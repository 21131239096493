<template>
  <div class="container">
    <AppPageDescription :description="$t('label.municipios')" :buttonText="$t('label.adicionar')"/>
    <div class="row">
      <div class="col-xl-12 col-md-12 flex-row align-self-center">
        <!-- busca lista -->
        <form class="fitro-lista" @submit.prevent="search">
          <div class="row pb-0">
            <div class="col-12 text-right">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="pendenteAnalise" v-model="$store.state.formSearch.pending_analysis">
                <label class="form-check-label" for="pendenteAnalise">{{ $t('label.pendenteAnalise') }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.municipio')" v-model="$store.state.formSearch.municipality">
            </div>
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.estado')" v-model="$store.state.formSearch.state">
            </div>
            <div class="col-12 col-lg-4  d-flex justify-content-end mt-1 align-items-end">
              <button type="button" @click="clear" class="btn btn-outline-secondary ">
                {{ $t('label.limpar') }}
              </button>
              <button type="submit" class="btn btn-primary ms-2">{{ $t('label.filtrar') }}</button>
            </div>
          </div>
        </form>
        <!-- /busca lista -->
      </div>
    </div>
    <MunicipiosTableView @orderBy="orderBy" @search="search" :items="items" :count="count" :withOrderBy="true" />
  </div>
</template>

<script>
import MunicipiosTableView from './MunicipiosTableView.vue'
import AppPageDescription from '../../components/AppPageDescription.vue'
import axios from 'axios'
export default {
  name: 'MunicipiosView',
  components: { AppPageDescription, MunicipiosTableView },
  data () {
    return {
      count: 0,
      items: []
    }
  },
  methods: {
    clear: function () {
      this.$store.commit('clearFormSearch')
    },
    orderBy: function (dataOrderBy) {
      this.$store.state.dataOrderBy = dataOrderBy
      this.search()
    },
    setFormOrderBy: function () {
      this.$store.state.formSearch.OrderByMunicipalityAcs = this.$store.state.dataOrderBy.OrderByMunicipalityAcs
      this.$store.state.formSearch.OrderByMunicipalityDesc = this.$store.state.dataOrderBy.OrderByMunicipalityDesc
      this.$store.state.formSearch.OrderByStateAcs = this.$store.state.dataOrderBy.OrderByStateAcs
      this.$store.state.formSearch.OrderByStateDesc = this.$store.state.dataOrderBy.OrderByStateDesc
    },
    search: function () {
      this.$store.dispatch('showPreload')
      this.setFormOrderBy()
      const params = this.$store.state.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      axios.get('/Municipalities/getallgrid', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.items = response.data.items
          this.count = response.data.count
        })
    }
  },
  created () {
    this.search()
  }
}
</script>
