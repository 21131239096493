import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import LoginView from '../views/login/LoginView.vue'
import ColetaView from '../views/coleta/ColetaView.vue'
import ColetaFormView from '../views/coleta/ColetaFormView.vue'
import ExpedMudasView from '../views/exped-mudas/ExpedMudasView.vue'
import ExpedMudasFormView from '../views/exped-mudas/ExpedMudasFormView.vue'
import GerminacaoView from '../views/germinacao/GerminacaoView.vue'
import GerminacaoFormView from '../views/germinacao/GerminacaoFormView.vue'
import MatrizesView from '../views/matrizes/MatrizesView.vue'
import MatrizesFormView from '../views/matrizes/MatrizesFormView.vue'
import MunicipiosView from '../views/municipios/MunicipiosView.vue'
import MunicipiosFormView from '../views/municipios/MunicipiosFormView.vue'
import RedeView from '../views/rede/RedeView.vue'
import RedeFormView from '../views/rede/RedeFormView.vue'
import CoordMudasView from '../views/coord-mudas/CoordMudasView.vue'
import CoordMudasFormView from '../views/coord-mudas/CoordMudasFormView.vue'
import EspeciesView from '../views/especies/EspeciesView.vue'
import EspeciesFormView from '../views/especies/EspeciesFormView.vue'
import MudasView from '../views/mudas/MudasView.vue'
import MudasFormView from '../views/mudas/MudasFormView.vue'
import UsuariosView from '../views/usuarios/UsuariosView.vue'
import UsuariosFormView from '../views/usuarios/UsuariosFormView.vue'
import ExportarMatrizes from '../views/exportar/ExportarMatrizes.vue'
import ExportarEspecies from '../views/exportar/ExportarEspecies.vue'
import ExportarPlantadores from '../views/exportar/ExportarPlantadores.vue'
import ExportarMudasPlantadas from '../views/exportar/ExportarMudasPlantadas.vue'
import ExportarCoordMudas from '../views/exportar/ExportarCoordMudas.vue'
import ExportarExpedicao from '../views/exportar/ExportarExpedicao.vue'
import ExportarGerminacao from '../views/exportar/ExportarGerminacao.vue'
import ExportarColetaDeFrutosESementes from '../views/exportar/ExportarColetaDeFrutosESementes.vue'

import store from '@/store'

const admin = 1
const basic = 2

const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/coleta',
    name: 'coleta',
    component: ColetaView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/coleta/:id',
    name: 'coletaForm',
    component: ColetaFormView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/exped-mudas',
    name: 'exped-mudas',
    component: ExpedMudasView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/exped-mudas/:id',
    name: 'exped-mudasForm',
    component: ExpedMudasFormView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/germinacao',
    name: 'germinacao',
    component: GerminacaoView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/germinacao/:id',
    name: 'germinacaoForm',
    component: GerminacaoFormView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/matrizes',
    name: 'matrizes',
    component: MatrizesView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/matrizes/:id',
    name: 'matrizesForm',
    component: MatrizesFormView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/municipios',
    name: 'municipios',
    component: MunicipiosView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/municipios/:id',
    name: 'municipiosForm',
    component: MunicipiosFormView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/rede',
    name: 'rede',
    component: RedeView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/rede/:id',
    name: 'redeForm',
    component: RedeFormView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/coord-mudas',
    name: 'coord-mudas',
    component: CoordMudasView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/coord-mudas/:id',
    name: 'coord-mudasForm',
    component: CoordMudasFormView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/especies',
    name: 'especies',
    component: EspeciesView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/especies/:id',
    name: 'especiesForm',
    component: EspeciesFormView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/mudas',
    name: 'mudas',
    component: MudasView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/mudas/:id',
    name: 'mudasForm',
    component: MudasFormView,
    meta: {
      requiresAuth: true,
      role: basic
    }
  },
  {
    path: '/exportar-matrizes',
    name: 'exportar-matrizes',
    component: ExportarMatrizes,
    meta: {
      requiresAuth: true,
      role: admin
    }
  },
  {
    path: '/exportar-especies',
    name: 'exportar-especies',
    component: ExportarEspecies,
    meta: {
      requiresAuth: true,
      role: admin
    }
  },
  {
    path: '/exportar-plantadores',
    name: 'exportar-plantadores',
    component: ExportarPlantadores,
    meta: {
      requiresAuth: true,
      role: admin
    }
  },
  {
    path: '/exportar-mudas-plantadas',
    name: 'exportar-mudas-plantadas',
    component: ExportarMudasPlantadas,
    meta: {
      requiresAuth: true,
      role: admin
    }
  },
  {
    path: '/exportar-coord-mudas',
    name: 'exportar-coord-mudas',
    component: ExportarCoordMudas,
    meta: {
      requiresAuth: true,
      role: admin
    }
  },
  {
    path: '/exportar-expedicao',
    name: 'exportar-expedicao',
    component: ExportarExpedicao,
    meta: {
      requiresAuth: true,
      role: admin
    }
  },
  {
    path: '/exportar-germinacao',
    name: 'exportar-germinacao',
    component: ExportarGerminacao,
    meta: {
      requiresAuth: true,
      role: admin
    }
  },
  {
    path: '/exportar-coletas',
    name: 'exportar-coletas',
    component: ExportarColetaDeFrutosESementes,
    meta: {
      requiresAuth: true,
      role: admin
    }
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: UsuariosView,
    meta: {
      requiresAuth: true,
      role: admin
    }
  },
  {
    path: '/usuarios/:id',
    name: 'usuariosForm',
    component: UsuariosFormView,
    meta: {
      requiresAuth: true,
      role: admin
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const defaultRouter = '/login'
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const role = to.meta.role
  store.commit('preload', false)
  store.state.beforeRouter = from.name

  const coleta = !((from.name === 'coleta' && to.name === 'coletaForm') || (from.name === 'coletaForm' && to.name === 'coleta'))
  const expedmudas = !((from.name === 'exped-mudas' && to.name === 'exped-mudasForm') || (from.name === 'exped-mudasForm' && to.name === 'exped-mudas'))
  const germinacao = !((from.name === 'germinacao' && to.name === 'germinacaoForm') || (from.name === 'germinacaoForm' && to.name === 'germinacao'))
  const matrizes = !((from.name === 'matrizes' && to.name === 'matrizesForm') || (from.name === 'matrizesForm' && to.name === 'matrizes'))
  const municipios = !((from.name === 'municipios' && to.name === 'municipiosForm') || (from.name === 'municipiosForm' && to.name === 'municipios'))
  const rede = !((from.name === 'rede' && to.name === 'redeForm') || (from.name === 'redeForm' && to.name === 'rede'))
  const coordmudas = !((from.name === 'coord-mudas' && to.name === 'coord-mudasForm') || (from.name === 'coord-mudasForm' && to.name === 'coord-mudas'))
  const coordmudasapp = !((from.name === 'coord-mudas-app' && to.name === 'coord-mudas-appForm') || (from.name === 'coord-mudas-appForm' && to.name === 'coord-mudas-app'))
  const especies = !((from.name === 'especies' && to.name === 'especiesForm') || (from.name === 'especiesForm' && to.name === 'especies'))
  const mudas = !((from.name === 'mudas' && to.name === 'mudasForm') || (from.name === 'mudasForm' && to.name === 'mudas'))
  const usuarios = !((from.name === 'usuarios' && to.name === 'usuariosForm') || (from.name === 'usuariosForm' && to.name === 'usuarios'))

  if (coleta && expedmudas && germinacao && matrizes && municipios && rede && coordmudas && coordmudasapp && especies && mudas && usuarios) {
    store.state.currentPage = 1
    store.state.perPage = 10
    store.commit('clearFormSearch')
  }
  if (requiresAuth) {
    if (!store.state.user || !store.state.user.token) {
      next(defaultRouter)
    } else if (role === admin && store.state.user && store.state.user.sysUser.user_level === basic) {
      store.commit('setUser', null)
      next(defaultRouter)
    } else {
      store.state.logged = true
      next()
    }
  } else next()
})

export default router
