<template>
  <div class="app-header">
    <nav class="navbar navbar-light navbar-expand-sm">
      <div class="container-fluid">
        <div class="navbar-nav" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link hide-sidebar-toggle-button" @click="onMenuCollapse"
                ><i class="material-icons">{{isMenuCollapsed ? 'last_page' : 'first_page'}}</i></a
              >
            </li>
            <li class="nav-item hello">
              <p class="">{{ $t('label.ola') }}, <span>{{user.sysUser.name}}</span>!</p>
            </li>
          </ul>
        </div>
        <div class="d-flex">
          <ul class="navbar-nav">
            <li class="nav-item hidden-on-mobile me-3">
              <ul class="list_idioma">
                <li><a :class="{ 'active': $i18n.locale === 'pt' }" @click="setLocale('pt')">PT</a></li>
                <li><a :class="{ 'active': $i18n.locale === 'en' }" @click="setLocale('en')">EN</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link btn bt-logoff" @click="logout" role="button">{{ $t('label.logoff') }}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AppHeader',
  computed: mapState(['isMenuCollapsed', 'user']),
  methods: {
    logout () {
      this.$router.push({ name: 'login' })
      this.$store.commit('setUser', null)
    },
    onMenuCollapse: function () {
      this.$store.commit('onMenuCollapse')
    },
    setLocale: function (value) {
      this.$i18n.locale = value
    }
  }
}
</script>

<style>
.list_idioma{
  display: flex;
  background-color: transparent;
  border-color: transparent;
  color: #016237;
  font-weight: 700;
  list-style: none;
  margin: 0;
  margin-bottom: 0!important;
  margin-top: 12px!important;
}
.list_idioma li {
  padding: 0 10px 0 0px;
}
.list_idioma li a {
  text-decoration: none;
}
.list_idioma li a.active {
  text-decoration: underline;
}
</style>
