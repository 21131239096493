<template>
  <div class="container">
    <AppPageDescription :description="$t('label.usuarios')" :buttonText="$t('label.adicionar')"/>
    <div class="row">
      <div class="col-xl-12 col-md-12 flex-row align-self-center">
        <!-- busca lista -->
        <form class="fitro-lista" @submit.prevent="search">
          <div class="row">
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.nome')" v-model="$store.state.formSearch.name">
            </div>
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.email')" v-model="$store.state.formSearch.email">
            </div>
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.telefone')" v-model="$store.state.formSearch.phone">
            </div>
            <div class="col-md-12  d-flex justify-content-end mt-1 align-items-end">
              <button type="button" @click="clear" class="btn btn-outline-secondary ">
                {{ $t('label.limpar') }}
              </button>
              <button type="submit" class="btn btn-primary ms-2">{{ $t('label.filtrar') }}</button>
            </div>
          </div>
        </form>
        <!-- /busca lista -->
      </div>
    </div>
    <UsuariosTableView @orderBy="orderBy" @search="search" :items="items" :count="count" :withOrderBy="true" />
  </div>
</template>

<script>
import UsuariosTableView from './UsuariosTableView.vue'
import AppPageDescription from '../../components/AppPageDescription.vue'
import axios from 'axios'
export default {
  name: 'UsuariosView',
  components: { AppPageDescription, UsuariosTableView },
  data () {
    return {
      count: 0,
      items: []
    }
  },
  methods: {
    clear: function () {
      this.$store.commit('clearFormSearch')
    },
    orderBy: function (dataOrderBy) {
      this.$store.state.dataOrderBy = dataOrderBy
      this.search()
    },
    setFormOrderBy: function () {
      this.$store.state.formSearch.OrderByNameAcs = this.$store.state.dataOrderBy.OrderByNameAcs
      this.$store.state.formSearch.OrderByNameDesc = this.$store.state.dataOrderBy.OrderByNameDesc
      this.$store.state.formSearch.OrderByEmailAcs = this.$store.state.dataOrderBy.OrderByEmailAcs
      this.$store.state.formSearch.OrderByEmailDesc = this.$store.state.dataOrderBy.OrderByEmailDesc
      this.$store.state.formSearch.OrderByPhoneAcs = this.$store.state.dataOrderBy.OrderByPhoneAcs
      this.$store.state.formSearch.OrderByPhoneDesc = this.$store.state.dataOrderBy.OrderByPhoneDesc
    },
    search: function () {
      this.$store.dispatch('showPreload')
      this.setFormOrderBy()
      const params = this.$store.state.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      axios.get('/user/getallgrid', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.items = response.data.items
          this.count = response.data.count
        })
    }
  },
  created () {
    this.search()
  }
}
</script>
