<template>
  <div class="container">
    <AppPageTitle :title="pageTitle" :pendingAnalysis="pendingAnalysis" />
    <form class="">
      <div class="row pb-5">

        <div class="col-md-6">
          <div class="">
            <div>
              <label class="form-label">{{ $t('label.id') }}</label>
              <input type="text" class="form-control" v-model="this.form.id_seedling" disabled>
            </div>
          </div>

          <div class="">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.code !== this.form.log_update.Code }">
              <label class="form-label">{{ $t('label.codigocorrdenadademudas') }}</label>
              <button v-if="pendingAnalysis && this.form.code !== this.form.log_update.Code" class="btn" type="button" @click="analysis($t('label.codigodamuda'), 'code')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <input type="text" class="form-control" v-model="this.form.code" maxlength="3">
            </div>
          </div>
          <div class="">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_species !== this.form.log_update.Id_species }">
              <label class="form-label">{{ $t('label.nomecientificonomepopular') }}</label>
              <button v-if="pendingAnalysis && this.form.id_species !== this.form.log_update.Id_species" class="btn" type="button" @click="analysis($t('label.nomecientificonomepopular'), 'id_species')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <v-select :appendToBody="true" :filterable="false" @search="getSpeciesCombo" :options="speciesList"
                v-model="form.id_species" label="text" index="id"
                :reduce="r => r.id" :clearable="false" class="vue-select" :class="{ 'is-invalid': v$.form.id_species.$dirty && v$.form.id_species.$error }">
                <template v-slot:selected-option="option">
                  {{ `${option.text}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.text}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
              <div class="invalid-feedback">
                {{ $t('message.requiredField') }}
              </div>
            </div>
          </div>
          <div class="">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_mothertree !== this.form.log_update.Id_mothertree }">
              <label class="form-label">{{ $t('label.codigodamatriz') }}</label>
              <button v-if="pendingAnalysis && this.form.id_mothertree !== this.form.log_update.Id_mothertree" class="btn" type="button" @click="analysis($t('label.codigodamatriz'), 'id_mothertree')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <v-select :appendToBody="true" :filterable="false" @search="getMotherTreesCombo" :options="motherTreesList"
                v-model="form.id_mothertree" label="text" index="id"
                :reduce="r => r.id" :clearable="false" class="vue-select">
                <template v-slot:selected-option="option">
                  {{ `${option.text ?? ''}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.text ?? ''}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
          </div>
          <div class="">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_property !== this.form.log_update.Id_property }">
              <label class="form-label">{{ $t('label.nomedapropriedade') }}</label>
              <button v-if="pendingAnalysis && this.form.id_property !== this.form.log_update.Id_property" class="btn" type="button" @click="analysis($t('label.nomedapropriedade'), 'id_property')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <v-select :appendToBody="true" :filterable="false" @search="getPlantersCombo" :options="plantersList"
                v-model="form.id_property" label="text" index="id"
                :reduce="r => r.id" :clearable="false" class="vue-select" :class="{ 'is-invalid': v$.form.id_property.$dirty && v$.form.id_property.$error }">
                <template v-slot:selected-option="option">
                  {{ `${option.text}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.text}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
              <div class="invalid-feedback">
                {{ $t('message.requiredField') }}
              </div>
            </div>
          </div>
          <div class="">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.lat !== this.form.log_update.Lat }">
              <label for="lat" class="form-label">{{ $t('label.latitude') }}</label>
              <button v-if="pendingAnalysis && this.form.lat !== this.form.log_update.Lat" class="btn" type="button" @click="analysis($t('label.latitude'), 'lat')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <input type="number" class="form-control" id="lat" v-model="form.lat">
            </div>
          </div>
          <div class="">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.long !== this.form.log_update.Long }">
              <label for="long" class="form-label">{{ $t('label.longitude') }}</label>
              <button v-if="pendingAnalysis && this.form.long !== this.form.log_update.Long" class="btn" type="button" @click="analysis($t('label.longitude'), 'long')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <input type="number" class="form-control" id="long" v-model="form.long">
            </div>
          </div>
          <div class="">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.date_planted !== this.form.log_update.Date_planted }">
              <label class="form-label">{{ $t('label.datadoplantio') }}</label>
              <button v-if="pendingAnalysis && this.form.date_planted !== this.form.log_update.Date_planted" class="btn" type="button" @click="analysis($t('label.datadoplantio'), 'date_planted')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <input type="text flatpickr1" class="form-control" v-model="this.form.date_planted" v-mask="'##/##/####'" :class="{ 'is-invalid': v$.form.date_planted.$dirty && v$.form.date_planted.$error }">
              <div class="invalid-feedback">
                {{ $t('message.invalidDate') }}
              </div>
            </div>
          </div>
          <div class="">
            <div>
              <label for="long" class="form-label">{{ $t('label.usuariocriacao') }}</label>
              <input type="text" class="form-control" id="long" v-model="form.userCreated.name" disabled>
            </div>
          </div>
          <div class="">
            <label class="form-label">{{ $t('label.datacriacao') }}</label>
            <input type="text" class="form-control" v-model="this.form.date_created" v-mask="'##/##/####'" disabled>
          </div>
          <div class="pt-5 ps-4 mt-2">
              <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="especiesFrutificacaoJaneiro" v-model="this.form.from_app" disabled>
                  <label class="form-check-label" for="especiesFrutificacaoJaneiro">{{ $t('label.cadastradoviaapp') }}</label>
              </div>
          </div>
        </div>
        <div class="col-md-6">
          <div id="mapContainer" style="height: 100%;width: 100%;"></div>
        </div>
        <div class="col-md-12">
          <div class="mb-3">
            <label class="form-label">{{ $t('label.imagens') }}</label>
            <input class="form-control" type="file" multiple accept="image/*" id="foto" @change="onFileChange">
          </div>
          <div class="mb-3">
            <viewer :options="options" :images="images" @inited="inited" class="viewer" ref="viewer">
              <template #default="scope">
                <img v-for="src in scope.images" :src="src" :key="src" width="150" height="200" style="padding: 2px;cursor: pointer;">
              </template>
            </viewer>
          </div>
        </div>
        <div class="col-md-12 mt-5 d-flex justify-content-start ">
          <button type="button" @click="save" class="btn btn-primary me-3">{{pendingAnalysis ? $t('label.aprovar') : $t('label.salvar')}}</button>
          <button type="button" @click="cancel" class="btn btn-danger">{{ $t('label.cancelar') }}</button>
        </div>
      </div>
    </form>
    <!-- Modal -->
    <AppModalReview :form="form" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import AppPageTitle from '../../components/AppPageTitle.vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import AppModalReview from '../../components/AppModalReview.vue'
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from 'v-viewer'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
export default {
  name: 'CoordMudasFormView',
  components: {
    AppPageTitle,
    AppModalReview,
    Viewer
  },
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      pageTitle: `${this.$t('label.coordenadasdemudasplantadas')} > ${this.$t('label.adicionar')}`,
      action: this.constants.actionNew,
      form: {
        userCreated: {},
        log_update: {},
        seedlingCoordImg: []
      },
      speciesList: [{ id_species: null, text: this.$t('label.nomecientificonomepopular') }],
      plantersList: [{ id_property: null, text: this.$t('label.nomedapropriedade') }],
      motherTreesList: [{ id_mothertree: null, text: this.$t('label.codigodamatriz') }],
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      },
      options: { title: false },
      images: [],
      imagesNovas: [],
      map: null,
      marker: null,
      myCoordinates: {
        lat: -27.5707056,
        lng: -48.7504603
      }
    }
  },
  validations () {
    return {
      form: {
        id_species: { required },
        id_property: { required },
        date_planted: {
          minLength: minLength(10),
          validDate (value) {
            if (!value) { return true }
            return this.$filters.dateIsValid(value)
          }
        }
      }
    }
  },
  watch: {
    'form.lat' (newLat, oldLat) {
      if (newLat && newLat !== oldLat) {
        this.updateMarker()
      }
    },
    'form.long' (newLong, oldLong) {
      if (newLong !== oldLong) {
        this.updateMarker()
      }
    },
    'form.id_species' (newLong, oldLong) {
      if (newLong !== oldLong) {
        if (oldLong) {
          this.form.id_mothertree = null
        }
        this.getMotherTreesCombo()
      }
    }
  },
  mounted () {
    this.map = L.map('mapContainer').setView([this.myCoordinates.lat, this.myCoordinates.lng], 13)
    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map)
    // use a mix of renderers
    const customPane = this.map.createPane('customPane')
    customPane.style.zIndex = 399 // put just behind the standard overlay pane which is at 400
    const LeafIcon = L.Icon.extend({
      options: {
        iconAnchor: [22, 94],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76]
      }
    })
    const greenIcon = new LeafIcon({
      iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.6.0/images/marker-icon.png'
    })

    this.marker = L.marker([this.myCoordinates.lat, this.myCoordinates.lng], { icon: greenIcon, draggable: 'true' }).addTo(this.map)
    this.marker.on('dragend', this.dragStartHandler)

    this.$getLocation()
      .then((coordinates) => {
        this.myCoordinates.lat = coordinates.lat
        this.myCoordinates.lng = coordinates.lng
        const latLng = [this.myCoordinates.lat, this.myCoordinates.lng]
        this.marker.setLatLng(latLng, {
          draggable: 'true'
        }).bindPopup(latLng).update()
        this.map.panTo(latLng)
      })
  },
  onBeforeUnmount () {
    if (this.map) {
      this.map.remove()
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.form.pending_analysis && this.$store.state.user.sysUser.user_level === 1
    }
  },
  methods: {
    updateMarker () {
      if (!this.form.lat && !this.form.long) {
        return
      }
      const latLng = [this.form.lat || 0, this.form.long || 0]
      this.marker.setLatLng(latLng, {
        draggable: 'true'
      }).bindPopup(latLng).update()
      this.map.panTo(latLng)
    },
    dragStartHandler (e) {
      const { lat, lng } = e.target.getLatLng()
      this.form.lat = lat
      this.form.long = lng
    },
    modalValuesSimple: function (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis: async function (label, input) {
      let newValue = ''
      let oldValue = ''
      switch (input) {
        case 'id_species':
          newValue = this.speciesList.filter(l => l.id === this.form.id_species)[0]
          oldValue = this.speciesList.filter(l => l.id === this.form.log_update.Id_species)[0]
          if (!oldValue) {
            oldValue = await this.getSpeciesComboById(this.form.log_update.Id_species)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
        case 'id_property':
          newValue = this.plantersList.filter(l => l.id === this.form.id_property)[0]
          oldValue = this.plantersList.filter(l => l.id === this.form.log_update.Id_property)[0]
          if (!oldValue) {
            oldValue = await this.getSpeciesComboById(this.form.log_update.Id_property)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
        case 'lat':
          this.modalValuesSimple(this.form.lat, this.form.log_update.Lat, label)
          break
        case 'long':
          this.modalValuesSimple(this.form.long, this.form.log_update.Long, label)
          break
        case 'date_planted':
          this.modalValuesSimple(this.form.date_planted, this.form.log_update.Date_planted, label)
          break
        case 'code':
          this.modalValuesSimple(this.form.code, this.form.log_update.Code, label)
          break
        case 'id_mothertree':
          newValue = this.motherTreesList.filter(l => l.id === this.form.id_mothertree)[0]
          oldValue = this.motherTreesList.filter(l => l.id === this.form.log_update.Id_mothertree)[0]
          if (!oldValue) {
            oldValue = await this.getMotherTreesComboById(this.form.log_update.Id_mothertree)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
      }
    },
    getById: async function (id) {
      this.$store.dispatch('showPreload')
      await axios.get(`/SeedlingCoord/${id}`)
        .then(response => {
          if (!response.data.userCreated) {
            response.data.userCreated = {}
          }
          this.form = response.data
          this.form.date_planted = this.$filters.formatDate(this.form.date_planted)
          this.form.date_created = this.$filters.formatDate(this.form.date_created)
          if (this.form.log_update) {
            this.form.log_update = JSON.parse(this.form.log_update)
            this.form.log_update.Date_planted = this.$filters.formatDate(this.form.log_update.Date_planted)
            this.form.log_update.Date_created = this.$filters.formatDate(this.form.log_update.Date_created)
          } else {
            this.form.log_update = {}
          }
          if (this.form.seedlingCoordImg.length > 0) {
            this.form.seedlingCoordImg.forEach(img => {
              this.getImage(img.image_name)
            })
          }
        })
    },
    getSpeciesComboById: async function (id) {
      const params = {
        id_species: id
      }
      let result = {}
      await axios.get('/species/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getSpeciesCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.latin_name = search
        params.common_name = search
        params.id_species = this.form.id_species
        loading(true)
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
            loading(false)
          })
      } else if (!search) {
        params.id_species = this.form.id_species
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
          })
      }
    },
    getPlantersComboById: async function (id) {
      const params = {
        id_property: id
      }
      let result = {}
      await axios.get('/planters/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getPlantersCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.property = search
        params.id_property = this.form.id_property
        loading(true)
        axios.get('/planters/getallcombo', { params })
          .then(response => {
            this.plantersList = response.data.items
            this.plantersList.unshift({ id_property: null, text: this.$t('label.nomedapropriedade') })
            loading(false)
          })
      } else if (!search) {
        params.id_property = this.form.id_property
        axios.get('/planters/getallcombo', { params })
          .then(response => {
            this.plantersList = response.data.items
            this.plantersList.unshift({ id_property: null, text: this.$t('label.nomedapropriedade') })
          })
      }
    },
    save: function () {
      this.v$.$touch()
      if (this.v$.form.$invalid) {
        return
      }
      this.form.log_update = JSON.stringify(this.form.log_update)
      let modelRequest = {}
      modelRequest = { ...this.form }
      if (this.form.date_planted) {
        modelRequest.date_planted = this.$filters.formatDateToBackend(this.form.date_planted)
      }
      if (this.form.date_created) {
        modelRequest.date_created = this.$filters.formatDateToBackend(this.form.date_created)
      }
      if (this.action === this.constants.actionEdit) {
        axios.put('/SeedlingCoord', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.form.log_update = JSON.parse(this.form.log_update)
            this.upload()
            this.cancel()
          })
      } else {
        axios.post('/SeedlingCoord', modelRequest)
          .then(res => {
            this.$toast.success(this.constants.success)
            this.form.id_seedling = res.data.id_seedling
            this.upload()
            this.cancel()
          })
      }
    },
    cancel: function () {
      this.$router.replace('/coord-mudas')
    },
    upload: async function () {
      if (this.imagesNovas.length) {
        Array.from(this.imagesNovas).forEach(file => {
          const formData = new FormData()
          formData.append('file', file)
          axios.post(`/upload/UploadImage/${this.form.id_seedling}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        })
      }
    },
    getImage: async function (imageName) {
      if (imageName) {
        await axios.get(`/Upload/SeedlingCoordImg/${imageName}`)
          .then(resp => {
            if (resp.data) {
              this.images.push(`data:image/*;base64, ${resp.data}`)
            }
          })
      }
    },
    inited (viewer) {
      this.$viewer = viewer
    },
    show () {
      this.$viewer.show()
    },
    onFileChange: async function (e) {
      this.imagesNovas = []
      this.images = []
      if (this.form.seedlingCoordImg.length > 0) {
        for (let index = 0; index < this.form.seedlingCoordImg.length; index++) {
          const img = this.form.seedlingCoordImg[index]
          await this.getImage(img.image_name)
        }
      }
      Array.from(e.target.files).forEach(file => {
        this.imagesNovas.push(file)
        this.toBase64(file).then(base64 => {
          this.images.push(base64)
        })
      })
    },
    toBase64: file => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    }),
    getMotherTreesComboById: async function (id) {
      const params = {
        id_mothertree: id
      }
      let result = {}
      await axios.get('/MotherTrees/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getMotherTreesCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      params.id_species = this.form.id_species
      if (search && search.length > 2) {
        params.code = search
        params.id_mothertree = this.form.id_mothertree
        loading(true)
        axios.get('/MotherTrees/getallcombo', { params })
          .then(response => {
            this.motherTreesList = response.data.items
            this.motherTreesList.unshift({ id_mothertree: null, text: this.$t('label.codigodamatriz') })
            loading(false)
          })
      } else if (!search) {
        params.id_mothertree = this.form.id_mothertree
        axios.get('/MotherTrees/getallcombo', { params })
          .then(response => {
            this.motherTreesList = response.data.items
            this.motherTreesList.unshift({ id_mothertree: null, text: this.$t('label.codigodamatriz') })
          })
      }
    }
  },
  created: async function () {
    this.getSpeciesCombo()
    this.getPlantersCombo()
    this.getMotherTreesCombo()

    this.action =
      this.$route.params.id !== this.constants.actionNew
        ? this.constants.actionEdit
        : this.constants.actionNew

    if (this.action === this.constants.actionEdit) {
      this.pageTitle = `${this.$t('label.coordenadasdemudasplantadas')} > ${this.$t('label.editar')}`
      await this.getById(this.$route.params.id)
      this.getSpeciesCombo()
      this.getPlantersCombo()
      this.getMotherTreesCombo()
    }
  }
}
</script>
