<template>
  <div class="container">
    <AppPageTitle :title="pageTitle" :pendingAnalysis="pendingAnalysis" />
    <form class="">
      <div class="row pb-5">
        <div class="col-md-4">
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.code !== this.form.log_update.Code }">
            <label class="form-label">{{ $t('label.codigodagerminacao') }}</label>
            <button v-if="pendingAnalysis && this.form.code !== this.form.log_update.Code" class="btn" type="button" @click="analysis($t('label.codigodaexpedicao'), 'code')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="text" class="form-control" v-model="this.form.code" maxlength="3">
          </div>
        </div>
        <div class="col-md-8">
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_species !== this.form.log_update.Id_species }">
            <label class="form-label">{{ $t('label.nomecientificonomepopular') }}</label>
            <button v-if="pendingAnalysis && this.form.id_species !== this.form.log_update.Id_species" class="btn" type="button" @click="analysis($t('label.nomecientificonomepopular'), 'id_species')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <v-select :appendToBody="true" :filterable="false" @search="getSpeciesCombo" :options="speciesList"
              v-model="form.id_species" label="text" index="id"
              :reduce="r => r.id" :clearable="false" class="vue-select" :class="{ 'is-invalid': v$.form.id_species.$dirty && v$.form.id_species.$error }">
              <template v-slot:selected-option="option">
                {{ `${option.text}` }}
              </template>
              <template v-slot:option="option">
                {{ `${option.text}` }}
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                </template>
              </template>
            </v-select>
            <div class="invalid-feedback">
              {{ $t('message.requiredField') }}
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_collection !== this.form.log_update.Id_collection }">
            <label class="form-label">{{ $t('label.codigodacoleta') }}/{{ $t('label.matriz') }}</label>
            <button v-if="pendingAnalysis && this.form.id_collection !== this.form.log_update.Id_collection" class="btn" type="button" @click="analysis($t('label.codigodacoleta'), 'id_collection')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <v-select :appendToBody="true" :filterable="false" @search="getEkoaCollectCombo" :options="ekoaCollectList"
              v-model="form.id_collection" label="text" index="id"
              :reduce="r => r.id" :clearable="false" class="vue-select">
              <template v-slot:selected-option="option">
                {{ `${option.text ?? ''}` }}
              </template>
              <template v-slot:option="option">
                {{ `${option.text ?? ''}` }}
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                </template>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-md-8">
          <label class="form-label" for="germinacao">Código da matriz</label>
          <input type="text" class="form-control" :value="this.collect.motherTrees ? this.collect.motherTrees.code : ''" disabled>
        </div>
        <div class="col-md-12">
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.obs !== this.form.log_update.Obs }">
            <label for="germinacaoObs" class="form-label">{{ $t('label.observacao') }}</label>
            <button v-if="pendingAnalysis && this.form.obs !== this.form.log_update.Obs" class="btn" type="button" @click="analysis($t('label.observacao'), 'obs')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <textarea class="form-control" v-model="this.form.obs" rows="3"></textarea>
          </div>
      </div>
      <div class="col-md-12 pt-2" :class="{ 'pending_analysis':  pendingAnalysis && pendingAnalysisGermin }">
        <table id="datatable1" class="display dataTable no-footer" style="width: 100%;" role="grid" aria-describedby="datatable1_info">
            <thead>
                <tr role="row">
                  <th>
                    <button v-if="pendingAnalysis && pendingAnalysisGermin" class="btn" type="button" @click="analysis($t('label.tabelagerminacao'), 'tabelagerminacao')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                      <i class="material-icons">search</i>
                    </button>
                    {{ $t('label.datadegerminacaoemtubetes') }}
                  </th>
                  <th>{{ $t('label.numerodeplantulasemtubetes') }}</th>
                  <th>{{ $t('label.datadegerminacaonasementeira') }}</th>
                  <th>{{ $t('label.numerodeplantulasnasementeira') }}</th>
                  <th style="display: flex;">{{ $t('label.acoes') }}
                    <ul class="list-group list-group-horizontal list-acoes ">
                      <li class="list-acoes-add">
                        <a @click="actionGrid = this.constants.actionNew"><i class="material-icons">add</i></a>
                      </li>
                    </ul>
                  </th>
                </tr>
            </thead>
            <tbody>
              <template v-if="actionGrid !== this.constants.actionList">
                <tr>
                  <td>
                    <div class="col-md-12">
                      <input type="text" class="form-control" v-model="this.ekoaGerminInfo.date_germin_tube" v-mask="'##/##/####'">
                    </div>
                  </td>
                  <td>
                    <div class="col-md-12">
                      <input type="number" class="form-control" v-model="this.ekoaGerminInfo.n_germin_tube">
                    </div>
                  </td>
                  <td>
                    <div class="col-md-12">
                      <input type="text" class="form-control" v-model="this.ekoaGerminInfo.date_germin_seedbed" v-mask="'##/##/####'">
                    </div>
                  </td>
                  <td>
                    <div class="col-md-12">
                      <input type="number" class="form-control" v-model="this.ekoaGerminInfo.n_germin_seedbed">
                    </div>
                  </td>
                  <td>
                    <div class="col-md-12">
                      <ul class="list-group list-group-horizontal list-acoes ">
                        <li class="">
                          <a @click="saveInfo()"><i class="material-icons">done</i></a>
                        </li>
                        <li class="">
                          <a @click="cancelInfo()"><i class="material-icons">cancel</i></a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr v-if="!this.form.ekoaGerminInfo || this.form.ekoaGerminInfo.length === 0"><td colspan="5" class="text-center">{{ $t('message.noRecords') }}</td></tr>
                <tr v-else v-for="i in this.form.ekoaGerminInfo" :key="i.id_germin_info" role="row" class="odd">
                  <td>{{$filters.formatDate(i.date_germin_tube)}}</td>
                  <td>{{i.n_germin_tube}}</td>
                  <td>{{$filters.formatDate(i.date_germin_seedbed)}}</td>
                  <td>{{i.n_germin_seedbed}}</td>
                  <td>
                    <ul class="list-group list-group-horizontal list-acoes ">
                      <li class="list-acoes-edit">
                        <a @click="actionInfo(this.constants.actionEdit, i)"><i class="material-icons">edit</i></a>
                      </li>
                      <li class="list-acoes-delete">
                        <a @click="actionInfo(this.constants.actionDelete, i)"><i class="material-icons">delete</i></a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 mt-5 d-flex justify-content-end ">
          <button type="button" @click="save" class="btn btn-primary me-3">{{pendingAnalysis ? $t('label.aprovar') : $t('label.salvar')}}</button>
          <button type="button" @click="cancel" class="btn btn-danger">{{ $t('label.cancelar') }}</button>
        </div>
      </div>
    </form>
    <!-- Modal -->
    <AppModalReview :form="form" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import AppPageTitle from '../../components/AppPageTitle.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import AppModalReview from '../../components/AppModalReview.vue'
export default {
  name: 'GerminacaoFormView',
  components: { AppPageTitle, AppModalReview },
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      pageTitle: `${this.$t('label.controlegerminacaosementes')} > ${this.$t('label.adicionar')}`,
      action: this.constants.actionNew,
      actionGrid: this.constants.actionList,
      form: {
        species: {},
        log_update: {},
        ekoaGerminInfo: []
      },
      ekoaGerminInfo: {
        id_germin_info: null,
        id_germin: null,
        date_germin_tube: null,
        n_germin_tube: null,
        date_germin_seedbed: null,
        n_germin_seedbed: null
      },
      speciesList: [{ id_species: null, text: this.$t('label.nomecientificonomepopular') }],
      ekoaCollectList: [{ id_collection: null, text: `${this.$t('label.codigodacoleta')} / ${this.$t('label.matriz')}` }],
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      },
      collect: {}
    }
  },
  watch: {
    'form.id_collection' (newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.getCollectById(newValue)
      }
    },
    'form.id_species' (newLong, oldLong) {
      if (newLong !== oldLong) {
        if (oldLong) {
          this.form.id_collection = null
        }
        this.getEkoaCollectCombo()
      }
    }
  },
  validations () {
    return {
      form: {
        id_species: { required }
      }
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.form.pending_analysis && this.$store.state.user.sysUser.user_level === 1
    },
    pendingAnalysisGermin: function () {
      if (!this.form.ekoaGerminInfo || !this.form.log_update.EkoaGerminInfo) {
        return false
      }
      if (this.form.ekoaGerminInfo.length !== this.form.log_update.EkoaGerminInfo.length) { return true }

      const ret = false
      for (const e in this.form.log_update.EkoaGerminInfo) {
        const item = this.form.log_update.EkoaGerminInfo[e]
        const list = this.form.ekoaGerminInfo
          .filter(f => f.id_germin === item.Id_germin &&
            f.date_germin_tube === item.Date_germin_tube &&
            f.n_germin_tube === item.N_germin_tube &&
            f.date_germin_seedbed === item.Date_germin_seedbed &&
            f.n_germin_seedbed === item.N_germin_seedbed)
        if (!list.length) {
          return true
        }
      }
      return ret
    }
  },
  methods: {
    cancelInfo: function () {
      this.ekoaGerminInfo = {
        id_germin_info: null,
        id_germin: null,
        date_germin_tube: null,
        n_germin_tube: null,
        date_germin_seedbed: null,
        n_germin_seedbed: null
      }
      this.actionGrid = this.constants.actionList
    },
    saveInfo: function () {
      // this.v$.$touch()
      // if (this.v$.ekoaGerminInfo.$invalid) {
      //   return
      // }
      this.form.ekoaGerminInfo = this.form.ekoaGerminInfo.filter(f => f.id_germin_info !== this.ekoaGerminInfo.id_germin_info)
      this.ekoaGerminInfo.id_germin_info = Date.now()
      this.ekoaGerminInfo.id_germin = this.form.id_germin
      this.ekoaGerminInfo.date_germin_tube = this.$filters.formatDateToBackend(this.ekoaGerminInfo.date_germin_tube)
      this.ekoaGerminInfo.date_germin_seedbed = this.$filters.formatDateToBackend(this.ekoaGerminInfo.date_germin_seedbed)
      this.form.ekoaGerminInfo.push(this.ekoaGerminInfo)
      this.actionGrid = this.constants.actionList
      this.ekoaGerminInfo = {
        id_germin_info: null,
        id_germin: null,
        date_germin_tube: null,
        n_germin_tube: null,
        date_germin_seedbed: null,
        n_germin_seedbed: null
      }
      this.v$.$reset()
    },
    actionInfo: function (action, item) {
      if (action === this.constants.actionDelete) {
        if (confirm(this.$t('message.confirmDel'))) {
          this.form.ekoaGerminInfo = this.form.ekoaGerminInfo.filter(f => f.id_germin_info !== item.id_germin_info)
          this.actionGrid = this.constants.actionList
        }
      }
      if (action === this.constants.actionEdit) {
        const exped = { ...item }
        exped.date_germin_tube = this.$filters.formatDate(exped.date_germin_tube)
        exped.date_germin_seedbed = this.$filters.formatDate(exped.date_germin_seedbed)
        this.ekoaGerminInfo = exped
        this.actionGrid = this.constants.actionEdit
      }
    },
    modalValuesSimple: function (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis: async function (label, input) {
      let newValue = ''
      let oldValue = ''
      this.modalValues.label = label
      switch (input) {
        case 'code':
          this.modalValuesSimple(this.form.code, this.form.log_update.Code, label)
          break
        case 'id_species':
          newValue = this.speciesList.filter(l => l.id === this.form.id_species)[0]
          oldValue = this.speciesList.filter(l => l.id === this.form.log_update.Id_species)[0]
          if (!oldValue) {
            oldValue = await this.getSpeciesComboById(this.form.log_update.Id_species)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
        case 'id_collection':
          newValue = this.ekoaCollectList.filter(l => l.id === this.form.id_collection)[0]
          oldValue = this.ekoaCollectList.filter(l => l.id === this.form.log_update.Id_collection)[0]
          if (!oldValue) {
            oldValue = await this.getEkoaCollectComboById(this.form.log_update.Id_collection)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
        case 'tabelagerminacao':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          this.form.ekoaGerminInfo.forEach(e => {
            this.modalValues.newValue.push({ id: e.id_germin_info, value: `${this.$filters.formatDate(e.date_germin_tube)} - ${e.n_germin_tube} - ${e.date_germin_seedbed ? this.$filters.formatDate(e.date_germin_seedbed) : ''} - ${e.n_germin_seedbed ? e.n_germin_seedbed : ''}` })
          })
          this.modalValues.oldValue = []
          this.form.log_update.EkoaGerminInfo.forEach(e => {
            this.modalValues.oldValue.push({ id: e.Id_germin_info, value: `${this.$filters.formatDate(e.Date_germin_tube)} - ${e.N_germin_tube} - ${e.Date_germin_seedbed ? this.$filters.formatDate(e.Date_germin_seedbed) : ''} - ${e.N_germin_seedbed ? e.N_germin_seedbed : ''}` })
          })
          break
        case 'obs':
          this.modalValuesSimple(this.form.obs, this.form.log_update.Obs, label)
          break
      }
    },
    getById: async function (id) {
      this.$store.dispatch('showPreload')
      await axios.get(`/EkoaGermin/${id}`)
        .then(response => {
          this.form = response.data
          if (this.form.log_update) {
            this.form.log_update = JSON.parse(this.form.log_update)
          } else {
            this.form.log_update = {}
          }
        })
    },
    getSpeciesComboById: async function (id) {
      const params = {
        id_species: id
      }
      let result = {}
      await axios.get('/species/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getEkoaCollectComboById: async function (id) {
      const params = {
        id_collection: id
      }
      let result = {}
      await axios.get('/EkoaCollect/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getSpeciesCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.latin_name = search
        params.common_name = search
        params.id_species = this.form.id_species
        loading(true)
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
            loading(false)
          })
      } else if (!search) {
        params.id_species = this.form.id_species
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
          })
      }
    },
    getEkoaCollectCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.code = search
        params.id_collection = this.form.id_collection
        params.id_species = this.form.id_species
        loading(true)
        axios.get('/EkoaCollect/getallcombo', { params })
          .then(response => {
            this.ekoaCollectList = response.data.items
            this.ekoaCollectList.unshift({ id_collection: null, text: `${this.$t('label.codigodacoleta')} / ${this.$t('label.matriz')}` })
            loading(false)
          })
      } else if (!search) {
        params.id_collection = this.form.id_collection
        params.id_species = this.form.id_species
        axios.get('/EkoaCollect/getallcombo', { params })
          .then(response => {
            this.ekoaCollectList = response.data.items
            this.ekoaCollectList.unshift({ id_collection: null, text: `${this.$t('label.codigodacoleta')} / ${this.$t('label.matriz')}` })
          })
      }
    },
    save: function () {
      this.v$.$touch()
      if (this.v$.form.$invalid) {
        return
      }
      this.form.log_update = JSON.stringify(this.form.log_update)
      let modelRequest = {}
      modelRequest = { ...this.form }
      modelRequest.ekoaGerminInfo.forEach(element => {
        element.id_germin_info = 0
      })
      if (this.action === this.constants.actionEdit) {
        axios.put('/EkoaGermin', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.form.log_update = JSON.parse(this.form.log_update)
            this.cancel()
          })
      } else {
        axios.post('/EkoaGermin', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.cancel()
          })
      }
    },
    cancel: function () {
      this.$router.replace('/germinacao')
    },
    getCollectById: async function (id) {
      await axios.get(`/EkoaCollect/${id}`)
        .then(response => {
          this.collect = response.data
        })
    }
  },
  created: async function () {
    this.getSpeciesCombo()
    this.getEkoaCollectCombo()

    this.action =
      this.$route.params.id !== this.constants.actionNew
        ? this.constants.actionEdit
        : this.constants.actionNew

    if (this.action === this.constants.actionEdit) {
      this.pageTitle = `${this.$t('label.controlegerminacaosementes')} > ${this.$t('label.editar')}`
      await this.getById(this.$route.params.id)
      this.getSpeciesCombo()
      this.getEkoaCollectCombo()
    }
  }
}
</script>
