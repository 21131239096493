<template>
  <div class="container">
    <AppPageDescription :description="$t('label.coordenadasdemudasplantadas')" :buttonText="$t('label.adicionar')"/>
    <div class="row">
      <div class="col-xl-12 col-md-12 flex-row align-self-center">
        <!-- busca lista -->
        <form class="fitro-lista" @submit.prevent="search">
          <div class="row pb-0">
            <div class="col-12 text-right">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="pendenteAnalise" v-model="$store.state.formSearch.pending_analysis">
                <label class="form-check-label" for="pendenteAnalise">{{ $t('label.pendenteAnalise') }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="from_app" v-model="$store.state.formSearch.from_app">
                <label class="form-check-label" for="from_app">{{ $t('label.cadastradoviaapp') }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.codigocorrdenadademudas')" v-model="$store.state.formSearch.code" maxlength="3">
            </div>
            <div class="col-12 col-lg-4 mt-1">
              <v-select :appendToBody="true" :filterable="false" @search="getSpeciesCombo" :options="speciesList" v-model="$store.state.formSearch.id_species" label="text" index="id"
                :reduce="r => r.id" :clearable="false" class="bg-laranja-select vue-select">
                <template v-slot:selected-option="option">
                  {{ `${option.text}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.text}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.nomedapropriedade')" v-model="$store.state.formSearch.property">
            </div>
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.proprietarix')" v-model="$store.state.formSearch.owner">
            </div>
            <div class="col-md-12  d-flex justify-content-end mt-1 align-items-end">
              <button type="button" @click="clear" class="btn btn-outline-secondary ">
                {{ $t('label.limpar') }}
              </button>
              <button type="submit" class="btn btn-primary ms-2">{{ $t('label.filtrar') }}</button>
            </div>
          </div>
        </form>
        <!-- /busca lista -->
      </div>
    </div>
    <CoordMudasTableView @orderBy="orderBy" @search="search" :items="items" :count="count" :withOrderBy="true" />
  </div>
</template>

<script>
import CoordMudasTableView from './CoordMudasTableView.vue'
import AppPageDescription from '../../components/AppPageDescription.vue'
import axios from 'axios'
export default {
  name: 'CoordMudasView',
  components: { AppPageDescription, CoordMudasTableView },
  data () {
    return {
      count: 0,
      items: [],
      speciesList: [{ id_species: null, text: this.$t('label.nomecientificonomepopular') }]
    }
  },
  methods: {
    clear: function () {
      this.$store.commit('clearFormSearch')
    },
    orderBy: function (dataOrderBy) {
      this.$store.state.dataOrderBy = dataOrderBy
      this.search()
    },
    setFormOrderBy: function () {
      this.$store.state.formSearch.OrderById_seedlingAcs = this.$store.state.dataOrderBy.OrderById_seedlingAcs
      this.$store.state.formSearch.OrderById_seedlingDesc = this.$store.state.dataOrderBy.OrderById_seedlingDesc
      this.$store.state.formSearch.OrderByCodeAcs = this.$store.state.dataOrderBy.OrderByCodeAcs
      this.$store.state.formSearch.OrderByCodeDesc = this.$store.state.dataOrderBy.OrderByCodeDesc
      this.$store.state.formSearch.OrderByMotherTreesCodeAcs = this.$store.state.dataOrderBy.OrderByMotherTreesCodeAcs
      this.$store.state.formSearch.OrderByMotherTreesCodeDesc = this.$store.state.dataOrderBy.OrderByMotherTreesCodeDesc
      this.$store.state.formSearch.OrderByLatin_nameAcs = this.$store.state.dataOrderBy.OrderByLatin_nameAcs
      this.$store.state.formSearch.OrderByLatin_nameDesc = this.$store.state.dataOrderBy.OrderByLatin_nameDesc
      this.$store.state.formSearch.OrderByPropertyAcs = this.$store.state.dataOrderBy.OrderByPropertyAcs
      this.$store.state.formSearch.OrderByPropertyDesc = this.$store.state.dataOrderBy.OrderByPropertyDesc
      this.$store.state.formSearch.OrderByDate_plantedAcs = this.$store.state.dataOrderBy.OrderByDate_plantedAcs
      this.$store.state.formSearch.OrderByDate_plantedDesc = this.$store.state.dataOrderBy.OrderByDate_plantedDesc
    },
    search: function () {
      this.$store.dispatch('showPreload')
      this.setFormOrderBy()
      const params = this.$store.state.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      axios.get('/SeedlingCoord/getallgrid', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.items = response.data.items
          this.count = response.data.count
        })
    },
    getSpeciesCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.latin_name = search
        params.id_species = this.$store.state.formSearch.id_species
        loading(true)
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
            loading(false)
          })
      } else if (!search) {
        params.id_species = this.$store.state.formSearch.id_species
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
          })
      }
    }
  },
  created () {
    this.search()
    this.getSpeciesCombo()
  }
}
</script>
