<template>
  <div class="container">
    <AppPageTitle :title="pageTitle" :pendingAnalysis="pendingAnalysis" />
    <form class="">
      <div class="row pb-5">
        <div class="col-md-6">
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.code !== this.form.log_update.Code }">
            <label class="form-label">{{ $t('label.codigodamatriz') }}</label>
            <button v-if="pendingAnalysis && this.form.code !== this.form.log_update.Code" class="btn" type="button" @click="analysis($t('label.codigodamatriz'), 'code')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="text" class="form-control" v-model="this.form.code" maxlength="3">
          </div>
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_species !== this.form.log_update.Id_species }">
            <label class="form-label">{{ $t('label.nomecientificonomepopular') }}</label>
            <button v-if="pendingAnalysis && this.form.id_species !== this.form.log_update.Id_species" class="btn" type="button" @click="analysis($t('label.nomecientificonomepopular'), 'id_species')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <v-select :appendToBody="true" :filterable="false" @search="getSpeciesCombo" :options="speciesList"
              v-model="form.id_species" label="text" index="id"
              :reduce="r => r.id" :clearable="false" class="vue-select" :class="{ 'is-invalid': v$.form.id_species.$dirty && v$.form.id_species.$error }">
              <template v-slot:selected-option="option">
                {{ `${option.text}` }}
              </template>
              <template v-slot:option="option">
                {{ `${option.text}` }}
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                </template>
              </template>
            </v-select>
            <div class="invalid-feedback">
              {{ $t('message.requiredField') }}
            </div>
          </div>
          <div :class="{ 'pending_analysis': pendingAnalysis && this.form.log_update && this.form.latitudeitude !== this.form.log_update.Latitude }">
            <label for="latitude" class="form-label">{{ $t('label.latitude') }}</label>
            <button v-if="pendingAnalysis && this.form.log_update && this.form.latitudeitude !== this.form.log_update.Latitude" class="btn" type="button" @click="analysis($t('label.latitude'), 'latitude')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="number" class="form-control" id="latitude" v-model="form.latitude"  :class="{ 'is-invalid': v$.form.latitude.$dirty && v$.form.latitude.$error }">
            <div class="invalid-feedback">
              {{ $t('message.requiredField') }}
            </div>
          </div>
          <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.form.log_update && this.form.longitudeitude !== this.form.log_update.Longitude }">
            <label for="longitude" class="form-label">{{ $t('label.longitude') }}</label>
            <button v-if="pendingAnalysis && this.form.log_update && this.form.longitudeitude !== this.form.log_update.Longitude" class="btn" type="button" @click="analysis($t('label.longitude'), 'longitude')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="number" class="form-control" id="longitude" v-model="form.longitude"  :class="{ 'is-invalid': v$.form.longitude.$dirty && v$.form.longitude.$error }">
            <div class="invalid-feedback">
              {{ $t('message.requiredField') }}
            </div>
          </div>
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.date !== this.form.log_update.Date }">
            <label class="form-label">{{ $t('label.data') }}</label>
            <button v-if="pendingAnalysis && this.form.date !== this.form.log_update.Date" class="btn" type="button" @click="analysis($t('label.data'), 'date')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="text" class="form-control" v-model="this.form.date" v-mask="'##/##/####'"  :class="{ 'is-invalid': v$.form.date.$dirty && v$.form.date.$error }">
            <div class="invalid-feedback">
              {{ $t('message.invalidDate') }}
            </div>
          </div>
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.location !== this.form.log_update.Location }">
            <label class="form-label">{{ $t('label.localizacao') }}</label>
            <button v-if="pendingAnalysis && this.form.location !== this.form.log_update.Location" class="btn" type="button" @click="analysis($t('label.localizacao'), 'location')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="text" class="form-control" v-model="this.form.location">
          </div>
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_municipality !== this.form.log_update.Id_municipality }">
            <label class="form-label">{{ $t('label.municipio') }}</label>
            <button v-if="pendingAnalysis && this.form.id_municipality !== this.form.log_update.Id_municipality" class="btn" type="button" @click="analysis($t('label.municipio'), 'id_municipality')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <v-select :appendToBody="true" :filterable="false" @search="getMunicipalitiesCombo" :options="municipalitiesList"
              v-model="form.id_municipality" label="text" index="id"
              :reduce="r => r.id" :clearable="false" class="vue-select">
              <template v-slot:selected-option="option">
                {{ `${option.text ?? ''}` }}
              </template>
              <template v-slot:option="option">
                {{ `${option.text ?? ''}` }}
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                </template>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-md-6">
          <div id="mapContainer" style="height: 611px;width: 100%;"></div>
        </div>
        <div class="col-md-12 mt-5 d-flex justify-content-start ">
          <button type="button" @click="save" class="btn btn-primary me-3">{{pendingAnalysis ? $t('label.aprovar') : $t('label.salvar')}}</button>
          <button type="button" @click="cancel" class="btn btn-danger">{{ $t('label.cancelar') }}</button>
        </div>
      </div>
    </form>
    <!-- Modal -->
    <AppModalReview :form="form" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import AppPageTitle from '../../components/AppPageTitle.vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import AppModalReview from '../../components/AppModalReview.vue'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
export default {
  name: 'MatrizesFormView',
  components: { AppPageTitle, AppModalReview },
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      pageTitle: `${this.$t('label.matrizes')} > ${this.$t('label.adicionar')}`,
      action: this.constants.actionNew,
      form: {
        species: {},
        log_update: {}
      },
      speciesList: [{ id_species: null, text: this.$t('label.nomecientificonomepopular') }],
      municipalitiesList: [{ id_municipality: null, text: this.$t('label.municipio') }],
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      },
      map: null,
      marker: null,
      myCoordinates: {
        lat: -27.5707056,
        lng: -48.7504603
      }
    }
  },
  validations () {
    return {
      form: {
        id_species: { required },
        latitude: { required },
        longitude: { required },
        date: {
          minLength: minLength(10),
          validDate (value) {
            if (!value) { return true }
            return this.$filters.dateIsValid(value)
          }
        }
      }
    }
  },
  watch: {
    'form.latitude' (newLat, oldLat) {
      if (newLat && newLat !== oldLat) {
        this.updateMarker()
      }
    },
    'form.longitude' (newLong, oldLong) {
      if (newLong !== oldLong) {
        this.updateMarker()
      }
    }
  },
  mounted () {
    this.map = L.map('mapContainer').setView([this.myCoordinates.lat, this.myCoordinates.lng], 13)
    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map)
    // use a mix of renderers
    const customPane = this.map.createPane('customPane')
    customPane.style.zIndex = 399 // put just behind the standard overlay pane which is at 400
    const LeafIcon = L.Icon.extend({
      options: {
        iconAnchor: [22, 94],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76]
      }
    })
    const greenIcon = new LeafIcon({
      iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.6.0/images/marker-icon.png'
    })

    this.marker = L.marker([this.myCoordinates.lat, this.myCoordinates.lng], { icon: greenIcon, draggable: 'true' }).addTo(this.map)
    this.marker.on('dragend', this.dragStartHandler)

    this.$getLocation()
      .then((coordinates) => {
        this.myCoordinates.lat = coordinates.lat
        this.myCoordinates.lng = coordinates.lng
        const latLng = [this.myCoordinates.lat, this.myCoordinates.lng]
        this.marker.setLatLng(latLng, {
          draggable: 'true'
        }).bindPopup(latLng).update()
        this.map.panTo(latLng)
      })
  },
  onBeforeUnmount () {
    if (this.map) {
      this.map.remove()
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.form.pending_analysis && this.$store.state.user.sysUser.user_level === 1
    }
  },
  methods: {
    updateMarker () {
      if (!this.form.latitude && !this.form.longitude) {
        return
      }
      const latLng = [this.form.latitude || 0, this.form.longitude || 0]
      this.marker.setLatLng(latLng, {
        draggable: 'true'
      }).bindPopup(latLng).update()
      this.map.panTo(latLng)
    },
    dragStartHandler (e) {
      const { lat, lng } = e.target.getLatLng()
      this.form.latitude = lat
      this.form.longitude = lng
    },
    modalValuesSimple: function (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis: async function (label, input) {
      let newValue = ''
      let oldValue = ''
      switch (input) {
        case 'code':
          this.modalValuesSimple(this.form.code, this.form.log_update.Code, label)
          break
        case 'id_species':
          newValue = this.speciesList.filter(l => l.id === this.form.id_species)[0]
          oldValue = this.speciesList.filter(l => l.id === this.form.log_update.Id_species)[0]
          if (!oldValue) {
            oldValue = await this.getSpeciesComboById(this.form.log_update.Id_species)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
        case 'id_municipality':
          newValue = this.municipalitiesList.filter(l => l.id === this.form.id_municipality)[0]
          oldValue = this.municipalitiesList.filter(l => l.id === this.form.log_update.Id_municipality)[0]
          if (!oldValue) {
            oldValue = await this.getMunicipalitiesComboById(this.form.log_update.Id_municipality)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
        case 'latitude':
          this.modalValuesSimple(this.form.latitudeitude, this.form.log_update.Latitude, label)
          break
        case 'longitude':
          this.modalValuesSimple(this.form.longitudeitude, this.form.log_update.Longitude, label)
          break
        case 'date':
          this.modalValuesSimple(this.form.date, this.form.log_update.Date, label)
          break
        case 'location':
          this.modalValuesSimple(this.form.location, this.form.log_update.Location, label)
          break
      }
    },
    getById: async function (id) {
      this.$store.dispatch('showPreload')
      await axios.get(`/MotherTrees/${id}`)
        .then(response => {
          this.form = response.data
          this.form.date = this.$filters.formatDate(this.form.date)
          if (this.form.log_update) {
            this.form.log_update = JSON.parse(this.form.log_update)
            this.form.log_update.Date = this.$filters.formatDate(this.form.log_update.Date)
          } else {
            this.form.log_update = {}
          }
        })
    },
    getSpeciesComboById: async function (id) {
      const params = {
        id_species: id
      }
      let result = {}
      await axios.get('/species/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getMunicipalitiesComboById: async function (id) {
      const params = {
        id_municipality: id
      }
      let result = {}
      await axios.get('/Municipalities/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getSpeciesCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.latin_name = search
        params.common_name = search
        params.id_species = this.form.id_species
        loading(true)
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
            loading(false)
          })
      } else if (!search) {
        params.id_species = this.form.id_species
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
          })
      }
    },
    getMunicipalitiesCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.municipality = search
        params.id_municipality = this.form.id_municipality
        loading(true)
        axios.get('/Municipalities/getallcombo', { params })
          .then(response => {
            this.municipalitiesList = response.data.items
            this.municipalitiesList.unshift({ id_municipality: null, text: this.$t('label.municipio') })
            loading(false)
          })
      } else if (!search) {
        params.id_municipality = this.form.id_municipality
        axios.get('/Municipalities/getallcombo', { params })
          .then(response => {
            this.municipalitiesList = response.data.items
            this.municipalitiesList.unshift({ id_municipality: null, text: this.$t('label.municipio') })
          })
      }
    },
    save: function () {
      this.v$.$touch()
      if (this.v$.form.$invalid) {
        return
      }
      this.form.log_update = JSON.stringify(this.form.log_update)
      let modelRequest = {}
      modelRequest = { ...this.form }
      if (this.form.date) {
        modelRequest.date = this.$filters.formatDateToBackend(this.form.date)
      }
      if (this.action === this.constants.actionEdit) {
        axios.put('/MotherTrees', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.form.log_update = JSON.parse(this.form.log_update)
            this.cancel()
          })
      } else {
        axios.post('/MotherTrees', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.cancel()
          })
      }
    },
    cancel: function () {
      this.$router.replace('/matrizes')
    }
  },
  created: async function () {
    this.getSpeciesCombo()
    this.getMunicipalitiesCombo()

    this.action =
      this.$route.params.id !== this.constants.actionNew
        ? this.constants.actionEdit
        : this.constants.actionNew

    if (this.action === this.constants.actionEdit) {
      this.pageTitle = `${this.$t('label.matrizes')} > ${this.$t('label.editar')}`
      await this.getById(this.$route.params.id)
      this.getSpeciesCombo()
      this.getMunicipalitiesCombo()
    }
  }
}
</script>
