<template>
  <div class="container">
    <AppPageTitle :title="pageTitle" :pendingAnalysis="pendingAnalysis" />
    <form class="">
      <div class="row pb-5">
        <div class="col-md-6">
          <div>
            <label class="form-label">{{ $t('label.idcoleta') }}</label>
            <input class="form-control" v-model="this.form.id_collection" type="text" disabled="disabled">
          </div>

          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.code !== this.form.log_update.Code }">
            <label class="form-label">{{ $t('label.codigodacoleta') }}</label>
            <button v-if="pendingAnalysis && this.form.code !== this.form.log_update.Code" class="btn" type="button" @click="analysis($t('label.codigodacoleta'), 'code')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="text" class="form-control" v-model="this.form.code" maxlength="3">
          </div>

          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_species !== this.form.log_update.Id_species }">
            <label class="form-label">{{ $t('label.nomecientificonomepopular') }}</label>
            <button v-if="pendingAnalysis && this.form.id_species !== this.form.log_update.Id_species" class="btn" type="button" @click="analysis($t('label.nomecientifico'), 'id_species')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <v-select :appendToBody="true" :filterable="false" @search="getSpeciesCombo" :options="speciesList"
              v-model="form.id_species" label="text" index="id"
              :reduce="r => r.id" :clearable="false" class="vue-select" :class="{ 'is-invalid': v$.form.id_species.$dirty && v$.form.id_species.$error }">
              <template v-slot:selected-option="option">
                {{ `${option.text}` }}
              </template>
              <template v-slot:option="option">
                {{ `${option.text}` }}
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                </template>
              </template>
            </v-select>
            <div class="invalid-feedback">
              {{ $t('message.requiredField') }}
            </div>
          </div>

          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_mothertree !== this.form.log_update.Id_mothertree }">
            <label class="form-label">{{ $t('label.codigodamatriz') }}</label>
            <button v-if="pendingAnalysis && this.form.id_mothertree !== this.form.log_update.Id_mothertree" class="btn" type="button" @click="analysis($t('label.codigodamatriz'), 'id_mothertree')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <v-select :appendToBody="true" :filterable="false" @search="getMotherTreesCombo" :options="motherTreesList"
              v-model="form.id_mothertree" label="text" index="id"
              :reduce="r => r.id" :clearable="false" class="vue-select">
              <template v-slot:selected-option="option">
                {{ `${option.text ?? ''}` }}
              </template>
              <template v-slot:option="option">
                {{ `${option.text ?? ''}` }}
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                </template>
              </template>
            </v-select>
          </div>

        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.date_collection !== this.form.log_update.Date_collection }">
                <label class="form-label">{{ $t('label.datadecoleta') }}</label>
                <button v-if="pendingAnalysis && this.form.date_collection !== this.form.log_update.Date_collection" class="btn" type="button" @click="analysis($t('label.datadecoleta'), 'date_collection')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="text flatpickr1" class="form-control" v-model="this.form.date_collection" v-mask="'##/##/####'" :class="{ 'is-invalid': v$.form.date_collection.$dirty && v$.form.date_collection.$error }">
                <div class="invalid-feedback">
                  {{ $t('message.invalidDate') }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.seed_weight !== this.form.log_update.Seed_weight }">
                <label class="form-label">{{ $t('label.pesodassementes') }}</label>
                <button v-if="pendingAnalysis && this.seed_weight !== this.form.log_update.Seed_weight" class="btn" type="button" @click="analysis($t('label.pesodassementes'), 'seed_weight')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <money3 class="form-control" v-model="this.form.seed_weight" v-bind="config"></money3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.n_fruits !== this.form.log_update.N_fruits }">
                <label class="form-label">{{ $t('label.numerodefrutos') }}</label>
                <button v-if="pendingAnalysis && this.form.n_fruits !== this.form.log_update.N_fruits" class="btn" type="button" @click="analysis($t('label.numerodefrutos'), 'n_fruits')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="number" class="form-control" v-model="this.form.n_fruits">
              </div>
            </div>
            <div class="col-md-6">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.n_seeds !== this.form.log_update.N_seeds }">
                <label class="form-label">{{ $t('label.numerodesementes') }}</label>
                <button v-if="pendingAnalysis && this.form.n_seeds !== this.form.log_update.N_seeds" class="btn" type="button" @click="analysis($t('label.numerodesementes'), 'n_seeds')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="number" class="form-control" v-model="this.form.n_seeds">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.date_tube_seeding !== this.form.log_update.Date_tube_seeding }">
                <label class="form-label">{{ $t('label.dtsemeaduraemtubetes') }}</label>
                <button v-if="pendingAnalysis && this.form.date_tube_seeding !== this.form.log_update.Date_tube_seeding" class="btn" type="button" @click="analysis($t('label.dtsemeaduraemtubetes'), 'date_tube_seeding')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="text flatpickr1" class="form-control" v-model="this.form.date_tube_seeding" v-mask="'##/##/####'" :class="{ 'is-invalid': v$.form.date_tube_seeding.$dirty && v$.form.date_tube_seeding.$error }">
                <div class="invalid-feedback">
                  {{ $t('message.invalidDate') }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.n_tube_seeding !== this.form.log_update.N_tube_seeding }">
                <label class="form-label">{{ $t('label.numerotubetessemeados') }}</label>
                <button v-if="pendingAnalysis && this.form.n_tube_seeding !== this.form.log_update.N_tube_seeding" class="btn" type="button" @click="analysis($t('label.numerotubetessemeados'), 'n_tube_seeding')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="number" class="form-control" v-model="this.form.n_tube_seeding">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.date_seedbed !== this.form.log_update.Date_seedbed }">
                <label class="form-label">{{ $t('label.dtsemeaduraemsementeira') }}</label>
                <button v-if="pendingAnalysis && this.form.date_seedbed !== this.form.log_update.Date_seedbed" class="btn" type="button" @click="analysis($t('label.dtsemeaduraemsementeira'), 'date_seedbed')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="text flatpickr1" class="form-control" v-model="this.form.date_seedbed" v-mask="'##/##/####'" :class="{ 'is-invalid': v$.form.date_seedbed.$dirty && v$.form.date_seedbed.$error }">
                <div class="invalid-feedback">
                  {{ $t('message.invalidDate') }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.date_rustific !== this.form.log_update.Date_rustific }">
                <label class="form-label">{{ $t('label.dtiniciodarustificacao') }}</label>
                <button v-if="pendingAnalysis && this.form.date_rustific !== this.form.log_update.Date_rustific" class="btn" type="button" @click="analysis($t('label.dtiniciodarustificacao'), 'date_rustific')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="text flatpickr1" class="form-control" v-model="this.form.date_rustific" v-mask="'##/##/####'" :class="{ 'is-invalid': v$.form.date_rustific.$dirty && v$.form.date_rustific.$error }">
                <div class="invalid-feedback">
                  {{ $t('message.invalidDate') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.obs !== this.form.log_update.Obs }">
                <label class="form-label">{{ $t('label.observacao') }}</label>
                <button v-if="pendingAnalysis && this.form.obs !== this.form.log_update.Obs" class="btn" type="button" @click="analysis($t('label.observacao'), 'observacao')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <textarea class="form-control" v-model="this.form.obs" rows="3"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5 d-flex justify-content-end ">
          <button type="button" @click="save" class="btn btn-primary me-3">{{pendingAnalysis ? $t('label.aprovar') : $t('label.salvar')}}</button>
          <button type="button" @click="cancel" class="btn btn-danger">{{ $t('label.cancelar') }}</button>
        </div>
      </div>
    </form>
    <!-- Modal -->
    <AppModalReview :form="form" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import AppPageTitle from '../../components/AppPageTitle.vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { Money3Component } from 'v-money3'
import AppModalReview from '../../components/AppModalReview.vue'
export default {
  name: 'ColetaFormView',
  components: { AppPageTitle, money3: Money3Component, AppModalReview },
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      pageTitle: `${this.$t('label.coletadefrutosesementes')} > ${this.$t('label.adicionar')}`,
      action: this.constants.actionNew,
      form: {
        species: {},
        log_update: {}
      },
      seed_weight: '',
      speciesList: [{ id_species: null, text: this.$t('label.nomecientificonomepopular') }],
      motherTreesList: [{ id_mothertree: null, text: this.$t('label.codigodamatriz') }],
      config: {
        masked: false,
        prefix: '',
        suffix: '',
        thousands: '',
        decimal: '.',
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: true,
        minimumNumberOfCharacters: 0
      },
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      }
    }
  },
  watch: {
    'form.id_species' (newLong, oldLong) {
      if (newLong !== oldLong) {
        if (oldLong) {
          this.form.id_mothertree = null
        }
        this.getMotherTreesCombo()
      }
    }
  },
  validations () {
    return {
      form: {
        id_species: { required },
        date_collection: {
          minLength: minLength(10),
          validDate (value) {
            if (!value) { return true }
            return this.$filters.dateIsValid(value)
          }
        },
        date_tube_seeding: {
          minLength: minLength(10),
          validDate (value) {
            if (!value) { return true }
            return this.$filters.dateIsValid(value)
          }
        },
        date_seedbed: {
          minLength: minLength(10),
          validDate (value) {
            if (!value) { return true }
            return this.$filters.dateIsValid(value)
          }
        },
        date_rustific: {
          minLength: minLength(10),
          validDate (value) {
            if (!value) { return true }
            return this.$filters.dateIsValid(value)
          }
        }
      }
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.form.pending_analysis && this.$store.state.user.sysUser.user_level === 1
    }
  },
  methods: {
    modalValuesSimple: function (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis: async function (label, input) {
      let newValue = ''
      let oldValue = ''
      switch (input) {
        case 'code':
          this.modalValuesSimple(this.form.code, this.form.log_update.Code, label)
          break
        case 'id_species':
          newValue = this.speciesList.filter(l => l.id === this.form.id_species)[0]
          oldValue = this.speciesList.filter(l => l.id === this.form.log_update.Id_species)[0]
          if (!oldValue) {
            oldValue = await this.getSpeciesComboById(this.form.log_update.Id_species)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
        case 'id_mothertree':
          newValue = this.motherTreesList.filter(l => l.id === this.form.id_mothertree)[0]
          oldValue = this.motherTreesList.filter(l => l.id === this.form.log_update.Id_mothertree)[0]
          if (!oldValue) {
            oldValue = await this.getMotherTreesComboById(this.form.log_update.Id_mothertree)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
        case 'date_collection':
          this.modalValuesSimple(this.form.date_collection, this.form.log_update.Date_collection, label)
          break
        case 'seed_weight':
          this.modalValuesSimple(this.form.seed_weight, this.form.log_update.Seed_weight, label)
          break
        case 'n_fruits':
          this.modalValuesSimple(this.form.n_fruits, this.form.log_update.N_fruits, label)
          break
        case 'n_seeds':
          this.modalValuesSimple(this.form.n_seeds, this.form.log_update.N_seeds, label)
          break
        case 'date_tube_seeding':
          this.modalValuesSimple(this.form.date_tube_seeding, this.form.log_update.Date_tube_seeding, label)
          break
        case 'n_tube_seeding':
          this.modalValuesSimple(this.form.n_tube_seeding, this.form.log_update.N_tube_seeding, label)
          break
        case 'date_seedbed':
          this.modalValuesSimple(this.form.date_seedbed, this.form.log_update.Date_seedbed, label)
          break
        case 'date_rustific':
          this.modalValuesSimple(this.form.date_rustific, this.form.log_update.Date_rustific, label)
          break
        case 'observacao':
          this.modalValuesSimple(this.form.obs, this.form.log_update.Obs, label)
          break
      }
    },
    getById: async function (id) {
      this.$store.dispatch('showPreload')
      await axios.get(`/EkoaCollect/${id}`)
        .then(response => {
          this.form = response.data
          this.seed_weight = response.data.seed_weight
          this.form.date_collection = this.$filters.formatDate(this.form.date_collection)
          this.form.date_tube_seeding = this.$filters.formatDate(this.form.date_tube_seeding)
          this.form.date_seedbed = this.$filters.formatDate(this.form.date_seedbed)
          this.form.date_rustific = this.$filters.formatDate(this.form.date_rustific)
          if (this.form.log_update) {
            this.form.log_update = JSON.parse(this.form.log_update)
            this.form.log_update.Date_collection = this.$filters.formatDate(this.form.log_update.Date_collection)
            this.form.log_update.Date_tube_seeding = this.$filters.formatDate(this.form.log_update.Date_tube_seeding)
            this.form.log_update.Date_seedbed = this.$filters.formatDate(this.form.log_update.Date_seedbed)
            this.form.log_update.Date_rustific = this.$filters.formatDate(this.form.log_update.Date_rustific)
          } else {
            this.form.log_update = {}
          }
        })
    },
    getSpeciesComboById: async function (id) {
      const params = {
        id_species: id
      }
      let result = {}
      await axios.get('/species/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getMotherTreesComboById: async function (id) {
      const params = {
        id_mothertree: id
      }
      let result = {}
      await axios.get('/MotherTrees/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getSpeciesCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.latin_name = search
        params.common_name = search
        params.id_species = this.form.id_species
        loading(true)
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
            loading(false)
          })
      } else if (!search) {
        params.id_species = this.form.id_species
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
          })
      }
    },
    getMotherTreesCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      params.id_species = this.form.id_species
      if (search && search.length > 2) {
        params.code = search
        params.id_mothertree = this.form.id_mothertree
        loading(true)
        axios.get('/MotherTrees/getallcombo', { params })
          .then(response => {
            this.motherTreesList = response.data.items
            this.motherTreesList.unshift({ id_mothertree: null, text: this.$t('label.codigodamatriz') })
            loading(false)
          })
      } else if (!search) {
        params.id_mothertree = this.form.id_mothertree
        axios.get('/MotherTrees/getallcombo', { params })
          .then(response => {
            this.motherTreesList = response.data.items
            this.motherTreesList.unshift({ id_mothertree: null, text: this.$t('label.codigodamatriz') })
          })
      }
    },
    save: function () {
      this.v$.$touch()
      if (this.v$.form.$invalid) {
        return
      }
      this.form.log_update = JSON.stringify(this.form.log_update)
      let modelRequest = {}
      modelRequest = { ...this.form }
      if (this.form.date_collection) {
        modelRequest.date_collection = this.$filters.formatDateToBackend(this.form.date_collection)
      }
      if (this.form.date_tube_seeding) {
        modelRequest.date_tube_seeding = this.$filters.formatDateToBackend(this.form.date_tube_seeding)
      }
      if (this.form.date_seedbed) {
        modelRequest.date_seedbed = this.$filters.formatDateToBackend(this.form.date_seedbed)
      }
      if (this.form.date_rustific) {
        modelRequest.date_rustific = this.$filters.formatDateToBackend(this.form.date_rustific)
      }
      if (this.action === this.constants.actionEdit) {
        axios.put('/EkoaCollect', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.form.log_update = JSON.parse(this.form.log_update)
            this.cancel()
          })
      } else {
        axios.post('/EkoaCollect', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.cancel()
          })
      }
    },
    cancel: function () {
      this.$router.replace('/coleta')
    }
  },
  created: async function () {
    this.getSpeciesCombo()
    this.getMotherTreesCombo()

    this.action =
      this.$route.params.id !== this.constants.actionNew
        ? this.constants.actionEdit
        : this.constants.actionNew

    if (this.action === this.constants.actionEdit) {
      this.pageTitle = `${this.$t('label.coletadefrutosesementes')} > ${this.$t('label.editar')}`
      await this.getById(this.$route.params.id)
      this.getSpeciesCombo()
      this.getMotherTreesCombo()
    }
  }
}
</script>
