<template>
  <div class="container">
    <AppPageTitle :title="pageTitle" :pendingAnalysis="pendingAnalysis" />
    <form class="">
      <div class="row pb-5">
          <div class="col-md-12">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.property !== this.form.log_update.Property }">
              <label class="form-label">{{ $t('label.propriedade') }}</label>
              <button v-if="pendingAnalysis && this.form.property !== this.form.log_update.Property" class="btn" type="button" @click="analysis($t('label.propriedade'), 'property')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <input type="text" class="form-control" v-model="this.form.property" :class="{ 'is-invalid': v$.form.property.$dirty && v$.form.property.$error }">
              <div class="invalid-feedback">
                {{ $t('message.requiredField') }}
              </div>
            </div>
          </div>
        <div class="col-md-6">
          <div class="col-md-12">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.location !== this.form.log_update.Location }">
              <label class="form-label">{{ $t('label.localizacao') }}</label>
              <button v-if="pendingAnalysis && this.form.location !== this.form.log_update.Location" class="btn" type="button" @click="analysis($t('label.localizacao'), 'location')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <textarea class="form-control" v-model="this.form.location" rows="3"></textarea>
            </div>
          </div>
          <div class="col-md-12">
            <div :class="{ 'pending_analysis': pendingAnalysis && this.form.log_update && this.form.hectares !== this.form.log_update.Hectares }">
              <label for="hectares" class="form-label">{{ $t('label.hectares') }}</label>
              <button v-if="pendingAnalysis && this.form.log_update && this.form.hectares !== this.form.log_update.Hectares" class="btn" type="button" @click="analysis($t('label.hectares'), 'hectares')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <input type="number" class="form-control" id="hectares" v-model="form.hectares">
            </div>
          </div>
          <div class="col-md-12">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_municipality !== this.form.log_update.Id_municipality }">
              <label class="form-label">{{ $t('label.municipio') }}</label>
              <button v-if="pendingAnalysis && this.form.id_municipality !== this.form.log_update.Id_municipality" class="btn" type="button" @click="analysis($t('label.municipio'), 'id_municipality')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <v-select :appendToBody="true" :filterable="false" @search="getMunicipalitiesCombo" :options="municipalitiesList"
                v-model="form.id_municipality" label="text" index="id"
                :reduce="r => r.id" :clearable="false" class="vue-select" :class="{ 'is-invalid': v$.form.id_municipality.$dirty && v$.form.id_municipality.$error }">
                <template v-slot:selected-option="option">
                  {{ `${option.text ?? ''}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.text ?? ''}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
              <div class="invalid-feedback">
                {{ $t('message.requiredField') }}
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.owner !== this.form.log_update.Owner }">
              <label class="form-label">{{ $t('label.proprietario') }}</label>
              <button v-if="pendingAnalysis && this.form.owner !== this.form.log_update.Owner" class="btn" type="button" @click="analysis($t('label.proprietario'), 'owner')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <input type="text" class="form-control" v-model="this.form.owner">
            </div>
          </div>

          <div class="col-md-12">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.contact !== this.form.log_update.Contact }">
              <label class="form-label">{{ $t('label.contato') }}</label>
              <button v-if="pendingAnalysis && this.form.contact !== this.form.log_update.Contact" class="btn" type="button" @click="analysis($t('label.contato'), 'contact')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <input type="text" class="form-control" v-model="this.form.contact">
            </div>
          </div>
          <div class="col-md-12">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.date !== this.form.log_update.Date }">
              <label class="form-label">{{ $t('label.data') }}</label>
              <button v-if="pendingAnalysis && this.form.date !== this.form.log_update.Date" class="btn" type="button" @click="analysis($t('label.data'), 'date')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <input type="text" class="form-control" v-model="this.form.date" v-mask="'##/##/####'"  :class="{ 'is-invalid': v$.form.date.$dirty && v$.form.date.$error }">
              <div class="invalid-feedback">
                {{ $t('message.invalidDate') }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.phone_1 !== this.form.log_update.Phone_1 }">
                <label class="form-label">{{ `${$t('label.telefone')} 1` }}</label>
                <button v-if="pendingAnalysis && this.form.phone_1 !== this.form.log_update.Phone_1" class="btn" type="button" @click="analysis(`${$t('label.telefone')} 1`, 'phone_1')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="text" class="form-control" v-model="this.form.phone_1" v-mask="['(##) ####-####', '(##) #####-####']">
              </div>
            </div>

            <div class="col-md-4">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.phone_2 !== this.form.log_update.Phone_2 }">
                <label class="form-label">{{ `${$t('label.telefone')} 2` }}</label>
                <button v-if="pendingAnalysis && this.form.phone_2 !== this.form.log_update.Phone_2" class="btn" type="button" @click="analysis(`${$t('label.telefone')} 2`, 'phone_2')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="text" class="form-control" v-model="this.form.phone_2" v-mask="['(##) ####-####', '(##) #####-####']">
              </div>
            </div>

            <div class="col-md-4">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.phone_3 !== this.form.log_update.Phone_3 }">
                <label class="form-label">{{ `${$t('label.telefone')} 3` }}</label>
                <button v-if="pendingAnalysis && this.form.phone_3 !== this.form.log_update.Phone_3" class="btn" type="button" @click="analysis(`${$t('label.telefone')} 3`, 'phone_3')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="text" class="form-control" v-model="this.form.phone_3" v-mask="['(##) ####-####', '(##) #####-####']">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.email_1 !== this.form.log_update.Email_1 }">
                <label class="form-label">{{ `${$t('label.email')} 1` }}</label>
                <button v-if="pendingAnalysis && this.form.email_1 !== this.form.log_update.Email_1" class="btn" type="button" @click="analysis(`${$t('label.email')} 1`, 'email_1')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="text" class="form-control" v-model="this.form.email_1">
              </div>
            </div>

            <div class="col-md-6">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.email_2 !== this.form.log_update.Email_2 }">
                <label class="form-label">{{ `${$t('label.email')} 2` }}</label>
                <button v-if="pendingAnalysis && this.form.email_2 !== this.form.log_update.Email_2" class="btn" type="button" @click="analysis(`${$t('label.email')} 2`, 'email_2')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="text" class="form-control" v-model="this.form.email_2">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.website !== this.form.log_update.Website }">
                <label class="form-label">{{ $t('label.website') }}</label>
                <button v-if="pendingAnalysis && this.form.website !== this.form.log_update.Website" class="btn" type="button" @click="analysis($t('label.website'), 'website')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="text" class="form-control" v-model="this.form.website">
              </div>
            </div>

            <div class="col-md-6">
              <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.social_media !== this.form.log_update.Social_media }">
                <label class="form-label">{{ $t('label.midiasocial') }}</label>
                <button v-if="pendingAnalysis && this.form.social_media !== this.form.log_update.Social_media" class="btn" type="button" @click="analysis($t('label.midiasocial'), 'social_media')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="text" class="form-control" v-model="this.form.social_media">
              </div>
            </div>
          </div>
          <div class="col-12 p-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="whats_app" v-model="this.form.whats_app">
              <label class="form-check-label" for="whats_app">{{ $t('label.whatsAppGroup') }}</label>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <div :class="{ 'pending_analysis': pendingAnalysis && this.form.log_update && this.form.lat !== this.form.log_update.Lat }">
                <label for="latitude" class="form-label">{{ $t('label.latitude') }}</label>
                <button v-if="pendingAnalysis && this.form.log_update && this.form.lat !== this.form.log_update.Lat" class="btn" type="button" @click="analysis($t('label.latitude'), 'latitude')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="number" class="form-control" id="latitude" v-model="form.lat">
              </div>
            </div>
            <div class="col-md-6">
              <div :class="{ 'pending_analysis': pendingAnalysis && this.form.log_update && this.form.long !== this.form.log_update.Long }">
                <label for="longitude" class="form-label">{{ $t('label.longitude') }}</label>
                <button v-if="pendingAnalysis && this.form.log_update && this.form.long !== this.form.log_update.Long" class="btn" type="button" @click="analysis($t('label.long'), 'longitude')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="material-icons">search</i>
                </button>
                <input type="number" class="form-control" id="longitude" v-model="form.long">
              </div>
            </div>
          </div>
          <div class="pt-3" >
            <div id="mapContainer" style="height: 766px;width: 100%;"></div>
          </div>
        </div>

        <div class="col-md-12">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.obs !== this.form.log_update.Obs }">
              <label class="form-label">{{ $t('observacao') }}</label>
              <button v-if="pendingAnalysis && this.form.obs !== this.form.log_update.Obs" class="btn" type="button" @click="analysis($t('observacao'), 'observacao')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
                <textarea class="form-control" v-model="this.form.obs" rows="3"></textarea>
            </div>
          </div>
        <div class="col-md-12 mt-5 d-flex justify-content-start ">
          <button type="button" @click="save" class="btn btn-primary me-3">{{pendingAnalysis ? $t('label.aprovar') : $t('label.salvar')}}</button>
          <button type="button" @click="cancel" class="btn btn-danger">{{ $t('label.cancelar') }}</button>
        </div>
      </div>
    </form>
    <!-- Modal -->
    <AppModalReview :form="form" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import AppPageTitle from '../../components/AppPageTitle.vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import AppModalReview from '../../components/AppModalReview.vue'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
export default {
  name: 'RedeFormView',
  components: { AppPageTitle, AppModalReview },
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      pageTitle: `${this.$t('label.rededeplantadorxs')} > ${this.$t('label.adicionar')}`,
      action: this.constants.actionNew,
      form: {
        species: {},
        log_update: {}
      },
      municipalitiesList: [{ id_municipality: null, text: this.$t('label.municipio') }],
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      },
      map: null,
      marker: null,
      myCoordinates: {
        lat: -27.5707056,
        lng: -48.7504603
      }
    }
  },
  validations () {
    return {
      form: {
        property: { required },
        id_municipality: { required },
        date: {
          minLength: minLength(10),
          validDate (value) {
            if (!value) { return true }
            return this.$filters.dateIsValid(value)
          }
        }
      }
    }
  },
  watch: {
    'form.lat' (newLat, oldLat) {
      if (newLat && newLat !== oldLat) {
        this.updateMarker()
      }
    },
    'form.long' (newLong, oldLong) {
      if (newLong !== oldLong) {
        this.updateMarker()
      }
    }
  },
  mounted () {
    this.map = L.map('mapContainer').setView([this.myCoordinates.lat, this.myCoordinates.lng], 13)
    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map)
    // use a mix of renderers
    const customPane = this.map.createPane('customPane')
    customPane.style.zIndex = 399 // put just behind the standard overlay pane which is at 400
    const LeafIcon = L.Icon.extend({
      options: {
        iconAnchor: [22, 94],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76]
      }
    })
    const greenIcon = new LeafIcon({
      iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.6.0/images/marker-icon.png'
    })

    this.marker = L.marker([this.myCoordinates.lat, this.myCoordinates.lng], { icon: greenIcon, draggable: 'true' }).addTo(this.map)
    this.marker.on('dragend', this.dragStartHandler)

    this.$getLocation()
      .then((coordinates) => {
        this.myCoordinates.lat = coordinates.lat
        this.myCoordinates.lng = coordinates.lng
        const latLng = [this.myCoordinates.lat, this.myCoordinates.lng]
        this.marker.setLatLng(latLng, {
          draggable: 'true'
        }).bindPopup(latLng).update()
        this.map.panTo(latLng)
      })
  },
  onBeforeUnmount () {
    if (this.map) {
      this.map.remove()
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.form.pending_analysis && this.$store.state.user.sysUser.user_level === 1
    }
  },
  methods: {
    updateMarker () {
      if (!this.form.lat && !this.form.long) {
        return
      }
      const latLng = [this.form.lat || 0, this.form.long || 0]
      this.marker.setLatLng(latLng, {
        draggable: 'true'
      }).bindPopup(latLng).update()
      this.map.panTo(latLng)
    },
    dragStartHandler (e) {
      const { lat, lng } = e.target.getLatLng()
      this.form.lat = lat
      this.form.long = lng
    },
    modalValuesSimple: function (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis: async function (label, input) {
      let newValue = ''
      let oldValue = ''
      switch (input) {
        case 'property':
          this.modalValuesSimple(this.form.property, this.form.log_update.Property, label)
          break
        case 'location':
          this.modalValuesSimple(this.form.location, this.form.log_update.Location, label)
          break
        case 'id_municipality':
          newValue = this.municipalitiesList.filter(l => l.id === this.form.id_municipality)[0]
          oldValue = this.municipalitiesList.filter(l => l.id === this.form.log_update.Id_municipality)[0]
          if (!oldValue) {
            oldValue = await this.getMunicipalitiesComboById(this.form.log_update.Id_municipality)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
        case 'owner':
          this.modalValuesSimple(this.form.owner, this.form.log_update.Owner, label)
          break
        case 'contact':
          this.modalValuesSimple(this.form.contact, this.form.log_update.Contact, label)
          break
        case 'phone_1':
          this.modalValuesSimple(this.form.phone_1, this.form.log_update.Phone_1, label)
          break
        case 'phone_2':
          this.modalValuesSimple(this.form.phone_2, this.form.log_update.Phone_2, label)
          break
        case 'phone_3':
          this.modalValuesSimple(this.form.phone_3, this.form.log_update.Phone_3, label)
          break
        case 'email_1':
          this.modalValuesSimple(this.form.email_1, this.form.log_update.Email_1, label)
          break
        case 'email_2':
          this.modalValuesSimple(this.form.email_2, this.form.log_update.Email_2, label)
          break
        case 'website':
          this.modalValuesSimple(this.form.website, this.form.log_update.Website, label)
          break
        case 'social_media':
          this.modalValuesSimple(this.form.social_media, this.form.log_update.Social_media, label)
          break
        case 'latitude':
          this.modalValuesSimple(this.form.lat, this.form.log_update.Lat, label)
          break
        case 'longitude':
          this.modalValuesSimple(this.form.long, this.form.log_update.Long, label)
          break
        case 'date':
          this.modalValuesSimple(this.form.date, this.form.log_update.Date, label)
          break
        case 'hectares':
          this.modalValuesSimple(this.form.hectares, this.form.log_update.Hectares, label)
          break
        case 'observacao':
          this.modalValuesSimple(this.form.obs, this.form.log_update.Obs, label)
          break
      }
    },
    getById: async function (id) {
      this.$store.dispatch('showPreload')
      await axios.get(`/Planters/${id}`)
        .then(response => {
          this.form = response.data
          this.form.date = this.$filters.formatDate(this.form.date)
          if (this.form.log_update) {
            this.form.log_update = JSON.parse(this.form.log_update)
            this.form.log_update.Date = this.$filters.formatDate(this.form.log_update.Date)
          } else {
            this.form.log_update = {}
          }
        })
    },
    getMunicipalitiesComboById: async function (id) {
      const params = {
        id_municipality: id
      }
      let result = {}
      await axios.get('/Municipalities/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getMunicipalitiesCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.municipality = search
        params.id_municipality = this.form.id_municipality
        loading(true)
        axios.get('/Municipalities/getallcombo', { params })
          .then(response => {
            this.municipalitiesList = response.data.items
            this.municipalitiesList.unshift({ id_municipality: null, text: this.$t('label.municipio') })
            loading(false)
          })
      } else if (!search) {
        params.id_municipality = this.form.id_municipality
        axios.get('/Municipalities/getallcombo', { params })
          .then(response => {
            this.municipalitiesList = response.data.items
            this.municipalitiesList.unshift({ id_municipality: null, text: this.$t('label.municipio') })
          })
      }
    },
    save: function () {
      this.v$.$touch()
      if (this.v$.form.$invalid) {
        return
      }
      if (!this.form.hectares) {
        this.form.hectares = null
      }
      this.form.log_update = JSON.stringify(this.form.log_update)
      let modelRequest = {}
      modelRequest = { ...this.form }
      if (this.form.date) {
        modelRequest.date = this.$filters.formatDateToBackend(this.form.date)
      }
      if (this.action === this.constants.actionEdit) {
        axios.put('/Planters', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.form.log_update = JSON.parse(this.form.log_update)
            this.cancel()
          })
      } else {
        axios.post('/Planters', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.cancel()
          })
      }
    },
    cancel: function () {
      this.$router.replace('/rede')
    }
  },
  created: async function () {
    this.getMunicipalitiesCombo()

    this.action =
      this.$route.params.id !== this.constants.actionNew
        ? this.constants.actionEdit
        : this.constants.actionNew

    if (this.action === this.constants.actionEdit) {
      this.pageTitle = `${this.$t('label.rededeplantadorxs')} > ${this.$t('label.editar')}`
      await this.getById(this.$route.params.id)
      this.getMunicipalitiesCombo()
    }
  }
}
</script>
