<template>
  <vue-progress-bar></vue-progress-bar>
  <Loading :active="this.$store.state.preload"
          :is-full-page="true" :width="45" :color="'#007bff'" style="z-index: 9999"></Loading>
  <div v-if="user && user.sysUser" class="app align-content-stretch d-flex flex-wrap" :class="{ 'sidebar-hidden' : isMenuCollapsed}">
    <!-- sidebar nav -->
      <AppSidebar/>
    <!-- /sidebar nav -->

    <!-- main content -->
    <div class="app-container">
      <div class="app-content">
        <!-- app header -->
        <AppHeader/>
        <!-- /app-header -->

        <div class="content-wrapper">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <!-- /main content -->
  </div>
  <LoginView v-else/>
</template>
<script>
import '@/util/bootstrap'
import AppSidebar from '@/components/AppSidebar.vue'
import AppHeader from '@/components/AppHeader.vue'
import LoginView from '@/views/login/LoginView.vue'
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
import { mapState } from 'vuex'
export default {
  name: 'App',
  components: { AppSidebar, AppHeader, Loading, LoginView },
  computed: mapState(['isMenuCollapsed', 'user'])
}
</script>
<style>
@import url("./assets/css/main.css");
@import url("./assets/css/darktheme.css");
@import url("./assets/css/custom.css");
@import url("./assets/css/custom-forms.css");
@import url("./assets/css/datatables.css");
@import url("./assets/css/salvando-arvores-custom.css");
</style>
