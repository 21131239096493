<template>
  <div class="container">
    <AppPageTitle :title="pageTitle" :pendingAnalysis="pendingAnalysis" />
    <form class="">
      <div class="row pb-5">
        <div class="col-md-6">
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.code !== this.form.log_update.Code }">
            <label class="form-label">{{ $t('label.codigodamuda') }}</label>
            <button v-if="pendingAnalysis && this.form.code !== this.form.log_update.Code" class="btn" type="button" @click="analysis($t('label.codigodamuda'), 'code')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="text" class="form-control" v-model="this.form.code" maxlength="3">
          </div>
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_property !== this.form.log_update.Id_property }">
            <label class="form-label">{{ $t('label.nomedapropriedade') }}</label>
            <button v-if="pendingAnalysis && this.form.id_property !== this.form.log_update.Id_property" class="btn" type="button" @click="analysis($t('label.nomedapropriedade'), 'id_property')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <v-select :appendToBody="true" :filterable="false" @search="getPlantersCombo" :options="plantersList"
              v-model="form.id_property" label="text" index="id"
              :reduce="r => r.id" :clearable="false" class="vue-select" :class="{ 'is-invalid': v$.form.id_property.$dirty && v$.form.id_property.$error }">
              <template v-slot:selected-option="option">
                {{ `${option.text}` }}
              </template>
              <template v-slot:option="option">
                {{ `${option.text}` }}
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                </template>
              </template>
            </v-select>
            <div class="invalid-feedback">
              {{ $t('message.requiredField') }}
            </div>
          </div>
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_species !== this.form.log_update.Id_species }">
            <label class="form-label">{{ $t('label.nomecientificonomepopular') }}</label>
            <button v-if="pendingAnalysis && this.form.id_species !== this.form.log_update.Id_species" class="btn" type="button" @click="analysis($t('label.nomecientificonomepopular'), 'id_species')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <v-select :appendToBody="true" :filterable="false" @search="getSpeciesCombo" :options="speciesList"
              v-model="form.id_species" label="text" index="id"
              :reduce="r => r.id" :clearable="false" class="vue-select" :class="{ 'is-invalid': v$.form.id_species.$dirty && v$.form.id_species.$error }">
              <template v-slot:selected-option="option">
                {{ `${option.text}` }}
              </template>
              <template v-slot:option="option">
                {{ `${option.text}` }}
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                </template>
              </template>
            </v-select>
            <div class="invalid-feedback">
              {{ $t('message.requiredField') }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-md-12">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.quantity !== this.form.log_update.Quantity }">
              <label class="form-label">{{ $t('label.quantidade') }}</label>
              <button v-if="pendingAnalysis && this.form.quantity !== this.form.log_update.Quantity" class="btn" type="button" @click="analysis($t('label.quantidade'), 'quantity')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <input type="text" class="form-control" v-model="this.form.quantity" :class="{ 'is-invalid': v$.form.quantity.$dirty && v$.form.quantity.$error }">
              <div class="invalid-feedback">
                {{ $t('message.requiredField') }}
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.date !== this.form.log_update.Date }">
              <label class="form-label">{{ $t('label.data') }}</label>
              <button v-if="pendingAnalysis && this.form.date !== this.form.log_update.Date" class="btn" type="button" @click="analysis($t('label.data'), 'date')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="material-icons">search</i>
              </button>
              <input type="text" class="form-control" v-model="this.form.date" v-mask="'##/##/####'"  :class="{ 'is-invalid': v$.form.date.$dirty && v$.form.date.$error }">
              <div class="invalid-feedback">
                {{ $t('message.invalidDate') }}
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_mothertree !== this.form.log_update.Id_mothertree }">
            <label class="form-label">{{ $t('label.codigodamatriz') }}</label>
            <button v-if="pendingAnalysis && this.form.id_mothertree !== this.form.log_update.Id_mothertree" class="btn" type="button" @click="analysis($t('label.codigodamatriz'), 'id_mothertree')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <v-select :appendToBody="true" :filterable="false" @search="getMotherTreesCombo" :options="motherTreesList"
              v-model="form.id_mothertree" label="text" index="id"
              :reduce="r => r.id" :clearable="false" class="vue-select">
              <template v-slot:selected-option="option">
                {{ `${option.text ?? ''}` }}
              </template>
              <template v-slot:option="option">
                {{ `${option.text ?? ''}` }}
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                </template>
              </template>
            </v-select>
          </div>
          </div>
        </div>

        <div class="col-md-12 mt-5 d-flex justify-content-end ">
          <button type="button" @click="save" class="btn btn-primary me-3">{{pendingAnalysis ? $t('label.aprovar') : $t('label.salvar')}}</button>
          <button type="button" @click="cancel" class="btn btn-danger">{{ $t('label.cancelar') }}</button>
        </div>
      </div>
    </form>
    <!-- Modal -->
    <AppModalReview :form="form" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import AppPageTitle from '../../components/AppPageTitle.vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import AppModalReview from '../../components/AppModalReview.vue'
export default {
  name: 'MudasFormView',
  components: { AppPageTitle, AppModalReview },
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      pageTitle: `${this.$t('label.mudasplantadas')} > ${this.$t('label.adicionar')}`,
      action: this.constants.actionNew,
      form: {
        species: {},
        log_update: {}
      },
      plantersList: [{ id_property: null, text: this.$t('label.nomedapropriedade') }],
      speciesList: [{ id_species: null, text: this.$t('label.nomecientificonomepopular') }],
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      }
    }
  },
  validations () {
    return {
      form: {
        id_property: { required },
        id_species: { required },
        quantity: { required },
        date: {
          minLength: minLength(10),
          validDate (value) {
            if (!value) { return true }
            return this.$filters.dateIsValid(value)
          }
        }
      }
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.form.pending_analysis && this.$store.state.user.sysUser.user_level === 1
    }
  },
  methods: {
    modalValuesSimple: function (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis: async function (label, input) {
      let newValue = ''
      let oldValue = ''
      switch (input) {
        case 'id_property':
          newValue = this.plantersList.filter(l => l.id === this.form.id_property)[0]
          oldValue = this.plantersList.filter(l => l.id === this.form.log_update.Id_property)[0]
          if (!oldValue) {
            oldValue = await this.getSpeciesComboById(this.form.log_update.Id_property)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
        case 'id_species':
          newValue = this.speciesList.filter(l => l.id === this.form.id_species)[0]
          oldValue = this.speciesList.filter(l => l.id === this.form.log_update.Id_species)[0]
          if (!oldValue) {
            oldValue = await this.getSpeciesComboById(this.form.log_update.Id_species)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
        case 'quantity':
          this.modalValuesSimple(this.form.quantity, this.form.log_update.Quantity, label)
          break
        case 'date':
          this.modalValuesSimple(this.form.date, this.form.log_update.Date, label)
          break
        case 'code':
          this.modalValuesSimple(this.form.code, this.form.log_update.Code, label)
          break
        case 'id_mothertree':
          newValue = this.motherTreesList.filter(l => l.id === this.form.id_mothertree)[0]
          oldValue = this.motherTreesList.filter(l => l.id === this.form.log_update.Id_mothertree)[0]
          if (!oldValue) {
            oldValue = await this.getMotherTreesComboById(this.form.log_update.Id_mothertree)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
      }
    },
    getById: async function (id) {
      this.$store.dispatch('showPreload')
      await axios.get(`/TreesPlanted/${id}`)
        .then(response => {
          this.form = response.data
          this.form.date = this.$filters.formatDate(this.form.date)
          if (this.form.log_update) {
            this.form.log_update = JSON.parse(this.form.log_update)
            this.form.log_update.Date = this.$filters.formatDate(this.form.log_update.Date)
          } else {
            this.form.log_update = {}
          }
        })
    },
    getSpeciesComboById: async function (id) {
      const params = {
        id_species: id
      }
      let result = {}
      await axios.get('/species/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getSpeciesCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.latin_name = search
        params.common_name = search
        params.id_species = this.form.id_species
        loading(true)
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
            loading(false)
          })
      } else if (!search) {
        params.id_species = this.form.id_species
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
          })
      }
    },
    getPlantersComboById: async function (id) {
      const params = {
        id_property: id
      }
      let result = {}
      await axios.get('/planters/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getPlantersCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.property = search
        params.id_property = this.form.id_property
        loading(true)
        axios.get('/planters/getallcombo', { params })
          .then(response => {
            this.plantersList = response.data.items
            this.plantersList.unshift({ id_property: null, text: this.$t('label.nomedapropriedade') })
            loading(false)
          })
      } else if (!search) {
        params.id_property = this.form.id_property
        axios.get('/planters/getallcombo', { params })
          .then(response => {
            this.plantersList = response.data.items
            this.plantersList.unshift({ id_property: null, text: this.$t('label.nomedapropriedade') })
          })
      }
    },
    save: function () {
      this.v$.$touch()
      if (this.v$.form.$invalid) {
        return
      }
      this.form.log_update = JSON.stringify(this.form.log_update)
      let modelRequest = {}
      modelRequest = { ...this.form }
      if (this.form.date) {
        modelRequest.date = this.$filters.formatDateToBackend(this.form.date)
      }
      if (this.action === this.constants.actionEdit) {
        axios.put('/TreesPlanted', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.form.log_update = JSON.parse(this.form.log_update)
            this.cancel()
          })
      } else {
        axios.post('/TreesPlanted', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.cancel()
          })
      }
    },
    cancel: function () {
      this.$router.replace('/mudas')
    },
    getMotherTreesComboById: async function (id) {
      const params = {
        id_mothertree: id
      }
      let result = {}
      await axios.get('/MotherTrees/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getMotherTreesCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.code = search
        params.id_mothertree = this.form.id_mothertree
        loading(true)
        axios.get('/MotherTrees/getallcombo', { params })
          .then(response => {
            this.motherTreesList = response.data.items
            this.motherTreesList.unshift({ id_mothertree: null, text: this.$t('label.codigodamatriz') })
            loading(false)
          })
      } else if (!search) {
        params.id_mothertree = this.form.id_mothertree
        axios.get('/MotherTrees/getallcombo', { params })
          .then(response => {
            this.motherTreesList = response.data.items
            this.motherTreesList.unshift({ id_mothertree: null, text: this.$t('label.codigodamatriz') })
          })
      }
    }
  },
  created: async function () {
    this.getSpeciesCombo()
    this.getPlantersCombo()
    this.getMotherTreesCombo()
    this.action =
      this.$route.params.id !== this.constants.actionNew
        ? this.constants.actionEdit
        : this.constants.actionNew

    if (this.action === this.constants.actionEdit) {
      this.pageTitle = `${this.$t('label.mudasplantadas')} > ${this.$t('label.editar')}`
      await this.getById(this.$route.params.id)
      this.getPlantersCombo()
      this.getMotherTreesCombo()
    }
  }
}
</script>
