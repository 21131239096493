<template>
  <div class="app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end">
    <div class="app-auth-background"><img>
    </div>
    <form @submit.prevent="submit" class="app-auth-container">
      <div class="logo">
          <img src="../../assets/images/logo-salvando-arvores1.png" alt="Salvando Árvores da Extinção" />
      </div>
      <p class="auth-description">{{ $t('label.porfavorfacaloginparaacessaroadmin') }}</p>
      <div class="auth-credentials m-b-xxl">
          <label for="signInEmail" class="form-label">{{ $t('label.email') }}</label>
          <input type="email" class="form-control m-b-md" id="signInEmail" aria-describedby="signInEmail" v-model="form.login"
            :class="{ 'is-invalid': v$.form.login.$dirty && v$.form.login.$error }">
            <div class="invalid-feedback">
              {{ $t('message.requiredField') }}
            </div>
          <label for="signInPassword" class="form-label">{{ $t('label.senha') }}</label>
          <input type="password" class="form-control" id="signInPassword" aria-describedby="signInPassword" v-model="form.password"
            :class="{ 'is-invalid': v$.form.password.$dirty && v$.form.password.$error }">
            <div class="invalid-feedback">
              {{ $t('message.requiredField') }}
            </div>
      </div>
      <div class="auth-submit">
          <button type="submit" class="btn btn-primary">{{ $t('label.acessar') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
export default {
  name: 'LoginView',
  setup: () => ({ v$: useVuelidate() }),
  data: () => ({
    form: {
      login: '',
      password: '',
      fromApp: false
    }
  }),
  validations () {
    return {
      form: {
        login: { required, email },
        password: { required }
      }
    }
  },
  methods: {
    submit () {
      this.v$.$touch()
      if (this.v$.form.$invalid === false) {
        axios.post('/login', this.form).then(response => {
          if (response.data.token) {
            this.$store.commit('setUser', response.data)
            this.$router.push('/')
          }
        })
      }
    }
  }
}
</script>
