<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="page-description">
          <h1>Dashboard</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-6 col-xl-12">
        <div class="row">
          <div class="col-md-4">
            <div class="card widget widget-stats text-center">
              <div class="card-body widget-stats1">
                <div class="widget-stats-container">
                  <div
                    class="widget-stats-icon widget-stats-icon-primary"
                  >
                    <i class="material-icons-outlined">paid</i>
                  </div>
                  <div class="widget-stats-content flex-fill">
                    <span class="widget-stats-title"
                      >{{ $t('label.numero') }} <br />{{ $t('label.dematrizes') }}</span
                    >
                    <span class="widget-stats-amount">{{motherTreesCount}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card widget widget-stats text-center">
              <div class="card-body widget-stats2">
                <div class="widget-stats-container">
                  <div
                    class="widget-stats-icon widget-stats-icon-primary"
                  >
                    <i class="material-icons-outlined">paid</i>
                  </div>
                  <div class="widget-stats-content flex-fill">
                    <span class="widget-stats-title"
                      >{{ $t('label.registradas') }} <br />{{ $t('label.peloapp') }}</span
                    >
                    <span class="widget-stats-amount">{{sendlingFromAppCount}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card widget widget-stats text-center">
              <div class="card-body widget-stats3">
                <div class="widget-stats-container">
                  <div
                    class="widget-stats-icon widget-stats-icon-primary"
                  >
                    <i class="material-icons-outlined">paid</i>
                  </div>
                  <div class="widget-stats-content flex-fill">
                    <span class="widget-stats-title"
                      >{{ $t('label.numero') }} <br />{{ $t('label.deespecies') }}</span
                    >
                    <span class="widget-stats-amount">{{speciesCount}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card widget widget-stats text-center">
              <div class="card-body widget-stats4">
                <div class="widget-stats-container">
                  <div
                    class="widget-stats-icon widget-stats-icon-primary"
                  >
                    <i class="material-icons-outlined">paid</i>
                  </div>
                  <div class="widget-stats-content flex-fill">
                    <span class="widget-stats-title"
                      >{{ $t('label.sementes') }} <br />{{ $t('label.coletadas') }}</span
                    >
                    <span class="widget-stats-amount">{{seedsCount}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--<div class="col-md-4">
            <div class="card widget widget-stats text-center">
              <div class="card-body widget-stats5">
                <div class="widget-stats-container">
                  <div
                    class="widget-stats-icon widget-stats-icon-primary"
                  >
                    <i class="material-icons-outlined">paid</i>
                  </div>
                  <div class="widget-stats-content flex-fill">
                    <span class="widget-stats-title"
                      >{{ $t('label.frutas') }}<br />
                      {{ $t('label.coletadas') }}</span
                    >
                    <span class="widget-stats-amount">{{fuitsCount}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>-->

          <div class="col-md-4">
            <div class="card widget widget-stats text-center">
              <div class="card-body widget-stats6">
                <div class="widget-stats-container">
                  <div
                    class="widget-stats-icon widget-stats-icon-primary"
                  >
                    <i class="material-icons-outlined">paid</i>
                  </div>
                  <div class="widget-stats-content flex-fill">
                    <span class="widget-stats-title"
                      >{{ $t('label.redede') }}<br />{{ $t('label.plantadores') }}</span
                    >
                    <span class="widget-stats-amount">{{plantersCount}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card widget widget-stats text-center">
              <div class="card-body widget-stats6">
                <div class="widget-stats-container">
                  <div
                    class="widget-stats-icon widget-stats-icon-primary"
                  >
                    <i class="material-icons-outlined">paid</i>
                  </div>
                  <div class="widget-stats-content flex-fill">
                    <span class="widget-stats-title"
                      >{{ $t('label.mudasdistribuidas1') }}<br />{{ $t('label.mudasdistribuidas2') }}</span
                    >
                    <span class="widget-stats-amount">{{sumNumSeedlings}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="card card-chart">
              <div class="card-body">
                <apexchart
                  :options="chartOptions"
                  :series="series"
                  height="200"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-6 col-xl-12">
        <h3 class="mb-3">{{ $t('label.propriedades') }}:</h3>
        <div id="mapContainer" style="height: 620px;width: 100%;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
export default {
  name: 'HomeView',
  data: function () {
    return {
      motherTreesCount: 0,
      sendlingFromAppCount: 0,
      speciesCount: 0,
      seedsCount: 0,
      fuitsCount: 0,
      plantersCount: 0,
      sumNumSeedlings: 0,
      chartOptions: {
        chart: {
          type: 'bar',
          id: 'apex3'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            borderRadius: 10
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: 'rgba(94, 96, 110, .5)'
            }
          }
        },
        yaxis: {
          title: {
            text: this.$t('label.quantidade')
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        grid: {
          borderColor: 'rgba(94, 96, 110, .5)',
          strokeDashArray: 4
        }
      },
      series: [{
        name: this.$t('label.mudasdistribuidas'),
        data: []
      }, {
        name: this.$t('label.sementescoletadas'),
        data: []
      }, {
        name: this.$t('label.registrosapp'),
        data: []
      }, {
        name: this.$t('label.matrizes'),
        data: []
      }],
      map: null,
      coordenates: []
    }
  },
  mounted () {
    this.map = L.map('mapContainer').setView([-19, -51], 4)
    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map)
    // use a mix of renderers
    const customPane = this.map.createPane('customPane')
    customPane.style.zIndex = 399 // put just behind the standard overlay pane which is at 400
  },
  onBeforeUnmount () {
    if (this.map) {
      this.map.remove()
    }
  },
  methods: {
    updateAccessDate: function () {
      this.$store.dispatch('showPreload')
      axios.post(`/User/updateaccessdate/${this.$store.state.user.sysUser.id_user}`)
    },
    loadCounts: async function () {
      this.$store.dispatch('showPreload')
      await axios.get('/MotherTrees/countrows').then(r => {
        this.motherTreesCount = r.data
      })
      await axios.get('/SeedlingCoord/countrowsbyapp').then(r => {
        this.sendlingFromAppCount = r.data
      })
      await axios.get('/Species/countrows').then(r => {
        this.speciesCount = r.data
      })
      await axios.get('/EkoaCollect/sumfuitsseeds').then(r => {
        this.seedsCount = r.data.n_seeds
        this.fuitsCount = r.data.n_fruits
      })
      await axios.get('/Planters/countrows').then(r => {
        this.plantersCount = r.data
      })
      await axios.get('/EkoaExped/sumnumseedlings').then(r => {
        this.sumNumSeedlings = r.data
      })
      await axios.get('/EkoaExped/chart').then(r => {
        r.data.forEach(element => {
          this.chartOptions.xaxis.categories.push(element.month)
          this.series[0].data.push(element.count)
        })
      })
      await axios.get('/EkoaCollect/chart/seeds').then(r => {
        r.data.forEach(element => {
          this.chartOptions.xaxis.categories.push(element.month)
          this.series[1].data.push(element.count)
        })
      })
      await axios.get('/SeedlingCoord/chart').then(r => {
        r.data.forEach(element => {
          this.chartOptions.xaxis.categories.push(element.month)
          this.series[2].data.push(element.count)
        })
      })
      await axios.get('/MotherTrees/chart').then(r => {
        r.data.forEach(element => {
          this.chartOptions.xaxis.categories.push(element.month)
          this.series[3].data.push(element.count)
        })
      })
      await axios.get('/Planters/allcoordinates').then(r => {
        this.coordenates = r.data
        this.loadCoordenatesMap()
      })
    },
    loadCoordenatesMap: function () {
      const LeafIcon = L.Icon.extend({
        options: {
          iconAnchor: [22, 94],
          shadowAnchor: [4, 62],
          popupAnchor: [-3, -76]
        }
      })
      const greenIcon = new LeafIcon({
        iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.6.0/images/marker-icon.png'
      })
      this.coordenates.forEach(e => {
        L.marker([e.lat, e.long], { icon: greenIcon }).addTo(this.map)
      })
      this.map.setView([this.coordenates[0].lat, this.coordenates[0].long], 10)
    }
  },
  created: function () {
    this.updateAccessDate()
    this.loadCounts()
  }
}
</script>
