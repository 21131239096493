<template>
  <div class="container">
    <div class="row">
      <div>
        <div class="page-description">
          <h1>{{$t('label.exportarplantadores')}}</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-md-12 flex-row align-self-center">
        <!-- busca lista -->
        <form class="fitro-lista" @submit.prevent="exportar">
          <div class="row pb-0">
            <div class="col-12 text-right">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="pendenteAnalise" v-model="$store.state.formSearch.pending_analysis">
                <label class="form-check-label" for="pendenteAnalise">{{ $t('label.pendenteAnalise') }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.nomedapropriedade')" v-model="$store.state.formSearch.property">
            </div>
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.municipio')" v-model="$store.state.formSearch.municipality">
            </div>
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.proprietarix')" v-model="$store.state.formSearch.owner">
            </div>
            <div class="col-md-12  d-flex justify-content-end mt-1 align-items-end">
              <button type="button" @click="clear" class="btn btn-outline-secondary ">
                {{ $t('label.limpar') }}
              </button>
              <button type="submit" class="btn btn-primary ms-2">{{ $t('label.exportar') }}</button>
            </div>
          </div>
        </form>
        <!-- /busca lista -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import * as XLSX from 'xlsx'
export default {
  name: 'ExportarPlantadores',
  data () {
    return {
      header: [
        'property',
        'Owner',
        'contact',
        'location',
        'municipality',
        'state',
        'phone_1',
        'phone_2',
        'phone_3',
        'lat',
        'long'
      ]
    }
  },
  methods: {
    clear: function () {
      this.$store.commit('clearFormSearch')
    },
    exportar: function () {
      this.$store.dispatch('showPreload')
      const params = this.$store.state.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      axios.get('/ExportData/exportarplantadores', { params })
        .then(response => {
          const dados = XLSX.utils.json_to_sheet(response.data, {
            header: this.header
          })
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, dados, 'Sheet')
          XLSX.writeFile(wb, `plantadores-${Date.now()}.xlsx`)
        })
    }
  }
}
</script>
