<template>
  <div class="container">
    <AppPageTitle :title="pageTitle" :pendingAnalysis="pendingAnalysis" />
    <form class="">
      <div class="row pb-5">
        <div class="col-md-6">
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.municipality !== this.form.log_update.Municipality }">
            <label class="form-label">{{ $t('label.municipio') }}</label>
            <button v-if="pendingAnalysis && this.form.municipality !== this.form.log_update.Municipality" class="btn" type="button" @click="analysis($t('label.municipio'), 'municipality')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="text" class="form-control" v-model="this.form.municipality" :class="{ 'is-invalid': v$.form.municipality.$dirty && v$.form.municipality.$error }">
            <div class="invalid-feedback">
              {{ $t('message.requiredField') }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.state !== this.form.log_update.State }">
            <label class="form-label">{{ $t('label.estado') }}</label>
            <button v-if="pendingAnalysis && this.form.state !== this.form.log_update.State" class="btn" type="button" @click="analysis($t('label.estado'), 'state')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="text" class="form-control" v-model="this.form.state" maxlength="2" :class="{ 'is-invalid': v$.form.state.$dirty && v$.form.state.$error }">
            <div class="invalid-feedback">
              {{ $t('message.requiredField') }}
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-5 d-flex justify-content-end ">
          <button type="button" @click="save" class="btn btn-primary me-3">{{pendingAnalysis ? $t('label.aprovar') : $t('label.salvar')}}</button>
          <button type="button" @click="cancel" class="btn btn-danger">{{ $t('label.cancelar') }}</button>
        </div>
      </div>
    </form>
    <!-- Modal -->
    <AppModalReview :form="form" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import AppPageTitle from '../../components/AppPageTitle.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import AppModalReview from '../../components/AppModalReview.vue'
export default {
  name: 'MunicipiosFormView',
  components: { AppPageTitle, AppModalReview },
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      pageTitle: `${this.$t('label.municipios')} > ${this.$t('label.adicionar')}`,
      action: this.constants.actionNew,
      form: {
        log_update: {}
      },
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      }
    }
  },
  validations () {
    return {
      form: {
        municipality: { required },
        state: { required }
      }
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.form.pending_analysis && this.$store.state.user.sysUser.user_level === 1
    }
  },
  methods: {
    modalValuesSimple: function (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis: async function (label, input) {
      switch (input) {
        case 'municipality':
          this.modalValuesSimple(this.form.municipality, this.form.log_update.Municipality, label)
          break
        case 'state':
          this.modalValuesSimple(this.form.state, this.form.log_update.State, label)
          break
      }
    },
    getById: async function (id) {
      this.$store.dispatch('showPreload')
      await axios.get(`/municipalities/${id}`)
        .then(response => {
          this.form = response.data
          if (this.form.log_update) {
            this.form.log_update = JSON.parse(this.form.log_update)
          } else {
            this.form.log_update = {}
          }
        })
    },
    save: function () {
      this.v$.$touch()
      if (this.v$.form.$invalid) {
        return
      }
      this.form.log_update = JSON.stringify(this.form.log_update)
      let modelRequest = {}
      modelRequest = { ...this.form }
      if (this.action === this.constants.actionEdit) {
        axios.put('/municipalities', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.form.log_update = JSON.parse(this.form.log_update)
            this.cancel()
          })
      } else {
        axios.post('/municipalities', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.cancel()
          })
      }
    },
    cancel: function () {
      this.$router.replace('/municipios')
    }
  },
  created: async function () {
    this.action =
      this.$route.params.id !== this.constants.actionNew
        ? this.constants.actionEdit
        : this.constants.actionNew

    if (this.action === this.constants.actionEdit) {
      this.pageTitle = `${this.$t('label.municipios')} > ${this.$t('label.editar')}`
      await this.getById(this.$route.params.id)
    }
  }
}
</script>
