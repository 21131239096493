<template>
  <div class="app-sidebar">
    <div class="logo_salvando">
      <a href="/home">
        <img
          src="../../src/assets/images/logo-salvando-arvores1.png"
          alt="Salvando Árvores da Extinção"
        />
      </a>
    </div>
    <perfect-scrollbar class="app-menu">
      <ul class="accordion-menu">
        <li class="sidebar-title">{{ $t('label.tabelas') }}</li>
        <li :class="{ 'active-page' : $route.name === 'home' }">
          <router-link to="/"><i class="material-icons">dashboard</i>{{ $t('label.home') }}</router-link>
        </li>
        <li class="no-invert" :class="{ 'active-page' : $route.name === 'coleta' || $route.name === 'coletaForm' }">
          <router-link to="/coleta"><i class="material-icons">compost</i>{{ $t('label.coletadefrutosesementes') }}</router-link>
        </li>
        <li :class="{ 'active-page' : $route.name === 'exped-mudas' || $route.name === 'exped-mudasForm' }">
          <router-link to="/exped-mudas"><i class="material-icons">local_shipping</i>{{ $t('label.dadosexpedicaomudas') }}</router-link>
        </li>
        <li :class="{ 'active-page' : $route.name === 'germinacao' || $route.name === 'germinacaoForm' }">
          <router-link to="/germinacao"><i class="material-icons">emoji_nature</i>{{ $t('label.controlegerminacaosementes') }}</router-link>
        </li>
        <li :class="{ 'active-page' : $route.name === 'matrizes' || $route.name === 'matrizesForm' }">
          <router-link to="/matrizes"><i class="material-icons">spa</i>{{ $t('label.matrizes') }}</router-link>
        </li>
        <li class="no-invert" :class="{ 'active-page' : $route.name === 'municipios' || $route.name === 'municipiosForm' }">
          <router-link to="/municipios"><i class="material-icons">location_city</i>{{ $t('label.municipios') }}</router-link>
        </li>
        <li :class="{ 'active-page' : $route.name === 'rede' || $route.name === 'redeForm' }">
          <router-link to="/rede"><i class="material-icons">diversity_1</i>{{ $t('label.rededeplantadorxs') }}</router-link>
        </li>
        <li :class="{ 'active-page' : $route.name === 'coord-mudas' || $route.name === 'coord-mudasForm' }">
          <router-link to="/coord-mudas"><i class="material-icons">room</i>{{ $t('label.coordmudasplantadas') }}</router-link>
        </li>
        <li :class="{ 'active-page' : $route.name === 'especies' || $route.name === 'especiesForm' }">
          <router-link to="/especies"><i class="material-icons">park</i>{{ $t('label.especies') }}</router-link>
        </li>
        <li class="no-invert" :class="{ 'active-page' : $route.name === 'mudas' || $route.name === 'mudasForm' }">
          <router-link to="/mudas"><i class="material-icons">mode_of_travel</i>{{ $t('label.mudasplantadas') }}</router-link>
        </li>
        <template v-if="$store.state.user.sysUser.user_level === 1">
          <li class="sidebar-title">{{ $t('label.ferramentas') }}</li>
          <li :class="{ 'active-page' : $route.name === 'usuarios' || $route.name === 'usuariosForm' }">
            <router-link to="/usuarios"><i class="material-icons">manage_accounts</i>{{ $t('label.usuarios') }}</router-link>
          </li>
          <li :class="{ 'active-page' : $route.name === 'exportar-matrizes' ||
          $route.name === 'exportar-especies' ||
          $route.name === 'exportar-plantadores' ||
          $route.name === 'exportar-mudas-plantadas' ||
          $route.name === 'exportar-coord-mudas' ||
          $route.name === 'exportar-expedicao' ||
          $route.name === 'exportar-germinacao'}">
            <router-link to="/exportar-matrizes"><i class="material-icons">table_view</i>{{ $t('label.exportar') }}</router-link>

            <router-link v-if="$route.name === 'exportar-matrizes'" to="/exportar-matrizes" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;">{{ $t('label.exportarmatrizes') }}</router-link>
            <router-link v-else to="/exportar-matrizes" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;color: #fff !important;">{{ $t('label.exportarmatrizes') }}</router-link>

            <router-link v-if="$route.name === 'exportar-especies'" to="/exportar-especies" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;">{{ $t('label.exportarespecies') }}</router-link>
            <router-link v-else to="/exportar-especies" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;color: #fff !important;">{{ $t('label.exportarespecies') }}</router-link>

            <router-link v-if="$route.name === 'exportar-plantadores'" to="/exportar-plantadores" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;">{{ $t('label.exportarplantadores') }}</router-link>
            <router-link v-else to="/exportar-plantadores" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;color: #fff !important;">{{ $t('label.exportarplantadores') }}</router-link>

            <router-link v-if="$route.name === 'exportar-coord-mudas'" to="/exportar-coord-mudas" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;">{{ $t('label.exportarcoordmudas') }}</router-link>
            <router-link v-else to="/exportar-coord-mudas" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;color: #fff !important;">{{ $t('label.exportarcoordmudas') }}</router-link>

            <router-link v-if="$route.name === 'exportar-mudas-plantadas'" to="/exportar-mudas-plantadas" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;">{{ $t('label.exportarmudasplantadas') }}</router-link>
            <router-link v-else to="/exportar-mudas-plantadas" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;color: #fff !important;">{{ $t('label.exportarmudasplantadas') }}</router-link>

            <router-link v-if="$route.name === 'exportar-expedicao'" to="/exportar-expedicao" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;">{{ $t('label.exportarexpedicao') }}</router-link>
            <router-link v-else to="/exportar-expedicao" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;color: #fff !important;">{{ $t('label.exportarexpedicao') }}</router-link>

            <router-link v-if="$route.name === 'exportar-germinacao'" to="/exportar-germinacao" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;">{{ $t('label.exportargerminacao') }}</router-link>
            <router-link v-else to="/exportar-germinacao" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;color: #fff !important;">{{ $t('label.exportargerminacao') }}</router-link>

            <router-link v-if="$route.name === 'exportar-coletas'" to="/exportar-coletas" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;">{{ $t('label.exportarcoletas') }}</router-link>
            <router-link v-else to="/exportar-coletas" style="padding-left: 53px;padding-top: 0px;padding-bottom: 0px;color: #fff !important;">{{ $t('label.exportarcoletas') }}</router-link>
          </li>
        </template>
      </ul>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'AppSidebar'
}
</script>

<style>

</style>
