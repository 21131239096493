<template>
  <div class="container">
    <AppPageTitle :title="pageTitle" :pendingAnalysis="pendingAnalysis" />
    <form class="">
      <div class="row pb-5">
        <div class="col-md-6">
          <div>
            <label class="form-label">{{ $t('label.id') }}</label>
            <input class="form-control" v-model="this.form.id_species" type="text" disabled="disabled">
          </div>
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.sp_code !== this.form.log_update.Sp_code }">
            <label class="form-label">{{ $t('label.codigodaespecie') }}</label>
            <button v-if="pendingAnalysis && this.form.sp_code !== this.form.log_update.Sp_code" class="btn" type="button" @click="analysis($t('label.codigodaespecie'), 'sp_code')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="text" class="form-control" v-model="this.form.sp_code" maxlength="3" :class="{ 'is-invalid': v$.form.sp_code.$dirty && v$.form.sp_code.$error }">
            <div class="invalid-feedback">
              {{ $t('message.requiredField') }}
            </div>
          </div>
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.latin_name !== this.form.log_update.Latin_name }">
            <label class="form-label">{{ $t('label.nomecientifico') }}</label>
            <button v-if="pendingAnalysis && this.form.latin_name !== this.form.log_update.Latin_name" class="btn" type="button" @click="analysis($t('label.nomecientifico'), 'latin_name')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="text" class="form-control" v-model="this.form.latin_name" :class="{ 'is-invalid': v$.form.latin_name.$dirty && v$.form.latin_name.$error }">
            <div class="invalid-feedback">
              {{ $t('message.requiredField') }}
            </div>
          </div>
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.common_name !== this.form.log_update.Common_name }">
            <label class="form-label">{{ $t('label.nomepopular') }}</label>
            <button v-if="pendingAnalysis && this.form.common_name !== this.form.log_update.Common_name" class="btn" type="button" @click="analysis($t('label.nomepopular'), 'common_name')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="text" class="form-control" v-model="this.form.common_name">
          </div>
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.family !== this.form.log_update.Family }">
            <label class="form-label">{{ $t('label.familia') }}</label>
            <button v-if="pendingAnalysis && this.form.family !== this.form.log_update.Family" class="btn" type="button" @click="analysis($t('label.familia'), 'family')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="text" class="form-control" v-model="this.form.family">
          </div>
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.habitat !== this.form.log_update.Habitat }">
            <label class="form-label">{{ $t('label.habitat') }}</label>
            <button v-if="pendingAnalysis && this.form.habitat !== this.form.log_update.Habitat" class="btn" type="button" @click="analysis($t('label.habitat'), 'habitat')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="text" class="form-control" v-model="this.form.habitat">
          </div>
        </div>
        <div class="col-md-6">
          <div :class="{ 'pending_analysis':  pendingAnalysis && pendingAnalysisFruits }">
            <label class="form-label">{{ $t('label.frutificacao') }}</label>
            <button v-if="pendingAnalysis && pendingAnalysisFruits" class="btn" type="button" @click="analysis($t('label.frutificacao'), 'fruits')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <div class="row box-frutificacao ms-1 p-1">
                <div class="col-sm-6 col-md-2 mt-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="especiesFrutificacaoJaneiro" v-model="this.form.fruits_jan" true-value="yes" false-value="no">
                        <label class="form-check-label" for="especiesFrutificacaoJaneiro">{{ $t('label.janeiro') }}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-2 mt-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="especiesFrutificacaoFevereiro" v-model="this.form.fruits_feb" true-value="yes" false-value="no">
                        <label class="form-check-label" for="especiesFrutificacaoFevereiro">{{ $t('label.fevereiro') }}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-2 mt-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="especiesFrutificacaoMarco" v-model="this.form.fruits_mar" true-value="yes" false-value="no">
                        <label class="form-check-label" for="especiesFrutificacaoMarco">{{ $t('label.marco') }}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-2 mt-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="especiesFrutificacaoAbril" v-model="this.form.fruits_apr" true-value="yes" false-value="no">
                        <label class="form-check-label" for="especiesFrutificacaoAbril">{{ $t('label.abril') }}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-2 mt-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="especiesFrutificacaoMaio" v-model="this.form.fruits_may" true-value="yes" false-value="no">
                        <label class="form-check-label" for="especiesFrutificacaoMaio">{{ $t('label.maio') }}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-2 mt-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="especiesFrutificacaoJunho" v-model="this.form.fruits_jun" true-value="yes" false-value="no">
                        <label class="form-check-label" for="especiesFrutificacaoJunho">{{ $t('label.junho') }}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-2 mt-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="especiesFrutificacaoJulho" v-model="this.form.fruits_jul" true-value="yes" false-value="no">
                        <label class="form-check-label" for="especiesFrutificacaoJulho">{{ $t('label.julho') }}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-2 mt-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="especiesFrutificacaoAgosto" v-model="this.form.fruits_aug" true-value="yes" false-value="no">
                        <label class="form-check-label" for="especiesFrutificacaoAgosto">{{ $t('label.agosto') }}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-2 mt-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="especiesFrutificacaoSetembro" v-model="this.form.fruits_sep" true-value="yes" false-value="no">
                        <label class="form-check-label" for="especiesFrutificacaoSetembro">{{ $t('label.setembro') }}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-2 mt-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="especiesFrutificacaoOutubro" v-model="this.form.fruits_oct" true-value="yes" false-value="no">
                        <label class="form-check-label" for="especiesFrutificacaoOutubro">{{ $t('label.outubro') }}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-2 mt-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="especiesFrutificacaoNovembro" v-model="this.form.fruits_nov" true-value="yes" false-value="no">
                        <label class="form-check-label" for="especiesFrutificacaoNovembro">{{ $t('label.novembro') }}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-2 mt-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="especiesFrutificacaoDezembro" v-model="this.form.fruits_dec" true-value="yes" false-value="no">
                        <label class="form-check-label" for="especiesFrutificacaoDezembro">{{ $t('label.dezembro') }}</label>
                    </div>
                </div>
            </div>
          </div>
          <div :class="{ 'pending_analysis':  pendingAnalysis && pendingAnalysisFlowering }">
            <label class="form-label">{{ $t('label.floracao') }}</label>
            <button v-if="pendingAnalysis && pendingAnalysisFlowering" class="btn" type="button" @click="analysis($t('label.floracao'), 'flowering')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <div class="row box-frutificacao ms-1 p-1">
              <div class="col-sm-6 col-md-2 mt-3">
                  <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="especiesFrutificacaoJaneiro" v-model="this.form.flowering_jan" true-value="yes" false-value="no">
                      <label class="form-check-label" for="especiesFrutificacaoJaneiro">{{ $t('label.janeiro') }}</label>
                  </div>
              </div>
              <div class="col-sm-6 col-md-2 mt-3">
                  <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="especiesFrutificacaoFevereiro" v-model="this.form.flowering_feb" true-value="yes" false-value="no">
                      <label class="form-check-label" for="especiesFrutificacaoFevereiro">{{ $t('label.fevereiro') }}</label>
                  </div>
              </div>
              <div class="col-sm-6 col-md-2 mt-3">
                  <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="especiesFrutificacaoMarco" v-model="this.form.flowering_mar" true-value="yes" false-value="no">
                      <label class="form-check-label" for="especiesFrutificacaoMarco">{{ $t('label.marco') }}</label>
                  </div>
              </div>
              <div class="col-sm-6 col-md-2 mt-3">
                  <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="especiesFrutificacaoAbril" v-model="this.form.flowering_apr" true-value="yes" false-value="no">
                      <label class="form-check-label" for="especiesFrutificacaoAbril">{{ $t('label.abril') }}</label>
                  </div>
              </div>
              <div class="col-sm-6 col-md-2 mt-3">
                  <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="especiesFrutificacaoMaio" v-model="this.form.flowering_may" true-value="yes" false-value="no">
                      <label class="form-check-label" for="especiesFrutificacaoMaio">{{ $t('label.maio') }}</label>
                  </div>
              </div>
              <div class="col-sm-6 col-md-2 mt-3">
                  <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="especiesFrutificacaoJunho" v-model="this.form.flowering_jun" true-value="yes" false-value="no">
                      <label class="form-check-label" for="especiesFrutificacaoJunho">{{ $t('label.junho') }}</label>
                  </div>
              </div>
              <div class="col-sm-6 col-md-2 mt-3">
                  <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="especiesFrutificacaoJulho" v-model="this.form.flowering_jul" true-value="yes" false-value="no">
                      <label class="form-check-label" for="especiesFrutificacaoJulho">{{ $t('label.julho') }}</label>
                  </div>
              </div>
              <div class="col-sm-6 col-md-2 mt-3">
                  <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="especiesFrutificacaoAgosto" v-model="this.form.flowering_aug" true-value="yes" false-value="no">
                      <label class="form-check-label" for="especiesFrutificacaoAgosto">{{ $t('label.agosto') }}</label>
                  </div>
              </div>
              <div class="col-sm-6 col-md-2 mt-3">
                  <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="especiesFrutificacaoSetembro" v-model="this.form.flowering_sep" true-value="yes" false-value="no">
                      <label class="form-check-label" for="especiesFrutificacaoSetembro">{{ $t('label.setembro') }}</label>
                  </div>
              </div>
              <div class="col-sm-6 col-md-2 mt-3">
                  <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="especiesFrutificacaoOutubro" v-model="this.form.flowering_oct" true-value="yes" false-value="no">
                      <label class="form-check-label" for="especiesFrutificacaoOutubro">{{ $t('label.outubro') }}</label>
                  </div>
              </div>
              <div class="col-sm-6 col-md-2 mt-3">
                  <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="especiesFrutificacaoNovembro" v-model="this.form.flowering_nov" true-value="yes" false-value="no">
                      <label class="form-check-label" for="especiesFrutificacaoNovembro">{{ $t('label.novembro') }}</label>
                  </div>
              </div>
              <div class="col-sm-6 col-md-2 mt-3">
                  <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="especiesFrutificacaoDezembro" v-model="this.form.flowering_dec" true-value="yes" false-value="no">
                      <label class="form-check-label" for="especiesFrutificacaoDezembro">{{ $t('label.dezembro') }}</label>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-2" :class="{ 'pending_analysis':  pendingAnalysis && this.form.red_lists !== this.form.log_update.Red_lists }">
            <table id="datatable1" class="display dataTable no-footer" style="width: 100%;" role="grid" aria-describedby="datatable1_info">
              <thead>
                <tr role="row">
                  <th>
                    <button v-if="pendingAnalysis && this.form.red_lists !== this.form.log_update.Red_lists" class="btn" type="button" @click="analysis($t('label.listasvermelhas'), 'red_lists')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                      <i class="material-icons">search</i>
                    </button>
                    {{ $t('label.listasvermelhas') }}
                  </th>
                  <th style="display: flex;">{{ $t('label.acoes') }}
                    <ul class="list-group list-group-horizontal list-acoes ">
                      <li class="list-acoes-add">
                        <a @click="actionRedListsGrid = this.constants.actionNew"><i class="material-icons">add</i></a>
                      </li>
                    </ul>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="actionRedListsGrid !== this.constants.actionList">
                  <tr>
                    <td>
                      <div class="col-md-12">
                        <input type="text" class="form-control" v-model="this.redItem">
                      </div>
                    </td>
                    <td>
                      <div class="col-md-12">
                        <ul class="list-group list-group-horizontal list-acoes ">
                          <li class="">
                            <a @click="saveRedLists()"><i class="material-icons">done</i></a>
                          </li>
                          <li class="">
                            <a @click="cancelRedLists()"><i class="material-icons">cancel</i></a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-if="!this.red_lists || this.red_lists.length === 0"><td colspan="2" class="text-center">{{ $t('message.noRecords') }}</td></tr>
                  <tr v-else v-for="(i, index) in this.red_lists" :key="index" role="row" class="odd">
                    <td>{{i}}</td>
                    <td>
                      <ul class="list-group list-group-horizontal list-acoes ">
                        <li class="list-acoes-edit">
                          <a @click="actionRedItem(this.constants.actionEdit, i, index)"><i class="material-icons">edit</i></a>
                        </li>
                        <li class="list-acoes-delete">
                          <a @click="actionRedItem(this.constants.actionDelete, i)"><i class="material-icons">delete</i></a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-12 mt-5 d-flex justify-content-end ">
          <button type="button" @click="save" class="btn btn-primary me-3">{{pendingAnalysis ? $t('label.aprovar') : $t('label.salvar')}}</button>
          <button type="button" @click="cancel" class="btn btn-danger">{{ $t('label.cancelar') }}</button>
        </div>
      </div>
    </form>
    <!-- Modal -->
    <AppModalReview :form="form" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import AppPageTitle from '../../components/AppPageTitle.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import AppModalReview from '../../components/AppModalReview.vue'
export default {
  name: 'EspeciesFormView',
  components: { AppPageTitle, AppModalReview },
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      pageTitle: `${this.$t('label.especies')} > ${this.$t('label.adicionar')}`,
      action: this.constants.actionNew,
      actionRedListsGrid: this.constants.actionList,
      form: {
        species: {},
        log_update: {}
      },
      red_lists: [],
      redItem: '',
      redItemIndex: 0,
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      }
    }
  },
  validations () {
    return {
      form: {
        sp_code: { required },
        latin_name: { required }
      }
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.form.pending_analysis && this.$store.state.user.sysUser.user_level === 1
    },
    pendingAnalysisFruits: function () {
      return this.form.fruits_jan !== this.form.log_update.Fruits_jan ||
this.form.fruits_feb !== this.form.log_update.Fruits_feb ||
this.form.fruits_mar !== this.form.log_update.Fruits_mar ||
this.form.fruits_apr !== this.form.log_update.Fruits_apr ||
this.form.fruits_may !== this.form.log_update.Fruits_may ||
this.form.fruits_jun !== this.form.log_update.Fruits_jun ||
this.form.fruits_jul !== this.form.log_update.Fruits_jul ||
this.form.fruits_aug !== this.form.log_update.Fruits_aug ||
this.form.fruits_sep !== this.form.log_update.Fruits_sep ||
this.form.fruits_oct !== this.form.log_update.Fruits_oct ||
this.form.fruits_nov !== this.form.log_update.Fruits_nov ||
this.form.fruits_dec !== this.form.log_update.Fruits_dec
    },
    pendingAnalysisFlowering: function () {
      return this.form.flowering_jan !== this.form.log_update.Flowering_jan ||
this.form.flowering_feb !== this.form.log_update.Flowering_feb ||
this.form.flowering_mar !== this.form.log_update.Flowering_mar ||
this.form.flowering_apr !== this.form.log_update.Flowering_apr ||
this.form.flowering_may !== this.form.log_update.Flowering_may ||
this.form.flowering_jun !== this.form.log_update.Flowering_jun ||
this.form.flowering_jul !== this.form.log_update.Flowering_jul ||
this.form.flowering_aug !== this.form.log_update.Flowering_aug ||
this.form.flowering_sep !== this.form.log_update.Flowering_sep ||
this.form.flowering_oct !== this.form.log_update.Flowering_oct ||
this.form.flowering_nov !== this.form.log_update.Flowering_nov ||
this.form.flowering_dec !== this.form.log_update.Flowering_dec
    }
  },
  methods: {
    cancelRedLists: function () {
      this.redItem = ''
      this.actionRedListsGrid = this.constants.actionList
    },
    saveRedLists: function () {
      console.log(this.actionRedListsGrid)
      if (this.actionRedListsGrid === this.constants.actionEdit) {
        this.red_lists[this.redItemIndex] = this.redItem
      }
      if (this.actionRedListsGrid === this.constants.actionNew) {
        this.red_lists.push(this.redItem)
      }

      this.actionRedListsGrid = this.constants.actionList
      this.redItem = ''
    },
    actionRedItem: function (action, item, index) {
      if (action === this.constants.actionDelete) {
        if (confirm(this.$t('message.confirmDel'))) {
          this.red_lists = this.red_lists.filter(f => f !== item)
          this.actionRedListsGrid = this.constants.actionList
        }
      }
      if (action === this.constants.actionEdit) {
        this.redItem = item
        this.redItemIndex = index
        this.actionRedListsGrid = this.constants.actionEdit
      }
    },
    modalValuesSimple: function (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis: async function (label, input) {
      this.modalValues.label = label
      switch (input) {
        case 'sp_code':
          this.modalValuesSimple(this.form.sp_code, this.form.log_update.Sp_code, label)
          break
        case 'latin_name':
          this.modalValuesSimple(this.form.latin_name, this.form.log_update.Latin_name, label)
          break
        case 'common_name':
          this.modalValuesSimple(this.form.common_name, this.form.log_update.Common_name, label)
          break
        case 'family':
          this.modalValuesSimple(this.form.family, this.form.log_update.Family, label)
          break
        case 'habitat':
          this.modalValuesSimple(this.form.habitat, this.form.log_update.Habitat, label)
          break
        case 'red_lists':
          this.modalValuesSimple(this.form.red_lists, this.form.log_update.Red_lists, label)
          break
        case 'flowering':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          this.modalValues.oldValue = []
          if (this.form.flowering_jan !== this.form.log_update.Flowering_jan) {
            this.modalValues.newValue.push({ id: this.form.flowering_jan, value: `${this.$t('label.janeiro')}: ${this.form.flowering_jan}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Flowering_jan, value: `${this.$t('label.janeiro')}: ${this.form.log_update.Flowering_jan}` })
          }
          if (this.form.flowering_feb !== this.form.log_update.Flowering_feb) {
            this.modalValues.newValue.push({ id: this.form.flowering_feb, value: `${this.$t('label.fevereiro')}: ${this.form.flowering_feb}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Flowering_feb, value: `${this.$t('label.fevereiro')}: ${this.form.log_update.Flowering_feb}` })
          }
          if (this.form.flowering_mar !== this.form.log_update.Flowering_mar) {
            this.modalValues.newValue.push({ id: this.form.flowering_mar, value: `${this.$t('label.marco')}: ${this.form.flowering_mar}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Flowering_mar, value: `${this.$t('label.marco')}: ${this.form.log_update.Flowering_mar}` })
          }
          if (this.form.flowering_apr !== this.form.log_update.Flowering_apr) {
            this.modalValues.newValue.push({ id: this.form.flowering_apr, value: `${this.$t('label.abril')}: ${this.form.flowering_apr}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Flowering_apr, value: `${this.$t('label.abril')}: ${this.form.log_update.Flowering_apr}` })
          }
          if (this.form.flowering_may !== this.form.log_update.Flowering_may) {
            this.modalValues.newValue.push({ id: this.form.flowering_may, value: `${this.$t('label.maio')}: ${this.form.flowering_may}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Flowering_may, value: `${this.$t('label.maio')}: ${this.form.log_update.Flowering_may}` })
          }
          if (this.form.flowering_jun !== this.form.log_update.Flowering_jun) {
            this.modalValues.newValue.push({ id: this.form.flowering_jun, value: `${this.$t('label.junho')}: ${this.form.flowering_jun}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Flowering_jun, value: `${this.$t('label.junho')}: ${this.form.log_update.Flowering_jun}` })
          }
          if (this.form.flowering_jul !== this.form.log_update.Flowering_jul) {
            this.modalValues.newValue.push({ id: this.form.flowering_jul, value: `${this.$t('label.julho')}: ${this.form.flowering_jul}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Flowering_jul, value: `${this.$t('label.julho')}: ${this.form.log_update.Flowering_jul}` })
          }
          if (this.form.flowering_aug !== this.form.log_update.Flowering_aug) {
            this.modalValues.newValue.push({ id: this.form.flowering_aug, value: `${this.$t('label.agosto')}: ${this.form.flowering_aug}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Flowering_aug, value: `${this.$t('label.agosto')}: ${this.form.log_update.Flowering_aug}` })
          }
          if (this.form.flowering_sep !== this.form.log_update.Flowering_sep) {
            this.modalValues.newValue.push({ id: this.form.flowering_sep, value: `${this.$t('label.setembro')}: ${this.form.flowering_sep}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Flowering_sep, value: `${this.$t('label.setembro')}: ${this.form.log_update.Flowering_sep}` })
          }
          if (this.form.flowering_oct !== this.form.log_update.Flowering_oct) {
            this.modalValues.newValue.push({ id: this.form.flowering_oct, value: `${this.$t('label.outubro')}: ${this.form.flowering_oct}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Flowering_oct, value: `${this.$t('label.outubro')}: ${this.form.log_update.Flowering_oct}` })
          }
          if (this.form.flowering_nov !== this.form.log_update.Flowering_nov) {
            this.modalValues.newValue.push({ id: this.form.flowering_nov, value: `${this.$t('label.novembro')}: ${this.form.flowering_nov}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Flowering_nov, value: `${this.$t('label.novembro')}: ${this.form.log_update.Flowering_nov}` })
          }
          if (this.form.flowering_dec !== this.form.log_update.Flowering_dec) {
            this.modalValues.newValue.push({ id: this.form.flowering_dec, value: `${this.$t('label.dezembro')}: ${this.form.flowering_dec}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Flowering_dec, value: `${this.$t('label.dezembro')}: ${this.form.log_update.Flowering_dec}` })
          }
          break
        case 'fruits':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          this.modalValues.oldValue = []
          if (this.form.fruits_jan !== this.form.log_update.Fruits_jan) {
            this.modalValues.newValue.push({ id: this.form.fruits_jan, value: `${this.$t('label.janeiro')}: ${this.form.fruits_jan}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Fruits_jan, value: `${this.$t('label.janeiro')}: ${this.form.log_update.Fruits_jan}` })
          }
          if (this.form.fruits_feb !== this.form.log_update.Fruits_feb) {
            this.modalValues.newValue.push({ id: this.form.fruits_feb, value: `${this.$t('label.fevereiro')}: ${this.form.fruits_feb}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Fruits_feb, value: `${this.$t('label.fevereiro')}: ${this.form.log_update.Fruits_feb}` })
          }
          if (this.form.fruits_mar !== this.form.log_update.Fruits_mar) {
            this.modalValues.newValue.push({ id: this.form.fruits_mar, value: `${this.$t('label.marco')}: ${this.form.fruits_mar}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Fruits_mar, value: `${this.$t('label.marco')}: ${this.form.log_update.Fruits_mar}` })
          }
          if (this.form.fruits_apr !== this.form.log_update.Fruits_apr) {
            this.modalValues.newValue.push({ id: this.form.fruits_apr, value: `${this.$t('label.abril')}: ${this.form.fruits_apr}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Fruits_apr, value: `${this.$t('label.abril')}: ${this.form.log_update.Fruits_apr}` })
          }
          if (this.form.fruits_may !== this.form.log_update.Fruits_may) {
            this.modalValues.newValue.push({ id: this.form.fruits_may, value: `${this.$t('label.maio')}: ${this.form.fruits_may}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Fruits_may, value: `${this.$t('label.maio')}: ${this.form.log_update.Fruits_may}` })
          }
          if (this.form.fruits_jun !== this.form.log_update.Fruits_jun) {
            this.modalValues.newValue.push({ id: this.form.fruits_jun, value: `${this.$t('label.junho')}: ${this.form.fruits_jun}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Fruits_jun, value: `${this.$t('label.junho')}: ${this.form.log_update.Fruits_jun}` })
          }
          if (this.form.fruits_jul !== this.form.log_update.Fruits_jul) {
            this.modalValues.newValue.push({ id: this.form.fruits_jul, value: `${this.$t('label.julho')}: ${this.form.fruits_jul}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Fruits_jul, value: `${this.$t('label.julho')}: ${this.form.log_update.Fruits_jul}` })
          }
          if (this.form.fruits_aug !== this.form.log_update.Fruits_aug) {
            this.modalValues.newValue.push({ id: this.form.fruits_aug, value: `${this.$t('label.agosto')}: ${this.form.fruits_aug}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Fruits_aug, value: `${this.$t('label.agosto')}: ${this.form.log_update.Fruits_aug}` })
          }
          if (this.form.fruits_sep !== this.form.log_update.Fruits_sep) {
            this.modalValues.newValue.push({ id: this.form.fruits_sep, value: `${this.$t('label.setembro')}: ${this.form.fruits_sep}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Fruits_sep, value: `${this.$t('label.setembro')}: ${this.form.log_update.Fruits_sep}` })
          }
          if (this.form.fruits_oct !== this.form.log_update.Fruits_oct) {
            this.modalValues.newValue.push({ id: this.form.fruits_oct, value: `${this.$t('label.outubro')}: ${this.form.fruits_oct}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Fruits_oct, value: `${this.$t('label.outubro')}: ${this.form.log_update.Fruits_oct}` })
          }
          if (this.form.fruits_nov !== this.form.log_update.Fruits_nov) {
            this.modalValues.newValue.push({ id: this.form.fruits_nov, value: `${this.$t('label.novembro')}: ${this.form.fruits_nov}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Fruits_nov, value: `${this.$t('label.novembro')}: ${this.form.log_update.Fruits_nov}` })
          }
          if (this.form.fruits_dec !== this.form.log_update.Fruits_dec) {
            this.modalValues.newValue.push({ id: this.form.fruits_dec, value: `${this.$t('label.dezembro')}: ${this.form.fruits_dec}` })
            this.modalValues.oldValue.push({ id: this.form.log_update.Fruits_dec, value: `${this.$t('label.dezembro')}: ${this.form.log_update.Fruits_dec}` })
          }
          break
      }
    },
    getById: async function (id) {
      this.$store.dispatch('showPreload')
      await axios.get(`/Species/${id}`)
        .then(response => {
          this.form = response.data
          if (this.form.red_lists) {
            this.red_lists = this.form.red_lists.split(', ')
          }
          if (this.form.log_update) {
            this.form.log_update = JSON.parse(this.form.log_update)
          } else {
            this.form.log_update = {}
          }
        })
    },
    save: function () {
      this.v$.$touch()
      if (this.v$.form.$invalid) {
        return
      }
      this.form.log_update = JSON.stringify(this.form.log_update)
      let modelRequest = {}
      modelRequest = { ...this.form }
      modelRequest.red_lists = this.red_lists.join(', ')
      if (this.action === this.constants.actionEdit) {
        axios.put('/Species', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.form.log_update = JSON.parse(this.form.log_update)
            this.cancel()
          })
      } else {
        axios.post('/Species', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.cancel()
          })
      }
    },
    cancel: function () {
      this.$router.replace('/especies')
    }
  },
  created: async function () {
    this.action =
      this.$route.params.id !== this.constants.actionNew
        ? this.constants.actionEdit
        : this.constants.actionNew

    if (this.action === this.constants.actionEdit) {
      this.pageTitle = `${this.$t('label.especies')} > ${this.$t('label.editar')}`
      await this.getById(this.$route.params.id)
    }
  }
}
</script>
