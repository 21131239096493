<template>
  <div class="container">
    <div class="row">
      <div>
        <div class="page-description">
          <h1>{{$t('label.exportarcoordmudas')}}</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-md-12 flex-row align-self-center">
        <!-- busca lista -->
        <form class="fitro-lista" @submit.prevent="exportar">
          <div class="row pb-0">
            <div class="col-12 text-right">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="pendenteAnalise" v-model="$store.state.formSearch.pending_analysis">
                <label class="form-check-label" for="pendenteAnalise">{{ $t('label.pendenteAnalise') }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="from_app" v-model="$store.state.formSearch.from_app">
                <label class="form-check-label" for="from_app">{{ $t('label.cadastradoviaapp') }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.codigodamatriz')" v-model="$store.state.formSearch.code" maxlength="3">
            </div>
            <div class="col-12 col-lg-4 mt-1">
              <v-select :appendToBody="true" :filterable="false" @search="getSpeciesCombo" :options="speciesList" v-model="$store.state.formSearch.id_species" label="text" index="id"
                :reduce="r => r.id" :clearable="false" class="bg-laranja-select vue-select">
                <template v-slot:selected-option="option">
                  {{ `${option.text}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.text}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.nomedapropriedade')" v-model="$store.state.formSearch.property">
            </div>
            <div class="col-md-12  d-flex justify-content-end mt-1 align-items-end">
              <button type="button" @click="clear" class="btn btn-outline-secondary ">
                {{ $t('label.limpar') }}
              </button>
              <button type="submit" class="btn btn-primary ms-2">{{ $t('label.exportar') }}</button>
            </div>
          </div>
        </form>
        <!-- /busca lista -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import * as XLSX from 'xlsx'
export default {
  name: 'ExportarCoordMudas',
  data () {
    return {
      header: [
        'code',
        'latin_name',
        'property',
        'lat',
        'long',
        'date_planted',
        'common_name'
      ],
      speciesList: [{ id_species: null, text: this.$t('label.nomecientificonomepopular') }]
    }
  },
  methods: {
    clear: function () {
      this.$store.commit('clearFormSearch')
    },
    exportar: function () {
      this.$store.dispatch('showPreload')
      const params = this.$store.state.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      axios.get('/ExportData/exportarcoordmudas', { params })
        .then(response => {
          const dados = XLSX.utils.json_to_sheet(response.data, {
            header: this.header
          })
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, dados, 'Sheet')
          XLSX.writeFile(wb, `coordmudas-${Date.now()}.xlsx`)
        })
    },
    getSpeciesCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.latin_name = search
        params.id_species = this.$store.state.formSearch.id_species
        loading(true)
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
            loading(false)
          })
      } else if (!search) {
        params.id_species = this.$store.state.formSearch.id_species
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
          })
      }
    }
  },
  created () {
    this.getSpeciesCombo()
  }
}
</script>
