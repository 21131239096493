<template>
  <div class="container">
    <div class="row">
      <div>
        <div class="page-description">
          <h1>{{$t('label.exportarespecies')}}</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-md-12 flex-row align-self-center">
        <!-- busca lista -->
        <form class="fitro-lista" @submit.prevent="exportar">
          <div class="row pb-0">
            <div class="col-12 text-right">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="pendenteAnalise" v-model="$store.state.formSearch.pending_analysis">
                <label class="form-check-label" for="pendenteAnalise">{{ $t('label.pendenteAnalise') }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.codigodaespecie')" v-model="$store.state.formSearch.sp_code" maxlength="3">
            </div>
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.nomecientifico')" v-model="$store.state.formSearch.latin_name">
            </div>
            <div class="col-12 col-lg-4 mt-1">
              <input type="text" class="form-control" :placeholder="$t('label.familia')" v-model="$store.state.formSearch.family">
            </div>
            <div class="col-12 col-lg-4 mt-1">
              <v-select :appendToBody="true" :filterable="true" :options="frutificacaoList" v-model="$store.state.formSearch.frutificacao" label="text" index="id"
                :reduce="r => r.id" :clearable="false" class="bg-laranja-select vue-select">
                <template v-slot:selected-option="option">
                  {{ `${option.text}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.text}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="col-12 col-lg-4 mt-1">
              <v-select :appendToBody="true" :filterable="true" :options="floracaoList" v-model="$store.state.formSearch.floracao" label="text" index="id"
                :reduce="r => r.id" :clearable="false" class="bg-laranja-select vue-select">
                <template v-slot:selected-option="option">
                  {{ `${option.text}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.text}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="col-md-12  d-flex justify-content-end mt-1 align-items-end">
              <button type="button" @click="clear" class="btn btn-outline-secondary ">
                {{ $t('label.limpar') }}
              </button>
              <button type="submit" class="btn btn-primary ms-2">{{ $t('label.exportar') }}</button>
            </div>
          </div>
        </form>
        <!-- /busca lista -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import * as XLSX from 'xlsx'
export default {
  name: 'ExportarEspecies',
  data () {
    return {
      header: [
        'latin_name',
        'common_name',
        'family',
        'Habitat',
        'Red_lists'
      ],
      frutificacaoList: [
        { id: null, text: this.$t('label.frutificacao') },
        { id: 1, text: this.$t('label.jan') },
        { id: 2, text: this.$t('label.feb') },
        { id: 3, text: this.$t('label.mar') },
        { id: 4, text: this.$t('label.apr') },
        { id: 5, text: this.$t('label.may') },
        { id: 6, text: this.$t('label.jun') },
        { id: 7, text: this.$t('label.jul') },
        { id: 8, text: this.$t('label.aug') },
        { id: 9, text: this.$t('label.sep') },
        { id: 10, text: this.$t('label.oct') },
        { id: 11, text: this.$t('label.nov') },
        { id: 12, text: this.$t('label.dec') }
      ],
      floracaoList: [
        { id: null, text: this.$t('label.floracao') },
        { id: 1, text: this.$t('label.jan') },
        { id: 2, text: this.$t('label.feb') },
        { id: 3, text: this.$t('label.mar') },
        { id: 4, text: this.$t('label.apr') },
        { id: 5, text: this.$t('label.may') },
        { id: 6, text: this.$t('label.jun') },
        { id: 7, text: this.$t('label.jul') },
        { id: 8, text: this.$t('label.aug') },
        { id: 9, text: this.$t('label.sep') },
        { id: 10, text: this.$t('label.oct') },
        { id: 11, text: this.$t('label.nov') },
        { id: 12, text: this.$t('label.dec') }
      ]
    }
  },
  methods: {
    clear: function () {
      this.$store.commit('clearFormSearch')
    },
    exportar: function () {
      this.$store.dispatch('showPreload')
      const params = this.$store.state.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      switch (this.$store.state.formSearch.frutificacao) {
        case 1:
          params.fruits_jan = 'yes'
          break
        case 2:
          params.fruits_feb = 'yes'
          break
        case 3:
          params.fruits_mar = 'yes'
          break
        case 4:
          params.fruits_apr = 'yes'
          break
        case 5:
          params.fruits_may = 'yes'
          break
        case 6:
          params.fruits_jun = 'yes'
          break
        case 7:
          params.fruits_jul = 'yes'
          break
        case 8:
          params.fruits_aug = 'yes'
          break
        case 9:
          params.fruits_sep = 'yes'
          break
        case 10:
          params.fruits_oct = 'yes'
          break
        case 11:
          params.fruits_nov = 'yes'
          break
        case 12:
          params.fruits_dec = 'yes'
          break
      }
      switch (this.$store.state.formSearch.floracao) {
        case 1:
          params.flowering_jan = 'yes'
          break
        case 2:
          params.flowering_feb = 'yes'
          break
        case 3:
          params.flowering_mar = 'yes'
          break
        case 4:
          params.flowering_apr = 'yes'
          break
        case 5:
          params.flowering_may = 'yes'
          break
        case 6:
          params.flowering_jun = 'yes'
          break
        case 7:
          params.flowering_jul = 'yes'
          break
        case 8:
          params.flowering_aug = 'yes'
          break
        case 9:
          params.flowering_sep = 'yes'
          break
        case 10:
          params.flowering_oct = 'yes'
          break
        case 11:
          params.flowering_nov = 'yes'
          break
        case 12:
          params.flowering_dec = 'yes'
          break
      }
      axios.get('/ExportData/exportarespecies', { params })
        .then(response => {
          const dados = XLSX.utils.json_to_sheet(response.data, {
            header: this.header
          })
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, dados, 'Sheet')
          XLSX.writeFile(wb, `especies-${Date.now()}.xlsx`)
        })
    }
  },
  created () {
    if (this.$store.state.formSearch.frutificacao === undefined) {
      this.$store.state.formSearch.frutificacao = null
    }
    if (this.$store.state.formSearch.floracao === undefined) {
      this.$store.state.formSearch.floracao = null
    }
  }
}
</script>
