import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toaster from '@meforma/vue-toaster'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import VueApexCharts from 'vue3-apexcharts'
import i18n from './util/i18n'
import './util/axios'
import VueProgressBar from '@aacassandra/vue3-progressbar'
import { Vue3Mq } from 'vue3-mq'
import consts from './util/constants'
import filters from './util/filters'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueTheMask from 'vue-the-mask'
import Vue3Geolocation from 'vue3-geolocation'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

const optionsProgressBar = {
  color: '#007bff ',
  failedColor: '#dc3545 ',
  thickness: '3px ',
  autoFinish: false
}

const globalOptions = {
  autoDefaultCountry: false,
  defaultCountry: 'BR',
  mode: 'international'
}

const app = createApp(App)
  .use(store)
  .use(VueProgressBar, optionsProgressBar)
  .use(router)
  .use(Toaster, {
    position: 'top-right',
    duration: 10000
  })
  .use(PerfectScrollbar)
  .use(VueApexCharts)
  .use(i18n)
  .use(Vue3Mq)
  .component('v-select', vSelect)
  .use(VueTheMask)
  .use(Vue3Geolocation)
  .use(VueTelInput, globalOptions)

app.config.globalProperties.constants = consts

app.config.globalProperties.$filters = filters

export default app.mount('#app')
