<template>
  <div class="row">
    <div class="col-md-6">
      <div class="page-description">
        <h1>{{description}}</h1>
      </div>
    </div>
    <div class="col-md-6 d-flex justify-content-end align-items-center">
      <router-link class="btn btn-primary ms-5" :to="`${this.$route.path}/${this.constants.actionNew}`">{{buttonText}}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPageDescription',
  props: {
    description: String,
    buttonText: String
  }
}
</script>
