import { createI18n } from 'vue-i18n'
import lang from '@/language/lang'

const messages = lang

const i18n = createI18n({
  locale: 'pt',
  messages
})

export default i18n
