<template>
  <div class="row">
    <div class="col-sm-12 col-md-5">
      <div class="dataTables_info" id="datatable1_info" role="status" aria-live="polite">{{ $t('label.exibindo1a') }} {{countPages}} {{ $t('label.de') }} {{count}} {{ $t('label.registros') }}</div>
    </div>
    <div class="col-sm-12 col-md-7">
      <div class="dataTables_paginate paging_simple_numbers" id="datatable1_paginate">
        <ul class="pagination">
          <li class="paginate_button page-item previous " id="datatable1_previous" :class="{ 'disabled': $store.state.currentPage === 1}">
            <a @click="nextPage(-1)" data-dt-idx="0" class="page-link">{{ $t('label.anterior') }}</a>
          </li>
          <li class="paginate_button page-item" v-if="$store.state.currentPage > countNumbers && countNumbers > 2">
            <a class="page-link" @click="nextPage(-countNumbers)">...</a>
          </li>
          <li class="paginate_button page-item" v-for="index in listPagination" :key="index" :class="{ 'active': index === $store.state.currentPage}">
            <a class="page-link" @click="setPage(index, true)">{{index}}</a>
          </li>
          <li class="paginate_button page-item" v-if="countPages > countNumbers && $store.state.currentPage < countPages && countNumbers > 2">
            <a class="page-link" @click="nextPage(countNumbers)">...</a>
          </li>
          <li class="paginate_button page-item next" id="datatable1_next" :class="{ 'disabled': $store.state.currentPage === countPages}">
            <a @click="nextPage(1)" data-dt-idx="5" class="page-link">{{ $t('label.proximo') }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTablePagination',
  inject: ['mq'],
  props: {
    count: Number
  },
  data: function () {
    return {
      countNumbers: 10,
      listPagination: [],
      countPages: 0
    }
  },
  watch: {
    '$store.state.countList' () {
      this.preparePagination()
    },
    '$store.state.perPage' () {
      this.preparePagination()
    }
  },
  methods: {
    preparePagination: function () {
      if (this.mq.current === 'xs') {
        this.countNumbers = 2
      } else if (this.mq.current === 'sm') {
        this.countNumbers = 5
      } else {
        this.countNumbers = 10
      }
      this.countPages = this.$store.state.countList / this.$store.state.perPage
      if (!Number.isInteger(this.countPages)) {
        this.countPages = Math.trunc(this.countPages + 1)
      }

      if (this.$store.state.currentPage > this.countNumbers) {
        this.listPagination = [...Array(this.countPages + 1)].map((_, i) => i).filter(i => i > this.$store.state.currentPage - this.countNumbers && i <= this.$store.state.currentPage)
        const last = this.listPagination[this.listPagination.length - 1]
        if (this.$store.state.currentPage > last) {
          this.setPage(last, true)
        }
      } else {
        this.listPagination = [...Array(this.countPages + 1)].map((_, i) => i).filter(i => i > 0 && i <= this.countNumbers)
        if (this.$store.state.currentPage > this.countPages) {
          this.setPage(this.countPages, false)
        }
      }
    },
    setPage: function (number, search) {
      if (number > 0) {
        this.$store.state.currentPage = number
        if (search) {
          this.$emit('searchTable')
        }
        this.preparePagination()
      }
    },
    nextPage: function (number) {
      this.setPage(this.$store.state.currentPage + number, true)
    }
  },
  created: function () {
    this.preparePagination()
  }
}
</script>
