<template>
  <div class="row">
    <div class="col">
      <div class="page-description" style="display:flex">
          <h1>{{title}}</h1><em class="title-pendente-analise" v-if="pendingAnalysis"> ({{ $t('label.pendenteAnalise') }})</em>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPageTitle',
  props: {
    title: String,
    pendingAnalysis: Boolean
  }
}
</script>
