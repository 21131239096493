<template>
  <div class="modal fade" id="modalAnalysis" refs="modalAnalysis" tabindex="-1" aria-labelledby="modalAnalysis" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg pending_analysis">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Review - {{modalValues.label}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body d-flex box_scroll_y">
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <h5 class="card-title">{{ $t('label.versaoAnterior') }}</h5>
              <div class="pt-2 pb-2 pe-1 ps-2" style="border: 1px solid #BDBDBD;border-radius: 8px;">
                <ul v-if="modalValues.isList">
                  <li v-for="item in modalValues.oldValue" :key="item.id">{{item.value}}</li>
                </ul>
                <p v-else class="card-text">{{modalValues.oldValue}}</p>
              </div>
              <h5 class="card-title">{{ $t('label.usuario') }}: {{log_user_update}}</h5>
            </div>
            <div class="col-sm-6 col-md-6">
              <h5 class="card-title">{{ $t('label.versaoAtual') }}</h5>
              <div class="pt-2 pb-2 pe-1 ps-2" style="border: 1px solid #BDBDBD;border-radius: 8px;">
                <ul v-if="modalValues.isList">
                  <li v-for="item in modalValues.newValue" :key="item.id">{{item.value}}</li>
                </ul>
                <p v-else class="card-text">{{modalValues.newValue}}</p>
              </div>
              <h5 class="card-title">{{ $t('label.usuario') }}: {{user_update}}</h5>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('label.fechar') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AppModalReview',
  props: {
    form: {},
    modalValues: {}
  },
  data: function () {
    return {
      user_update: '',
      log_user_update: ''
    }
  },
  watch: {
    'form.id_user_update' (newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.getUserUpdateById(newValue)
      }
    },
    'form.log_update.Id_user_update' (newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.getUserLogUpdateById(newValue)
      }
    }
  },
  methods: {
    getUserUpdateById: async function (id) {
      axios.get(`/user/${id}`)
        .then(response => {
          this.user_update = response.data.name
        })
    },
    getUserLogUpdateById: async function (id) {
      axios.get(`/user/${id}`)
        .then(response => {
          this.log_user_update = response.data.name
        })
    }
  }
}
</script>
<style scoped>

.pending_analysis .modal-content {
    position: relative!important;
    display: flex!important;
    flex-direction: column!important;
    width: 100%!important;
    pointer-events: auto!important;
    background-color: #fff!important;
    background-clip: padding-box!important;
    border: 0 solid rgba(0,0,0,.2)!important;
    border-radius: 0.3rem!important;
    outline: 0!important;
}
.pending_analysis .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
.pending_analysis .modal-title {
    font-size: 16px;
    color: #016237;
    margin: 0 auto;
    font-weight: 600;
    line-height: 1.5;
    font-size: 1.375rem;
}
.pending_analysis .modal-header .btn-close {
    margin: -0.5rem -0.5rem -0.5rem 0;
}
.pending_analysis .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}
.pending_analysis .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}
.pending_analysis .row {
    --bs-gutter-x: 24px;
    --bs-gutter-y: 0;
    display: flex;
    flex: 1 0 100%;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)*-1);
    margin-right: calc(var(--bs-gutter-x)/-2);
    margin-left: calc(var(--bs-gutter-x)/-2);
}
.pending_analysis .card-title {
    font-weight: 400;
    color: #016237;
    margin-bottom: 0.5rem;
}
.pending_analysis .modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}
</style>
