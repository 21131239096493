import moment from 'moment'
export default {
  dateIsValid (value) {
    if (value) {
      const date = moment({ y: value.split('/')[2], M: value.split('/')[1] - 1, d: value.split('/')[0] })
      return date.isValid()
    }
  },
  formatDateToBackend (value) {
    if (value) {
      return moment({ y: value.split('/')[2], M: value.split('/')[1] - 1, d: value.split('/')[0] }).format('YYYY-MM-DD')
    }
  },
  formatDate (value) {
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY')
    }
  }
}
