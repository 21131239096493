import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    user: {},
    logged: null,
    preload: false,
    isMenuCollapsed: false,
    currentPage: 1,
    perPage: 10,
    countList: 0,
    beforeRouter: '',
    formSearch: {},
    dataOrderBy: {}
  },
  getters: {
  },
  mutations: {
    clearFormSearch: (state) => {
      state.formSearch = {}
      state.dataOrderBy = {}
    },
    setUser (state, user) {
      state.user = user
      let hasUser = false
      if (user) {
        hasUser = true
      }
      // state.preload = hasUser
      state.logged = hasUser
    },
    login: state => {
      state.logged = true
    },
    logout: state => {
      state.logged = false
      state.user = {}
    },
    preload (store, value) {
      store.preload = value
    },
    onMenuCollapse (state, isMenuCollapsed) {
      if (isMenuCollapsed === undefined) {
        state.isMenuCollapsed = !state.isMenuCollapsed
      } else {
        state.isMenuCollapsed = isMenuCollapsed
      }
    }
  },
  actions: {
    showPreload (dispatch) {
      dispatch.commit('preload', true)
    },
    hidePreload (dispatch) {
      dispatch.commit('preload', false)
    }
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
