<template>
  <div class="container">
    <AppPageTitle :title="pageTitle"/>
    <form class="">
      <div class="row pb-5">
        <div class="col-md-6">
          <label class="form-label">{{ $t('label.email') }}</label>
          <input type="email" class="form-control" v-model="this.form.email" :class="{ 'is-invalid': v$.form.email.$dirty && v$.form.email.$error }">
          <div class="invalid-feedback">
            {{ $t('message.requiredField') }}
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">{{ $t('label.nome') }}</label>
          <input type="text" class="form-control" v-model="this.form.name" :class="{ 'is-invalid': v$.form.name.$dirty && v$.form.name.$error }">
          <div class="invalid-feedback">
            {{ $t('message.requiredField') }}
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">{{ $t('label.senha') }}</label>
          <input type="password" class="form-control" v-model="this.form.password" :class="{ 'is-invalid': v$.form.password.$dirty && v$.form.password.$error }">
          <div class="invalid-feedback">
            {{ $t('message.requiredField') }}
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">{{ $t('label.telefone') }}</label>
          <vue-tel-input class="tel-input" v-model="this.form.phone" :class="{ 'is-invalid': v$.form.phone.$dirty && v$.form.phone.$error }"></vue-tel-input>
          <div class="invalid-feedback">
            {{ $t('message.requiredField') }}
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">{{ $t('label.empresa') }}</label>
          <input type="text" class="form-control" v-model="this.form.company">
        </div>
        <div class="col-md-6">
          <label for="usuarios-ativo" class="form-label">{{ $t('label.ativo') }}</label>
          <div class="mt-2">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" :value=true v-model="form.active">
                <label class="form-check-label" >{{ $t('label.sim') }}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" :value=false v-model="form.active">
                <label class="form-check-label" >{{ $t('label.nao') }}</label>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label for="usuarios-perfil" class="form-label">{{ $t('label.perfil') }}</label>
          <div class="mt-2">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" :value="1" v-model="form.user_level">
                <label class="form-check-label" >{{ $t('label.administrador') }}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" :value="2" v-model="form.user_level">
                <label class="form-check-label" >{{ $t('label.basico') }}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" :value="3" v-model="form.user_level">
                <label class="form-check-label" >{{ $t('label.app') }}</label>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <label for="usuarios-obs" class="form-label">{{ $t('label.observacao') }}</label>
          <div class="mt-2">
            <textarea class="form-control" v-model="this.form.obs" rows="3"></textarea>
          </div>
        </div>
        <div class="col-md-12 mt-5 d-flex justify-content-end ">
          <button type="button" @click="save" class="btn btn-primary me-3">{{$t('label.salvar')}}</button>
          <button type="button" @click="cancel" class="btn btn-danger">{{ $t('label.cancelar') }}</button>
        </div>
      </div>
    </form>
    <!-- Modal -->
  </div>
</template>

<script>
import axios from 'axios'
import AppPageTitle from '../../components/AppPageTitle.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'UsuariosFormView',
  components: { AppPageTitle },
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      pageTitle: `${this.$t('label.usuarios')} > ${this.$t('label.adicionar')}`,
      action: this.constants.actionNew,
      form: {
        active: true,
        user_level: 2,
        phone: ''
      }
    }
  },
  validations () {
    return {
      form: {
        name: { required },
        password: {
          validPass (value) {
            if (this.action === this.constants.actionNew && !value) {
              return false
            }
            return true
          }
        },
        email: {
          validEmail (value) {
            if (this.form.user_level !== 3 && !value) {
              return false
            }
            return true
          }
        },
        phone: { required }
      }
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.form.pending_analysis && this.$store.state.user.sysUser.user_level === 1
    }
  },
  methods: {
    getById: async function (id) {
      this.$store.dispatch('showPreload')
      await axios.get(`/user/${id}`)
        .then(response => {
          this.form = response.data
        })
    },
    save: function () {
      this.v$.$touch()
      if (this.v$.form.$invalid) {
        return
      }
      let modelRequest = {}
      modelRequest = { ...this.form }
      if (this.action === this.constants.actionEdit) {
        axios.put('/user', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.cancel()
          })
      } else {
        axios.post('/user', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.cancel()
          })
      }
    },
    cancel: function () {
      this.$router.replace('/usuarios')
    }
  },
  created: async function () {
    this.action =
      this.$route.params.id !== this.constants.actionNew
        ? this.constants.actionEdit
        : this.constants.actionNew

    if (this.action === this.constants.actionEdit) {
      this.pageTitle = `${this.$t('label.usuarios')} > ${this.$t('label.editar')}`
      await this.getById(this.$route.params.id)
    }
  }
}
</script>
<style>
.vue-tel-input {
  background-color: #fff;
  border-color: #016237;
  color: #778E1E;
  height: 43px;
}
</style>
