import axios from 'axios'
import store from '../store'
import router from '../router'
import toaster from './toaster'
import app from '../main'

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT || 'https://localhost:7067/api'

const success = res => {
  store.dispatch('hidePreload')
  app.$Progress.finish()
  return res
}
const error = err => {
  store.dispatch('hidePreload')
  app.$Progress.fail()
  if (!err.response || err.response.status === 500) {
    console.log('Unavailable service')
  } else {
    if (err.response.status === 401 || err.response.status === 403) {
      toaster.error('Unauthorized')
      if (router.currentRoute.path !== '/login') {
        store.commit('setUser', null)
        window.location = '/login'
      }
    } else {
      console.log(err)
      if (err.response && err.response.data && err.response.data.title) {
        toaster.error(err.response.data.title)
      } else if (err.response && err.response.data) {
        toaster.error(err.response.data)
      } else {
        toaster.error(err.message)
      }
      return Promise.reject(err)
    }
  }
}

axios.interceptors.response.use(success, error)

axios.interceptors.request.use(async (config) => {
  app.$Progress.start()
  config.headers['Content-Type'] = 'application/json'
  if (store.state.user && store.state.user.token) {
    config.headers.Authorization = `Bearer ${store.state.user.token}`
    if (config.method === 'get') {
      config.headers['Content-Language'] = app.$i18n.locale
    }
  }
  return config
})
