export const en = {
  message: {
    requiredField: 'Field required',
    noRecords: 'No record found!',
    confirmDel: 'Are you sure you want to delete this record?',
    confirmApproval: 'Are you sure you want to approve this record?',
    noResultsFor: 'No results for',
    invalidDate: 'Invalid date'
  },
  label: {
    porfavorfacaloginparaacessaroadmin: 'Please login to access admin',
    email: 'E-mail',
    acessar: 'Access',
    senha: 'Password',
    usuario: 'User',
    ola: 'Hello!',
    logoff: 'Log off',
    pendenteAnalise: 'Analysis pending',
    versaoAnterior: 'Former version',
    versaoAtual: 'Current version',
    fechar: 'Close',
    tabelas: 'Tables',
    home: 'Home',
    coletadefrutosesementes: 'Fruit and seed collection',
    dadosexpedicaomudas: 'Seedling expedition',
    controlegerminacaosementes: 'Seedling germination',
    matrizes: 'Mother trees',
    matriz: 'Mother tree',
    municipios: 'Municipalities',
    rededeplantadorxs: 'Planters network',
    coordmudasplantadas: 'Seedling coordinates',
    coordenadasmudasapp: 'Seedling coordinates app',
    especies: 'Species',
    mudasplantadas: 'Seedlings planted',
    ferramentas: 'Tools',
    relatorios: 'Reports',
    exportar: 'Export',
    usuarios: 'Users',
    exibindo1a: 'Showing 1 to',
    de: 'of',
    registros: 'records',
    anterior: 'Former',
    proximo: 'Next',
    idcoleta: 'Collection id',
    codigodacoleta: 'Collection code',
    nomecientifico: 'Latin name',
    nomecientificonomepopular: 'Latin name / Common name',
    datadecoleta: 'Collection date',
    pesodassementes: 'Seed weight',
    numerodefrutos: 'Number of fruits',
    numerodesementes: 'Number of seeds',
    dtsemeaduraemtubetes: 'Date tube sowing',
    numerotubetessemeados: 'Number tube sowing',
    dtsemeaduraemsementeira: 'Date tray sowing',
    dtiniciodarustificacao: 'Date rustification start',
    codigodamatriz: 'Mother tree code',
    aprovar: 'Approve',
    salvar: 'Save',
    cancelar: 'Cancel',
    adicionar: 'Add',
    editar: 'Edit',
    nomepopular: 'Common name',
    adicionarcoleta: 'Add collection',
    limpar: 'Clear',
    filtrar: 'Filter',
    acoes: 'Actions',
    codigoexpedicao: 'Expedition code',
    dataexpedicao: 'Expedition date',
    numerodemudas: 'Number of seedlings',
    codigodagerminacao: 'Germination code',
    municipio: 'Municipality',
    nomedapropriedade: 'Property name',
    estado: 'State',
    proprietarix: 'Property owner',
    codigodamuda: 'Seedling code',
    coordenadasdemudasplantadas: 'Planted seedling coordinates',
    codigodaespecie: 'Species code',
    familia: 'Family',
    quantidade: 'Quantity',
    nome: 'Name',
    telefone: 'Telephone number',
    codigodaexpedicao: 'Expedition code',
    datadaexpedicao: 'Expedition date',
    tabelaexpedicoes: 'Expedition table',
    datadegerminacaoemtubetes: 'Tube germination date',
    numerodeplantulasemtubetes: 'Number of seedlings in tubes',
    datadegerminacaonasementeira: 'Tray germination date',
    numerodeplantulasnasementeira: 'Number of seedlings in tray',
    tabelagerminacao: 'Germination table',
    observacao: 'Observation',
    latitude: 'Latitude',
    longitude: 'Longitude',
    data: 'Date',
    localizacao: 'Location',
    propriedade: 'Property name',
    proprietario: 'Property owner',
    contato: 'Contact',
    website: 'Website',
    midiasocial: 'Social media',
    frutificacao: 'Fruiting',
    floracao: 'Flowering',
    janeiro: 'January',
    fevereiro: 'February',
    marco: 'Marçh',
    abril: 'April',
    maio: 'May',
    junho: 'June',
    julho: 'July',
    agosto: 'August',
    setembro: 'September',
    outubro: 'October',
    novembro: 'November',
    dezembro: 'December',
    habitat: 'Habitat',
    listasvermelhas: 'Red lists',
    ativo: 'Active',
    empresa: 'Organization / company',
    perfil: 'Profile',
    administrador: 'Administrator',
    basico: 'Basic',
    sim: 'Yes',
    nao: 'No',
    datadoplantio: 'Planting date',
    usuariocriacao: 'Creation user',
    datacriacao: 'Creation date',
    cadastradoviaapp: 'Registered through app',
    codigocorrdenadademudas: 'Seedling coordinate code',
    imagens: 'Images',
    jan: 'January',
    feb: 'February',
    sea: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    Jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December',
    exportarmatrizes: 'Export mother trees',
    exportarespecies: 'Export species',
    exportarplantadores: 'Export planters',
    exportarcoordmudas: 'Export seedling coordinates',
    exportarmudasplantadas: 'Export seedlings planted',
    exportarexpedicao: 'Export seedling expedition',
    exportargerminacao: 'Export germination',
    exportarcoletas: 'Export collections',
    numero: 'Number',
    dematrizes: 'of mother trees',
    mudas: 'Seedlings',
    plantadas: 'planted',
    deespecies: 'of species',
    sementes: 'Seeds',
    coletadas: 'collected',
    frutas: 'Fruits',
    rededeplantadores: 'Planters network',
    propriedades: 'Properties',
    sementescoletadas: 'Seeds collected',
    frutoscoletadas: 'Fruits collected',
    app: 'APP',
    whatsAppGroup: 'WhatsApp group',
    id: 'ID',
    mudasdistribuidas: 'Distributed seedlings',
    redede: 'Planters',
    plantadores: 'network',
    mudasdistribuidas1: 'Distributed',
    mudasdistribuidas2: 'seedlings',
    ultimaexpedicao: 'Last expedition',
    hectares: 'Acre',
    registradas: 'Registered',
    peloapp: 'By APP',
    registrosapp: 'Registered by APP'
  }
}
