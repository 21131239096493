<template>
  <div class="row">
    <div class="col-12 pb-4 pt-1">
      <div id="datatable1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="row">
          <div class="col-sm-12 col-md-6"></div>
          <div class="col-sm-12 col-md-6"></div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table id="datatable1" class="display dataTable no-footer" style="width: 100%;" role="grid" aria-describedby="datatable1_info">
              <thead>
                  <tr role="row">
                    <th :class="OrderByCode" @click="orderBy(1)">{{ $t('label.id') }}</th>
                    <th :class="OrderByLatin_name" @click="orderBy(2)">{{ $t('label.nomecientifico') }}</th>
                    <th :class="OrderByCode_mother" @click="orderBy(3)">{{ $t('label.codigodamatriz') }}</th>
                    <th class="align-items-end">{{ $t('label.acoes') }}</th>
                  </tr>
              </thead>
              <tbody>
                <tr v-if="$store.state.countList === 0"><td colspan="4" class="text-center">{{ $t('message.noRecords') }}</td></tr>
                <tr v-else v-for="item in items" :key="item.id_germin" role="row" class="odd">
                  <td>{{item.id_germin}}</td>
                  <td>{{item.latin_name}}</td>
                  <td>{{item.code_mother}}</td>
                  <td>
                    <ul class="list-group list-group-horizontal list-acoes ">
                      <li class="list-acoes-notvalidate " :class="{ 'validate': item.pending_analysis }">
                        <a @click="action(this.constants.actionEdit, item)"><i class="material-icons">check_circle</i></a>
                      </li>
                      <li class="list-acoes-edit">
                        <a @click="action(this.constants.actionEdit, item)"><i class="material-icons">edit</i></a>
                      </li>
                      <li class="list-acoes-delete">
                        <a @click="action(this.constants.actionDelete, item)" v-if="$store.state.user && $store.state.user.sysUser.user_level === 1"><i class="material-icons">delete</i></a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <AppTablePagination @searchTable="search()" :count="count"/>
      </div>
    </div>
  </div>
</template>

<script>
import AppTablePagination from '../../components/AppTablePagination.vue'
import axios from 'axios'
export default {
  name: 'GerminacaoTableView',
  components: { AppTablePagination },
  props: {
    items: Array,
    count: Number,
    withOrderBy: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    dataOrderBy: {}
  }),
  computed: {
    OrderByCode: function () {
      if (this.$store.state.dataOrderBy.OrderByCodeAcs) {
        return 'sorting_asc'
      } else if (this.$store.state.dataOrderBy.OrderByCodeDesc) {
        return 'sorting_desc'
      } else {
        return 'sorting'
      }
    },
    OrderByLatin_name: function () {
      if (this.$store.state.dataOrderBy.OrderByLatin_nameAcs) {
        return 'sorting_asc'
      } else if (this.$store.state.dataOrderBy.OrderByLatin_nameDesc) {
        return 'sorting_desc'
      } else {
        return 'sorting'
      }
    },
    OrderByCode_mother: function () {
      if (this.$store.state.dataOrderBy.OrderByCode_motherAcs) {
        return 'sorting_asc'
      } else if (this.$store.state.dataOrderBy.OrderByCode_motherDesc) {
        return 'sorting_desc'
      } else {
        return 'sorting'
      }
    }
  },
  methods: {
    action: function (action, item) {
      if (action === this.constants.actionDelete) {
        if (confirm(this.$t('message.confirmDel'))) {
          this.delete(item)
        }
      } else {
        this.$router.push({ name: 'germinacaoForm', params: { id: item.id_germin } })
      }
    },
    delete: function (item) {
      axios.delete(`/EkoaGermin/${item.id_germin}`)
        .then(() => {
          this.$toast.success(this.constants.success)
          this.search()
        })
    },
    search: function () {
      this.$emit('search')
    },
    orderBy: function (num) {
      if (num === 1) {
        if (this.$store.state.dataOrderBy.OrderByCodeAcs) {
          this.$store.state.dataOrderBy.OrderByCodeDesc = true
        } else {
          this.$store.state.dataOrderBy.OrderByCodeDesc = false
        }
        this.$store.state.dataOrderBy.OrderByCodeAcs = !this.$store.state.dataOrderBy.OrderByCodeAcs
      }
      if (num === 2) {
        if (this.$store.state.dataOrderBy.OrderByLatin_nameAcs) {
          this.$store.state.dataOrderBy.OrderByLatin_nameDesc = true
        } else {
          this.$store.state.dataOrderBy.OrderByLatin_nameDesc = false
        }
        this.$store.state.dataOrderBy.OrderByLatin_nameAcs = !this.$store.state.dataOrderBy.OrderByLatin_nameAcs
      }
      if (num === 3) {
        if (this.$store.state.dataOrderBy.OrderByCode_motherAcs) {
          this.$store.state.dataOrderBy.OrderByCode_motherDesc = true
        } else {
          this.$store.state.dataOrderBy.OrderByCode_motherDesc = false
        }
        this.$store.state.dataOrderBy.OrderByCode_motherAcs = !this.$store.state.dataOrderBy.OrderByCode_motherAcs
      }
      this.$emit('orderBy', this.$store.state.dataOrderBy)
    }
  }
}
</script>
