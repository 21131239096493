export const ptBR = {
  message: {
    requiredField: 'Campo obrigatório',
    noRecords: 'Nenhum registro encontrado!',
    confirmDel: 'Tem certeza de que deseja excluir?',
    confirmApproval: 'Tem certeza de que deseja aprovar?',
    noResultsFor: 'Sem resultados para',
    invalidDate: 'Data inválida'
  },
  label: {
    porfavorfacaloginparaacessaroadmin: 'Por favor faça login para acessar o admin',
    email: 'E-mail',
    acessar: 'Acessar',
    senha: 'Senha',
    usuario: 'Usuárix',
    ola: 'Olá',
    logoff: 'Log off',
    pendenteAnalise: 'Pendente de análise',
    versaoAnterior: 'Versão anterior',
    versaoAtual: 'Versão atual',
    fechar: 'Fechar',
    tabelas: 'Tabelas',
    home: 'Home',
    coletadefrutosesementes: 'Coleta de frutos e sementes',
    dadosexpedicaomudas: 'Expedição de mudas',
    controlegerminacaosementes: 'Germinação de sementes',
    matrizes: 'Matrizes',
    matriz: 'Matriz',
    municipios: 'Municípios',
    rededeplantadorxs: 'Rede de plantadorxs',
    coordmudasplantadas: 'Coordenadas das mudas',
    coordenadasmudasapp: 'Coordenadas das mudas app',
    especies: 'Espécies',
    mudasplantadas: 'Mudas plantadas',
    ferramentas: 'Ferramentas',
    relatorios: 'Relatórios',
    exportar: 'Exportar',
    usuarios: 'Usuárixs',
    exibindo1a: 'Exibindo 1 a',
    de: 'de',
    registros: 'Registros',
    anterior: 'Anterior',
    proximo: 'Próximo',
    idcoleta: 'ID COLETA',
    codigodacoleta: 'Código da coleta',
    nomecientifico: 'Nome científico',
    nomecientificonomepopular: 'Nome científico / popular',
    datadecoleta: 'Data de coleta',
    pesodassementes: 'Peso das sementes por quilo',
    numerodefrutos: 'NÚMERO DE FRUTOS',
    numerodesementes: 'NÚMERO DE SEMENTES',
    dtsemeaduraemtubetes: 'DATA SEMEADURA NA EMBALAGEM',
    numerotubetessemeados: 'NÚMERO DE EMBALAGENS SEMEADAS',
    dtsemeaduraemsementeira: 'DATA SEMEADURA EM SEMENTEIRA',
    dtiniciodarustificacao: 'DATA INÍCIO DA REPICAGEM',
    codigodamatriz: 'Código da matriz',
    aprovar: 'Aprovar',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    adicionar: 'Adicionar',
    editar: 'Editar',
    nomepopular: 'Nome popular',
    adicionarcoleta: 'Adicionar coleta',
    limpar: 'Limpar',
    filtrar: 'Filtrar',
    acoes: 'Ações',
    codigoexpedicao: 'Código da expedição',
    dataexpedicao: 'Data da expedição',
    numerodemudas: 'Número de mudas',
    codigodagerminacao: 'Código da germinação',
    municipio: 'Município',
    nomedapropriedade: 'Nome da propriedade',
    estado: 'Estado',
    proprietarix: 'Proprietárix',
    codigodamuda: 'Código da muda',
    coordenadasdemudasplantadas: 'Coordenadas das mudas plantadas',
    codigodaespecie: 'Código da espécie',
    familia: 'Família',
    quantidade: 'Quantidade',
    nome: 'Nome',
    telefone: 'Telefone',
    codigodaexpedicao: 'Código da expedição',
    datadaexpedicao: 'Data de expedição',
    tabelaexpedicoes: 'Tabela de expedição',
    datadegerminacaoemtubetes: 'Data de germinação em embalagens',
    numerodeplantulasemtubetes: 'Número de plântulas em embalagens',
    datadegerminacaonasementeira: 'Data de germinação na sementeira',
    numerodeplantulasnasementeira: 'Número de plântulas na sementeira',
    tabelagerminacao: 'Tabela de germinação',
    observacao: 'Observação',
    latitude: 'Latitude',
    longitude: 'Longitude',
    data: 'Data',
    localizacao: 'Localização',
    propriedade: 'Propriedade',
    proprietario: 'Proprietárix',
    contato: 'Contato',
    website: 'Website',
    midiasocial: 'Mídia social',
    frutificacao: 'Frutificação',
    floracao: 'Floração',
    janeiro: 'Janeiro',
    fevereiro: 'Fevereiro',
    marco: 'Março',
    abril: 'Abril',
    maio: 'Maio',
    junho: 'Junho',
    julho: 'Julho',
    agosto: 'Agosto',
    setembro: 'Setembro',
    outubro: 'Outubro',
    novembro: 'Novembro',
    dezembro: 'Dezembro',
    habitat: 'Hábitat',
    listasvermelhas: 'Listas vermelhas',
    ativo: 'Ativo',
    empresa: 'Empresa',
    perfil: 'Perfil',
    administrador: 'Administrador',
    basico: 'Básico',
    sim: 'Sim',
    nao: 'Não',
    datadoplantio: 'Data do plantio',
    usuariocriacao: 'Usuárix de criação',
    datacriacao: 'Data de criação',
    cadastradoviaapp: 'Cadastrado via APP',
    codigocorrdenadademudas: 'Código coord. de mudas',
    imagens: 'Imagens',
    jan: 'Janeiro',
    feb: 'Fevereiro',
    mar: 'Março',
    apr: 'Abril',
    may: 'Maio',
    jun: 'Junho',
    jul: 'Julho',
    aug: 'Agosto',
    sep: 'Setembro',
    oct: 'Outubro',
    nov: 'Novembro',
    dec: 'Dezembro',
    exportarmatrizes: 'Exportar matrizes',
    exportarespecies: 'Exportar espécies',
    exportarplantadores: 'Exportar plantadores',
    exportarcoordmudas: 'Exportar coordenadas de mudas',
    exportarmudasplantadas: 'Exportar mudas plantadas',
    exportarexpedicao: 'Exportar expedição',
    exportargerminacao: 'Exportar germinação',
    exportarcoletas: 'Exportar coletas',
    numero: 'Número',
    dematrizes: 'de matrizes',
    mudas: 'Mudas',
    plantadas: 'plantadas',
    deespecies: 'de espécies',
    sementes: 'Sementes',
    coletadas: 'coletadas',
    frutas: 'Frutos',
    rededeplantadores: 'Rede de plantadores',
    propriedades: 'Propriedades',
    sementescoletadas: 'Sementes coletadas',
    frutoscoletadas: 'Frutos coletados',
    app: 'APP',
    whatsAppGroup: 'Grupo WhatsApp',
    id: 'ID',
    mudasdistribuidas: 'Mudas distribuídas',
    redede: 'Rede de',
    plantadores: 'plantadores',
    mudasdistribuidas1: 'Mudas',
    mudasdistribuidas2: 'distribuídas',
    ultimaexpedicao: 'Última expedição',
    hectares: 'Hectares',
    registradas: 'Registradas',
    peloapp: 'Pelo APP',
    registrosapp: 'Registros APP'
  }
}
