<template>
  <div class="container">
    <AppPageTitle :title="pageTitle" :pendingAnalysis="pendingAnalysis" />
    <form class="">
      <div class="row pb-5">
        <div class="col-md-4">
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.code !== this.form.log_update.Code }">
            <label class="form-label">{{ $t('label.codigodaexpedicao') }}</label>
            <button v-if="pendingAnalysis && this.form.code !== this.form.log_update.Code" class="btn" type="button" @click="analysis($t('label.codigodaexpedicao'), 'code')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <input type="text" class="form-control" v-model="this.form.code" maxlength="3">
          </div>
        </div>
        <div class="col-md-8">
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_species !== this.form.log_update.Id_species }">
            <label class="form-label">{{ $t('label.nomecientificonomepopular') }}</label>
            <button v-if="pendingAnalysis && this.form.id_species !== this.form.log_update.Id_species" class="btn" type="button" @click="analysis($t('label.nomecientificonomepopular'), 'id_species')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <v-select :appendToBody="true" :filterable="false" @search="getSpeciesCombo" :options="speciesList"
              v-model="form.id_species" label="text" index="id"
              :reduce="r => r.id" :clearable="false" class="vue-select" :class="{ 'is-invalid': v$.form.id_species.$dirty && v$.form.id_species.$error }">
              <template v-slot:selected-option="option">
                {{ `${option.text}` }}
              </template>
              <template v-slot:option="option">
                {{ `${option.text}` }}
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                </template>
              </template>
            </v-select>
            <div class="invalid-feedback">
              {{ $t('message.requiredField') }}
            </div>
          </div>
        </div>
        <!--div class="col-md-5">
          <div :class="{ 'pending_analysis':  pendingAnalysis && this.form.id_mothertree !== this.form.log_update.Id_mothertree }">
            <label class="form-label">{{ $t('label.codigodamatriz') }}</label>
            <button v-if="pendingAnalysis && this.form.id_mothertree !== this.form.log_update.Id_mothertree" class="btn" type="button" @click="analysis($t('label.codigodamatriz'), 'id_mothertree')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
              <i class="material-icons">search</i>
            </button>
            <v-select :appendToBody="true" :filterable="false" @search="getMotherTreesCombo" :options="motherTreesList"
              v-model="form.id_mothertree" label="text" index="id"
              :reduce="r => r.id" :clearable="false" class="vue-select">
              <template v-slot:selected-option="option">
                {{ `${option.text ?? ''}` }}
              </template>
              <template v-slot:option="option">
                {{ `${option.text ?? ''}` }}
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                </template>
              </template>
            </v-select>
          </div>
        </div-->
        <div class="col-md-12 pt-2" :class="{ 'pending_analysis':  pendingAnalysis && pendingAnalysisExped }">
          <table id="datatable1" class="display dataTable no-footer" style="width: 100%;" role="grid" aria-describedby="datatable1_info">
              <thead>
                  <tr role="row">
                    <th>
                      <button v-if="pendingAnalysis && pendingAnalysisExped" class="btn" type="button" @click="analysis($t('label.tabelaexpedicoes'), 'tabelaexpedicoes')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="material-icons">search</i>
                      </button>
                      {{ $t('label.datadaexpedicao') }}
                    </th>
                    <th>{{ $t('label.numerodemudas') }}</th>
                    <th>{{ $t('label.codigodacoleta') }}/{{ $t('label.matriz') }}</th>
                    <th>{{ $t('label.nomedapropriedade') }}</th>
                    <th style="display: flex;">{{ $t('label.acoes') }}
                      <ul class="list-group list-group-horizontal list-acoes ">
                        <li class="list-acoes-add">
                          <a @click="actionExpedGrid = this.constants.actionNew"><i class="material-icons">add</i></a>
                        </li>
                      </ul>
                    </th>
                  </tr>
              </thead>
              <tbody>
                <template v-if="actionExpedGrid !== this.constants.actionList">
                  <tr>
                    <td>
                      <div class="col-md-12">
                        <input type="text" class="form-control" v-model="this.ekoaExpedInfo.date_exped" v-mask="'##/##/####'" :class="{ 'is-invalid': v$.ekoaExpedInfo.date_exped.$dirty && v$.ekoaExpedInfo.date_exped.$error }">
                        <div class="invalid-feedback">
                          {{ $t('message.invalidDate') }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="col-md-12">
                        <input type="number" class="form-control" v-model="this.ekoaExpedInfo.n_seedlings" :class="{ 'is-invalid': v$.ekoaExpedInfo.n_seedlings.$dirty && v$.ekoaExpedInfo.n_seedlings.$error }">
                        <div class="invalid-feedback">
                          {{ $t('message.requiredField') }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <v-select :appendToBody="true" :filterable="false" @search="getEkoaCollectCombo" :options="ekoaCollectList"
                        v-model="this.ekoaExpedInfo.id_collection" label="text" index="id"
                        :reduce="r => r.id" :clearable="false" class="vue-select">
                        <template v-slot:selected-option="option">
                          {{ `${option.text ?? ''}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.text ?? ''}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                      </v-select>
                    </td>
                    <td>
                      <div class="col-md-12">
                        <v-select :appendToBody="true" :filterable="false" @search="getPlantersCombo" :options="plantersList"
                          v-model="this.ekoaExpedInfo.id_property" label="text" index="id"
                          :reduce="r => r.id" :clearable="false" class="vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.text}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.text}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                    </td>
                    <td>
                      <div class="col-md-12">
                        <ul class="list-group list-group-horizontal list-acoes ">
                          <li class="">
                            <a @click="saveExpedInfo()"><i class="material-icons">done</i></a>
                          </li>
                          <li class="">
                            <a @click="cancelExpedInfo()"><i class="material-icons">cancel</i></a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-if="!this.form.ekoaExpedInfo || this.form.ekoaExpedInfo.length === 0"><td colspan="5" class="text-center">{{ $t('message.noRecords') }}</td></tr>
                  <tr v-else v-for="i in this.form.ekoaExpedInfo" :key="i.id_exped_info" role="row" class="odd">
                    <td>{{$filters.formatDate(i.date_exped)}}</td>
                    <td>{{i.n_seedlings}}</td>
                    <td>{{i?.ekoaCollect?.code}}{{i?.ekoaCollect?.motherTrees?.code ? ` - ${i?.ekoaCollect?.motherTrees?.code}` : '' }}</td>
                    <td>{{i?.planters?.property}}</td>
                    <td>
                      <ul class="list-group list-group-horizontal list-acoes ">
                        <li class="list-acoes-edit">
                          <a @click="actionExped(this.constants.actionEdit, i)"><i class="material-icons">edit</i></a>
                        </li>
                        <li class="list-acoes-delete">
                          <a @click="actionExped(this.constants.actionDelete, i)"><i class="material-icons">delete</i></a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
        </div>

        <div class="col-md-12 mt-5 d-flex justify-content-end ">
          <button type="button" @click="save" class="btn btn-primary me-3">{{pendingAnalysis ? $t('label.aprovar') : $t('label.salvar')}}</button>
          <button type="button" @click="cancel" class="btn btn-danger">{{ $t('label.cancelar') }}</button>
        </div>
      </div>
    </form>
    <!-- Modal -->
    <AppModalReview :form="form" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import AppPageTitle from '../../components/AppPageTitle.vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import AppModalReview from '../../components/AppModalReview.vue'
export default {
  name: 'ExpendMudasFormView',
  components: { AppPageTitle, AppModalReview },
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      pageTitle: `${this.$t('label.dadosexpedicaomudas')} > ${this.$t('label.adicionar')}`,
      action: this.constants.actionNew,
      actionExpedGrid: this.constants.actionList,
      form: {
        species: {},
        log_update: {},
        ekoaExpedInfo: []
      },
      ekoaExpedInfo: { id_exped_info: null, id_exped: null, date_exped: null, n_seedlings: null, id_property: null, id_collection: null },
      speciesList: [{ id_species: null, text: this.$t('label.nomecientificonomepopular') }],
      ekoaCollectList: [{ id_collection: null, text: `${this.$t('label.codigodacoleta')} / ${this.$t('label.matriz')}` }],
      plantersList: [{ id_property: null, text: '' }],
      motherTreesList: [{ id_mothertree: null, text: this.$t('label.codigodamatriz') }],
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      }
    }
  },
  validations () {
    return {
      form: {
        id_species: { required }
      },
      ekoaExpedInfo: {
        date_exped: {
          minLength: minLength(10),
          validDate (value) {
            if (!value) { return false }
            return this.$filters.dateIsValid(value)
          }
        },
        n_seedlings: { required }
      }
    }
  },
  watch: {
    'form.id_species' (newLong, oldLong) {
      if (newLong !== oldLong) {
        if (oldLong) {
          this.form.id_mothertree = null
        }
        this.getMotherTreesCombo()
        this.getEkoaCollectCombo()
      }
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.form.pending_analysis && this.$store.state.user.sysUser.user_level === 1
    },
    pendingAnalysisExped: function () {
      if (!this.form.ekoaExpedInfo || !this.form.log_update.EkoaExpedInfo) {
        return false
      }
      if (this.form.ekoaExpedInfo.length !== this.form.log_update.EkoaExpedInfo.length) { return true }

      const ret = false
      for (const e in this.form.log_update.EkoaExpedInfo) {
        const item = this.form.log_update.EkoaExpedInfo[e]
        const list = this.form.ekoaExpedInfo
          .filter(f => f.date_exped === item.Date_exped && f.n_seedlings === item.N_seedlings && f.id_exped === item.Id_exped)
        if (!list.length) {
          return true
        }
      }
      return ret
    }
  },
  methods: {
    cancelExpedInfo: function () {
      this.ekoaExpedInfo = { id_exped_info: null, id_exped: null, date_exped: null, n_seedlings: null, id_property: null, id_collection: null }
      this.actionExpedGrid = this.constants.actionList
    },
    saveExpedInfo: function () {
      this.v$.$touch()
      if (this.v$.ekoaExpedInfo.$invalid) {
        return
      }
      this.form.ekoaExpedInfo = this.form.ekoaExpedInfo.filter(f => f.id_exped_info !== this.ekoaExpedInfo.id_exped_info)
      this.ekoaExpedInfo.id_exped_info = Date.now()
      this.ekoaExpedInfo.id_exped = this.form.id_exped
      this.ekoaExpedInfo.date_exped = this.$filters.formatDateToBackend(this.ekoaExpedInfo.date_exped)
      if (this.ekoaExpedInfo.id_property) {
        const property = this.plantersList.find(f => f.id === this.ekoaExpedInfo.id_property)
        this.ekoaExpedInfo.planters = { id_property: property.id, property: property.text }
      }
      if (this.ekoaExpedInfo.id_collection) {
        const collection = this.ekoaCollectList.find(f => f.id === this.ekoaExpedInfo.id_collection)
        this.ekoaExpedInfo.ekoaCollect = { id_collection: collection.id, code: collection.text }
      }
      this.form.ekoaExpedInfo.push(this.ekoaExpedInfo)
      this.actionExpedGrid = this.constants.actionList
      this.ekoaExpedInfo = { id_exped_info: null, id_exped: null, date_exped: null, n_seedlings: null, id_property: null, id_collection: null }
      this.v$.$reset()
    },
    actionExped: function (action, item) {
      if (action === this.constants.actionDelete) {
        if (confirm(this.$t('message.confirmDel'))) {
          this.form.ekoaExpedInfo = this.form.ekoaExpedInfo.filter(f => f.id_exped_info !== item.id_exped_info)
          this.actionExpedGrid = this.constants.actionList
        }
      }
      if (action === this.constants.actionEdit) {
        const exped = { ...item }
        exped.date_exped = this.$filters.formatDate(exped.date_exped)
        this.ekoaExpedInfo = exped
        this.actionExpedGrid = this.constants.actionEdit
      }
    },
    modalValuesSimple: function (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis: async function (label, input) {
      let newValue = ''
      let oldValue = ''
      switch (input) {
        case 'code':
          this.modalValuesSimple(this.form.code, this.form.log_update.Code, label)
          break
        case 'id_species':
          newValue = this.speciesList.filter(l => l.id === this.form.id_species)[0]
          oldValue = this.speciesList.filter(l => l.id === this.form.log_update.Id_species)[0]
          if (!oldValue) {
            oldValue = await this.getSpeciesComboById(this.form.log_update.Id_species)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
        case 'id_collection':
          newValue = this.ekoaCollectList.filter(l => l.id === this.form.id_collection)[0]
          oldValue = this.ekoaCollectList.filter(l => l.id === this.form.log_update.Id_collection)[0]
          if (!oldValue) {
            oldValue = await this.getEkoaCollectComboById(this.form.log_update.Id_collection)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
        case 'tabelaexpedicoes':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          this.form.ekoaExpedInfo.forEach(e => {
            this.modalValues.newValue.push({ id: e.id_exped_info, value: `${this.$filters.formatDate(e.date_exped)} - ${e.n_seedlings}` })
          })
          this.modalValues.oldValue = []
          this.form.log_update.EkoaExpedInfo.forEach(e => {
            this.modalValues.oldValue.push({ id: e.Id_exped_info, value: `${this.$filters.formatDate(e.Date_exped)} - ${e.N_seedlings}` })
          })
          break
        case 'id_mothertree':
          newValue = this.motherTreesList.filter(l => l.id === this.form.id_mothertree)[0]
          oldValue = this.motherTreesList.filter(l => l.id === this.form.log_update.Id_mothertree)[0]
          if (!oldValue) {
            oldValue = await this.getMotherTreesComboById(this.form.log_update.Id_mothertree)
          }
          this.modalValuesSimple(newValue.text ?? '', oldValue.text ?? '', label)
          break
      }
    },
    getById: async function (id) {
      this.$store.dispatch('showPreload')
      await axios.get(`/EkoaExped/${id}`)
        .then(response => {
          this.form = response.data
          if (this.form.log_update) {
            this.form.log_update = JSON.parse(this.form.log_update)
          } else {
            this.form.log_update = {}
          }
        })
    },
    getSpeciesComboById: async function (id) {
      const params = {
        id_species: id
      }
      let result = {}
      await axios.get('/species/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getEkoaCollectComboById: async function (id) {
      const params = {
        id_collection: id
      }
      let result = {}
      await axios.get('/EkoaCollect/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getSpeciesCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.latin_name = search
        params.common_name = search
        params.id_species = this.form.id_species
        loading(true)
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
            loading(false)
          })
      } else if (!search) {
        params.id_species = this.form.id_species
        axios.get('/species/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.speciesList.unshift({ id_species: null, text: this.$t('label.nomecientificonomepopular') })
          })
      }
    },
    getEkoaCollectCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.code = search
        params.id_collection = this.form.id_collection
        params.id_species = this.form.id_species
        loading(true)
        axios.get('/EkoaCollect/getallcombo', { params })
          .then(response => {
            this.ekoaCollectList = response.data.items
            this.ekoaCollectList.unshift({ id_collection: null, text: `${this.$t('label.codigodacoleta')} / ${this.$t('label.matriz')}` })
            loading(false)
          })
      } else if (!search) {
        params.id_collection = this.form.id_collection
        params.id_species = this.form.id_species
        axios.get('/EkoaCollect/getallcombo', { params })
          .then(response => {
            this.ekoaCollectList = response.data.items
            this.ekoaCollectList.unshift({ id_collection: null, text: `${this.$t('label.codigodacoleta')} / ${this.$t('label.matriz')}` })
          })
      }
    },
    getPlantersCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.property = search
        params.id_property = this.form.id_property
        loading(true)
        axios.get('/planters/getallcombo', { params })
          .then(response => {
            this.plantersList = response.data.items
            this.plantersList.unshift({ id_property: null, text: '' })
            loading(false)
          })
      } else if (!search) {
        params.id_property = this.form.id_property
        axios.get('/planters/getallcombo', { params })
          .then(response => {
            this.plantersList = response.data.items
            this.plantersList.unshift({ id_property: null, text: '' })
          })
      }
    },
    save: function () {
      this.v$.$touch()
      if (this.v$.form.$invalid) {
        return
      }
      this.form.log_update = JSON.stringify(this.form.log_update)
      let modelRequest = {}
      modelRequest = { ...this.form }
      modelRequest.ekoaExpedInfo.forEach(element => {
        element.id_exped_info = 0
      })
      if (this.action === this.constants.actionEdit) {
        axios.put('/EkoaExped', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.form.log_update = JSON.parse(this.form.log_update)
            this.cancel()
          })
      } else {
        axios.post('/EkoaExped', modelRequest)
          .then(() => {
            this.$toast.success(this.constants.success)
            this.cancel()
          })
      }
    },
    cancel: function () {
      this.$router.replace('/exped-mudas')
    },
    getCollectById: async function (id) {
      await axios.get(`/EkoaCollect/${id}`)
        .then(response => {
          this.collect = response.data
        })
    },
    getMotherTreesComboById: async function (id) {
      const params = {
        id_mothertree: id
      }
      let result = {}
      await axios.get('/MotherTrees/getallcombo', { params })
        .then(response => { result = response.data.items })
      return result
    },
    getMotherTreesCombo: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      params.id_species = this.form.id_species
      if (search && search.length > 2) {
        params.code = search
        params.id_mothertree = this.form.id_mothertree
        loading(true)
        axios.get('/MotherTrees/getallcombo', { params })
          .then(response => {
            this.motherTreesList = response.data.items
            this.motherTreesList.unshift({ id_mothertree: null, text: this.$t('label.codigodamatriz') })
            loading(false)
          })
      } else if (!search) {
        params.id_mothertree = this.form.id_mothertree
        axios.get('/MotherTrees/getallcombo', { params })
          .then(response => {
            this.motherTreesList = response.data.items
            this.motherTreesList.unshift({ id_mothertree: null, text: this.$t('label.codigodamatriz') })
          })
      }
    }
  },
  created: async function () {
    this.getSpeciesCombo()
    this.getEkoaCollectCombo()
    this.getPlantersCombo()

    this.action =
      this.$route.params.id !== this.constants.actionNew
        ? this.constants.actionEdit
        : this.constants.actionNew

    if (this.action === this.constants.actionEdit) {
      this.pageTitle = `${this.$t('label.dadosexpedicaomudas')} > ${this.$t('label.editar')}`
      await this.getById(this.$route.params.id)
      this.getSpeciesCombo()
      this.getEkoaCollectCombo()
      this.getPlantersCombo()
    }
  }
}
</script>
